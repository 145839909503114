import React from "react"
import { connect } from "react-redux"
import { sendContainerNumber, postPreNoteContainer, copyPostPreNoteContainer } from "../../actions"

class ExportPreNotes2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            saveCopyLoading: false,
            saveLoading: false,
            containerNumber: "",
            tempContainerNumber: "",
            sealNumber:"",
            totalWeight:"",
            isWeightVerified: false,
            verifiedBy: "",
            isoType:"",
            carrier:"",
            consignor:"",
            isLocked:false,
            setConsignor:"", // for returned consignor field after container number check for dropdown list
            setConsignorObjectString:"",
            temperatureSet:"None",//set to None by default so will accept form if no temperature value is present
            temperatureVal:"",
            details:""
         }
         this.inputContainerNumberRef = React.createRef()
         this.inputTotalWeightRef = React.createRef()

    }

    componentDidMount(){
        //to check if prenotes details has a temperature value. If it does, it strips the value visible to the user
        let details = this.props.preNotes[this.props.index].details
        let regex = "(?<=Temperature:</B> ).*?(?=</div>)"
        let temp = details.match(regex)
        let tempElement = "\r<div><B>Temperature:</B> " + temp[0] + "</div>"
        let htmlVal = details.replace(tempElement, "")
        if(temp[0] === "None"){htmlVal = this.props.preNotes[this.props.index].details}
        this.setState({
            verifiedBy: this.props.name,
            isoType: this.props.preNotes[this.props.index].defaultISOType.objectString,
            details: htmlVal,
            temperatureVal: temp[0]
        })
    }

    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }


    handleCheckboxChange = event => {
        this.setState({[event.target.name]:event.target.checked})
    }



    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            this.props.dispatch({type: "RESET_PRE_NOTES"})
            this.props.dispatch({type: "CHANGE_PRE_NOTES_INDEX", preNotesIndex: 0})

        }
    }

    sendContainerNumber = event => {
        if(this.state.containerNumber.length>0 && this.state.containerNumber.toUpperCase() !== this.state.tempContainerNumber.toUpperCase()){
            this.setState({tempContainerNumber: this.state.containerNumber})
            return this.props.dispatch(sendContainerNumber(this.state.containerNumber.toUpperCase()))
            .then(res=>{
                if(res.body && res.body.cargoItems){
                    this.lockInputs(res.body.cargoItems[0])
                } else if(this.state.isLocked){
                    this.resetInputs()
                } else {
                    //do nothing
                }
            })
            .catch(err =>{
                console.log(err)
                alert("An error has occured while validating this container number")
            })
        }
    }

    lockInputs = (cargoItems) =>{
        this.setState({
            isLocked: true,
            sealNumber: cargoItems.seal,
            isoType: cargoItems.isoTypeObjStr,
            carrier: cargoItems.receiveCarrierObjectString,
            consignor: cargoItems.consignorObjectString,
            //add in returned consignor field to dropdown list
            setConsignor: cargoItems.consignor,
            setConsignorObjectString: cargoItems.consignorObjectString
        })
        this.inputTotalWeightRef.current.focus()

    }

    resetInputs = ()=>{
        this.setState({
            isLocked: false,
            sealNumber: "",
            isoType: "",
            carrier: "",
            consignor: "",
            setConsignor:"",
            setConsignorObjectString:""
        })
    }

    renderTempChange = event => {
        //renders the temperature set field
        return(
        <div className="details-row">
        <span className="pre-notes-span-1">Set Temperature:</span>
        <span className="red pre-notes-span-2">*</span>
        <span className="pre-notes-span-3">
            <input 
            className="pre-notes-temperature"
            type="number" 
            name="temperatureSet" 
            value={this.state.temperatureSet} 
            onChange={this.handleChange} 
            />
        </span>
    </div>
        )
    }

    handleSave = event =>{
    
    //if there is a consignor to choose from the dropdown list or the container number check return, consignor is mandatory
       var needConsignor = false
       if(this.props.consignors.length > 0 || this.state.setConsignorObjectString !== ""){
           needConsignor = true
       }

       //make sure Total Weight is a whole number
        if(this.state.totalWeight % 1 !== 0){
            this.inputTotalWeightRef.current.focus()
            return alert("Please enter in a whole number for Total Weight.")
        }

        var verified = ""
        if(this.state.isWeightVerified){
            verified = this.state.verifiedBy 
        }
        var postBody ={
            "prenote": {
                "bookingReferenceObjectString" : this.props.preNotes[this.props.index].bookingReferenceObjectString,
                "carrierObjectString" : this.state.carrier,
                "consignorObjectString" : this.state.consignor,
                "containerId" : this.state.containerNumber.toUpperCase(),
                "isWeightCertified" : this.state.isWeightVerified,
                "isoObjectString" : this.state.isoType,
                "sealNumber" : this.state.sealNumber.toUpperCase(),
                "weight": this.state.totalWeight.toString(),
                "weightCertifiedName": verified
            },
            "user" :{
                "userObjectString": this.props.userInfo.user.userObjectString
            },
            "sessionId" : this.props.userInfo.sessionId
        }
        if(
            (this.state.containerNumber &&
            this.state.totalWeight &&
            this.state.isoType) &&
            (!this.state.isWeightVerified ||
             this.state.isWeightVerified &&
             this.state.verifiedBy
            ) && ((needConsignor && this.state.consignor) || !needConsignor)
            ){
            this.setState({saveLoading: true, })
            return this.props.dispatch(postPreNoteContainer(postBody))  
            .then(res=>{
                this.setState({
                    saveLoading: false,
                })
                res.body && this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
                res.body && this.props.dispatch({type: "RESET_PRE_NOTES"})
                res.body && this.props.dispatch({type: "CHANGE_PRE_NOTES_INDEX", preNotesIndex: 0})


        }) 
        .catch(err =>{
            this.setState({saveLoading:false})
            console.log(err)
        })
       }else{
           alert("Please enter all mandatory fields")
       }
    }

    handleSaveCopy = event =>{
     //if there is a consignor to choose from the dropdown list or the container number check return, consignor is mandatory
       var needConsignor = false
       if(this.props.consignors.length > 0 || this.state.setConsignorObjectString !== ""){
           needConsignor = true
       }

       //make sure Total Weight is a whole number
        if(this.state.totalWeight % 1 !== 0){
            this.inputTotalWeightRef.current.focus()
            return alert("Please enter in a whole number for Total Weight.")
        }
        

        var verified = ""
        if(this.state.isWeightVerified){
            verified = this.state.verifiedBy 
        }
        var postBody ={
            "prenote": {
                "bookingReferenceObjectString" : this.props.preNotes[this.props.index].bookingReferenceObjectString,
                "carrierObjectString" : this.state.carrier,
                "consignorObjectString" : this.state.consignor,
                "containerId" : this.state.containerNumber.toUpperCase(),
                "isWeightCertified" : this.state.isWeightVerified,
                "isoObjectString" : this.state.isoType,
                "sealNumber" : this.state.sealNumber.toUpperCase(),
                "weight": this.state.totalWeight.toString(),
                "weightCertifiedName": verified
            },
            "user" :{
                "userObjectString": this.props.userInfo.user.userObjectString
            },
            "sessionId" : this.props.userInfo.sessionId
        }
        if(
            (this.state.containerNumber &&
                this.state.totalWeight &&
                this.state.isoType) &&
                (!this.state.isWeightVerified ||
                 this.state.isWeightVerified &&
                 this.state.verifiedBy
                ) && ((needConsignor && this.state.consignor) || !needConsignor)
            ){
                this.setState({saveCopyLoading:true})
            return this.props.dispatch(copyPostPreNoteContainer(postBody))
            .then(res=>{
                    this.setState({
                        containerNumber: "",
                        tempContainerNumber: "",
                        sealNumber: "",
                        totalWeight: "",
                        verifiedBy: this.props.name,
                        isWeightVerified: false,
                        setConsignor:"",
                        setConsignorObjectString:"",
                        saveCopyLoading: false,
                    })
                    this.inputContainerNumberRef.current.focus()
            }) 
            .catch(err =>{
                this.setState({saveCopyLoading:false})
                console.log(err)
            })
       }else{
           alert("Please enter all mandatory fields")
       }
    }

    notifyTemperature = event => {
        alert("The Set Temperature entered does not match the booking temperature. Please contact the Shipping Line to have the booking temperature changed.")
    }
    

    render() { 
        return ( 
            <div className="modal-background">
                <div className="export-pre-notes-container">
                    <div className="close" onClick={this.handleCancel}>&times;</div>
                    <div className="modal-header">
                        <span>Export Pre-notes</span>
                    </div>
                    <div className="modal-content-container">
                        <div className="details-row">
                            <span className="pre-notes-span-1">Booking Reference:</span>
                            <span className="pre-notes-span-2"></span>
                            <span className="pre-notes-span-3">{this.props.preNotes[this.props.index].bookingReferenceNumber}</span>
                        </div>
                        <div className="details-row">
                            <span className="pre-notes-span-1">Container Number:</span>
                            <span className="red pre-notes-span-2">*</span>
                            <span className="pre-notes-span-3">
                                <input 
                                type="text" 
                                name="containerNumber" 
                                value={this.state.containerNumber} 
                                onChange={this.handleChange} 
                                onBlur={this.sendContainerNumber}
                                ref = {this.inputContainerNumberRef}
                                style={{textTransform: "uppercase"}}
                                />
                            </span>
                        </div>
                        {/* <div className={this.state.isLocked?"details-row unactive":"details-row"}> */}
                        <div className="details-row">

                            <span className="pre-notes-span-1">Seal Number:</span>
                            {/* <span className={this.state.isLocked?"invisible pre-notes-span-2":"red small-font pre-notes-span-2"}>#</span> */}
                            <span className="red small-font pre-notes-span-2">#</span>
                            <span className="pre-notes-span-3">
                                <input 
                                type="text" 
                                name="sealNumber" 
                                value={this.state.sealNumber} 
                                onChange={this.handleChange} 
                                // disabled={this.state.isLocked?true:false} 
                                style={{textTransform: "uppercase"}}
                                />
                            </span>
                        </div>
                        <div className="details-row">
                            <span className="pre-notes-span-1">Total Weight:</span>
                            <span className="red pre-notes-span-2">*</span>
                            <span className="pre-notes-span-3">
                                <input 
                                type="number" 
                                name="totalWeight" 
                                value={this.state.totalWeight} 
                                onChange={this.handleChange} 
                                ref = {this.inputTotalWeightRef}
                                /> Kgs
                            </span>
                        </div>
                        <div className="details-row">
                            <span className="pre-notes-span-1">Is Weight Verified</span>
                            <span className="red pre-notes-span-2 small-font">#</span>
                            <span className="pre-notes-span-3">
                                <input 
                                type="checkbox" 
                                name="isWeightVerified" 
                                checked={this.state.isWeightVerified} 
                                onChange={this.handleCheckboxChange}
                                />
                            </span>
                        </div>
                        <div className="pre-notes-verify">By ticking this box you confirm that the weight entered is the verified gross mass for the container.</div>
                        <div className={this.state.isWeightVerified?"details-row":"details-row unactive"}>
                            <span className="pre-notes-span-1">Verified By:</span>
                            <span className={this.state.isWeightVerified?"red pre-notes-span-2":"invisible pre-notes-span-2"}>*</span>
                            <span className=" pre-notes-span-3">
                                <input 
                                type="text" 
                                name="verifiedBy" 
                                value={this.state.verifiedBy} 
                                onChange={this.handleChange} 
                                disabled={this.state.isWeightVerified?false:true}
                                />
                            </span>
                        </div>
                        <div className={this.state.isLocked?"details-row unactive":"details-row"}>
                            <span className="pre-notes-span-1">ISO Type</span>
                            <span className="red pre-notes-span-2">*</span>
                            <span className="pre-notes-span-3">
                                <select 
                                onChange={this.handleChange} 
                                name="isoType" 
                                value={this.state.isoType} 
                                className="pre-notes-input" disabled={this.state.isLocked?true:false}>
                                    <option></option>
                                    {this.props.preNotes[this.props.index].isoTypes && this.props.preNotes[this.props.index].isoTypes.map((item, i)=>{
                                        return(
                                        <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                        )
                                        })}
                                </select>
                            </span>
                        </div>
                        <div className={this.state.isLocked?"details-row unactive":"details-row"}>
                            <span className="pre-notes-span-1">Carrier:</span>
                            <span className="red pre-notes-span-2"></span>
                            <span className="pre-notes-span-3">
                                <select 
                                onChange={this.handleChange} 
                                name="carrier" 
                                value={this.state.carrier} 
                                className="pre-notes-input" 
                                disabled={this.state.isLocked?true:false}>
                                <option value="">{this.state.isLocked?"Unknown Carrier":""}</option>
                                    {this.props.carriers && this.props.carriers.map((item, i)=>{
                                    return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                    )
                                    })}
                                </select>
                            </span>
                        </div>
                        {/* <div className={this.state.isLocked?"details-row unactive":"details-row"}> */}
                        <div className="details-row">
                            <span className="pre-notes-span-1">Consignor:</span>
                            <span className="red pre-notes-span-2">*</span>
                            <span className="pre-notes-span-3">
                                <select 
                                onChange={this.handleChange} 
                                name="consignor" 
                                value={this.state.consignor} 
                                className="pre-notes-input" 
                                // disabled={this.state.isLocked?true:false}
                                >
                                    <option value=""></option>
                                    {this.props.consignors && this.props.consignors.map((item, i)=>{
                                    return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                    )
                                    })}
                                    {this.state.setConsignor && this.state.setConsignorObjectString &&
                                    <option value={this.state.setConsignorObjectString}>{this.state.setConsignor}</option>
                                    }
                                </select>
                            </span>
                        </div>
                        {this.state.temperatureVal !== "None" && this.renderTempChange()}
                        <div>
                            <div className="pre-notes-info" dangerouslySetInnerHTML={{__html: this.state.details}} />
                        </div>
                        <div>
                            <div className="red pre-notes-required-info">* - required</div>
                            <div className="red pre-notes-required-info"># - required before PIN issued</div>

                        </div>
                        <br/>
                        <div>
                            <input 
                            type="button" 
                            value="Save" 
                            className={this.state.saveLoading?"button-loading submit-button save-button pre-notes-button":this.state.saveCopyLoading?"button-disabled submit-button save-button pre-notes-button":"submit-button save-button pre-notes-button"}
                            onClick={this.state.temperatureSet === this.state.temperatureVal? this.handleSave:this.notifyTemperature}
                            />
                            <input 
                            type="button" 
                            value="Save/Copy" 
                            className={this.state.saveCopyLoading?"button-loading submit-button save-button pre-notes-button":this.state.saveLoading?"button-disabled submit-button save-button pre-notes-button":"submit-button save-button pre-notes-button"}
                            onClick={this.state.temperatureSet === this.state.temperatureVal? this.handleSaveCopy:this.notifyTemperature}
                            />

                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
    preNotes: state.preNotes,
    name: state.userInfo.user.fullName,
    carriers: state.dropdowns.containerCarriers,
    consignors: state.dropdowns.consignors,
    userInfo: state.userInfo,
    index: state.visibleComponents.preNotesIndex
    }
  }  

export default connect(mapStateToProps)(ExportPreNotes2);