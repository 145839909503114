const initialState = {
    shippingSettings:{
        "savedSetting": null,
        "fromDate": "2020-03-18",
        "includeArrivals": true,
        "includeDepartures": true,
        "includeShifts": true,
        "inPortDate": "",
        "movementDateDynamic": "Next,2,Year(s)",
        "primaryCargoType": null,
        "primaryCargoTypeObjStr": "",
        "toDate": "2020-05-17",
        "vessel": null,
        "vesselObjStr": "",
        "vesselType": null,
        "vesselTypeObjStr": ""
    },
    containersSettings:{
        "savedSetting": null,
        "bookingReference": "",
        "cargoType": 1,
        "cedos": [
            " "
        ],
        "clientReference": "",
        "customsEntryNumber": "",
        "houseBOL": "",
        "ids": ["APZU3788888","APZU3537331"],
        "includeCleared": false,
        "includeDLR": false,
        "includeDry": true,
        "includeExports": false,
        "includeImports": true,
        "includeNotified": false,
        "includeNotReadyToDeliver": true,
        "includeOffPort": false,
        "includeOnHold": true,
        "includeOnPort": true,
        "includeOnShip": false,
        "includeReadyToDeliver": true,
        "includeReefers": true,
        "includeSOB": false,
        "includeStorage": false,
        "noCedoOnly": false,
        "oceanBOL": "",
        "operator": null,
        "operatorObjStr": "",
        "operatorPIN": "",
        "receivedDateDynamic": "",
        "receivedFromDate": "",
        "receivedToDate": "",
        "releasedDateDynamic": "",
        "releasedFromDate": "",
        "releasedToDate": "",
        "storageDays": -1,
        "voyage": null,
        "voyageObjStr": ""
    },
    vehiclesSettings:{},
    allSettings:{}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ALL_SETTINGS":
            return Object.assign({}, state, {allSettings: action.allSettings})
            break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer