import React from "react"
import { connect } from "react-redux"
import { sendVehicleUpdate } from "../../../actions"

class VehiclesTableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    postChanges = ()=>{
        this.props.vehiclesSaveArray.map((index)=>{
          var postBody ={	"cargoItemUpdate" : {
            "carrierObjectString" : this.props.vehiclesReturn[index].releaseCarrierObjectString,
            "carrierRemarks" : this.props.vehiclesReturn[index].carrierRemarks,
            "consigneeObjectString" : this.props.vehiclesReturn[index].consigneeObjectString,
            "freightForwarderObjectString" : this.props.vehiclesReturn[index].freightForwarderObjectString,
            "hasCustomsAgentStop" : this.props.vehiclesReturn[index].hasCustomsAgentStop,
            "hasFreightForwarderStop" : this.props.vehiclesReturn[index].hasFreightForwarderStop,
            "hasOperatorStop" : this.props.vehiclesReturn[index].hasOperatorStop,
            "objectEdition": this.props.vehiclesReturn[index].objectEdition.toString(),
            "targetObjectString": this.props.vehiclesReturn[index].cargoItemObjectString
          },
          "user" :{
              "userObjectString": this.props.userInfo.user.userObjectString
          },
          "sessionId": this.props.userInfo.sessionId
        
        }
        this.props.dispatch(sendVehicleUpdate(postBody, index))
        console.log(postBody)
        })
      }
      
      renderEditAll= ()=>{
        if(
          this.props.visibleComponents.editAllVehFFStop ||
          (this.props.visibleComponents.editAllVehCarrier && this.props.dropdowns.carCarriers.length > 0 ) ||
          this.props.visibleComponents.editAllVehCarrierRemarks ||
          (this.props.visibleComponents.editAllVehConsignee && this.props.dropdowns.consignees.length > 0 ) ||
          (this.props.visibleComponents.editAllVehFF && this.props.dropdowns.freightForwarders.length > 0 ) ||
          this.props.visibleComponents.editAllVehCustomsAgentStop
          ){
          return <span onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "editAllVehicles"})}>Edit All</span>
        }
      }

    render() { 
        return ( 
<React.Fragment>
    <div>Vehicles</div>
        <div>
            {this.props.isLoggedIn && this.props.vehiclesSaveButton && <span onClick={this.postChanges}>Save</span>}
            {this.props.isLoggedIn && this.renderEditAll()}
            {this.props.isLoggedIn && <span onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "organiseVehiclesTable"})}>Organise</span>}
            <span className="table-header-buttons" onClick={this.props.handleExport}>Export</span>

        </div>
</React.Fragment>

         );
    }
}

function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        vehiclesSaveButton: state.visibleComponents.vehiclesSaveButton,
        vehiclesSaveArray: state.visibleComponents.vehiclesSaveArray,
        vehiclesReturn: state.vehiclesReturn,
        userInfo: state.userInfo,
        visibleComponents: state.visibleComponents,
        dropdowns: state.dropdowns
    }
  } 

export default connect(mapStateToProps)(VehiclesTableHeader);