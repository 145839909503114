import React from "react"
import { connect } from "react-redux";
import { searchShipping } from "../../../actions/search"

class ShippingTableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    //Shipping search will trigger every 30 mins if shipping tab is active. Has been put in here as ShippingSearch.jsx is unmounted in fullscreen view.
    intervalID = 0

    componentDidMount() {
    //Set 30min timer on mount
        this.intervalID = setInterval(() => {
         this.props.visibleTab === "Shipping" && this.props.dispatch(searchShipping())
        }, 1800000)
      }

    componentWillUnmount() {
    //Cancel timer on unMount
    clearInterval(this.intervalID);
    }

    render() { 
        return ( 
            <React.Fragment>
                <div>Shipping Movements</div>
                <div>
                    {this.props.isLoggedIn && <span className="table-header-buttons" onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "organiseShippingTable"})}>Organise</span>}
                    <span className="table-header-buttons" onClick={this.props.handleExport}>Export</span>
                </div>
            </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
        visibleTab: state.visibleComponents.visibleTab,
        isLoggedIn: state.visibleComponents.isLoggedIn
    }
  } 
export default connect(mapStateToProps)(ShippingTableHeader);