import React from "react"
import { connect } from "react-redux"

class OrganiseVehiclesTable extends React.Component {
    constructor(props) {
        super(props);
    //Settings are kept in state until submitted and then passed to Store.
        this.state = { 
            vessel: true,
            oceanBillOfLading: true,
            portVoyageCode: false,
            houseBillOfLading: true,
            operator: false,
            id: true,
            stops: true,
            mafStatus: false,
            hasOperatorStop: false,
            hasFreightForwarderStop: false,
            locality: false,
            hasCustomsAgentStop: false,
            receivedDateTime: true,
            releasedDateTime: false,
            lastDayOfFreeStorage: true,
            availableForDeliveryDateTime: false,
            condition: false,
            consignee: true,
            clientRef: false,
            customsAgent: false,
            description: false,
            customsEntryNumber: false,
            releaseCarrier: false,
            hasRadio: false,
            keyNumber: true,
            locationOnPort: false,
            releaseVehicle: false,
            loadPort: false,
            carrierRemarks: false,
            freightForwarder: true,
            selectAll: false,
         }
    }

    componentDidMount(){
        //Keeps state settings up to date with Redux store on reload.
            this.setState({
                vessel: this.props.vehicles.vessel,
                oceanBillOfLading: this.props.vehicles.oceanBillOfLading,
                portVoyageCode: this.props.vehicles.portVoyageCode,
                houseBillOfLading: this.props.vehicles.houseBillOfLading,
                operator: this.props.vehicles.operator,
                id: this.props.vehicles.id,
                stops: this.props.vehicles.stops,
                mafStatus: this.props.vehicles.mafStatus,
                hasOperatorStop: this.props.vehicles.hasOperatorStop,
                hasFreightForwarderStop: this.props.vehicles.hasFreightForwarderStop,
                locality: this.props.vehicles.locality,
                hasCustomsAgentStop: this.props.vehicles.hasCustomsAgentStop,
                receivedDateTime: this.props.vehicles.receivedDateTime,
                releasedDateTime: this.props.vehicles.releasedDateTime,
                lastDayOfFreeStorage: this.props.vehicles.lastDayOfFreeStorage,
                availableForDeliveryDateTime: this.props.vehicles.availableForDeliveryDateTime,
                condition: this.props.vehicles.condition,
                consignee: this.props.vehicles.consignee,
                clientRef: this.props.vehicles.clientRef,
                customsAgent: this.props.vehicles.customsAgent,
                description: this.props.vehicles.description,
                customsEntryNumber: this.props.vehicles.customsEntryNumber,
                releaseCarrier: this.props.vehicles.releaseCarrier,
                hasRadio: this.props.vehicles.hasRadio,
                keyNumber: this.props.vehicles.keyNumber,
                locationOnPort: this.props.vehicles.locationOnPort,
                releaseVehicle: this.props.vehicles.releaseVehicle,
                loadPort: this.props.vehicles.loadPort,
                carrierRemarks: this.props.vehicles.carrierRemarks,
                freightForwarder: this.props.vehicles.freightForwarder,
                selectAll: this.props.vehicles.selectAll,
            })
          }
    

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})        }
    }


    handleCheckboxChange = event => {
        this.setState({[event.target.name]:event.target.checked})
    }


    handleSelectAll = event =>{
        this.setState({
                    selectAll: event.target.checked,
                    vessel: event.target.checked,
                    oceanBillOfLading: event.target.checked,
                    portVoyageCode: event.target.checked,
                    houseBillOfLading: event.target.checked,
                    operator: event.target.checked,
                    id: event.target.checked,
                    stops: event.target.checked,
                    mafStatus: event.target.checked,
                    hasOperatorStop: event.target.checked,
                    hasFreightForwarderStop: event.target.checked,
                    locality: event.target.checked,
                    hasCustomsAgentStop: event.target.checked,
                    receivedDateTime: event.target.checked,
                    releasedDateTime: event.target.checked,
                    lastDayOfFreeStorage: event.target.checked,
                    availableForDeliveryDateTime: event.target.checked,
                    condition: event.target.checked,
                    consignee: event.target.checked,
                    clientRef: event.target.checked,
                    customsAgent: event.target.checked,
                    description: event.target.checked,
                    customsEntryNumber: event.target.checked,
                    releaseCarrier: event.target.checked,
                    hasRadio: event.target.checked,
                    keyNumber: event.target.checked,
                    locationOnPort: event.target.checked,
                    releaseVehicle: event.target.checked,
                    loadPort: event.target.checked,
                    carrierRemarks: event.target.checked,
                    freightForwarder: event.target.checked
       
            })
    }


    handleSubmit = event =>{
        event.preventDefault()
        this.props.dispatch(
            {
                type: "UPDATE_ALL_ORG_VEH_CHECKBOX",
                payload: {
                    vessel: this.state.vessel,
                    oceanBillOfLading: this.state.oceanBillOfLading,
                    portVoyageCode: this.state.portVoyageCode,
                    houseBillOfLading: this.state.houseBillOfLading,
                    operator: this.state.operator,
                    id: this.state.id,
                    stops: this.state.stops,
                    mafStatus: this.state.mafStatus,
                    hasOperatorStop: this.state.hasOperatorStop,
                    hasFreightForwarderStop: this.state.hasFreightForwarderStop,
                    locality: this.state.locality,
                    hasCustomsAgentStop: this.state.hasCustomsAgentStop,
                    receivedDateTime: this.state.receivedDateTime,
                    releasedDateTime: this.state.releasedDateTime,
                    lastDayOfFreeStorage: this.state.lastDayOfFreeStorage,
                    availableForDeliveryDateTime: this.state.availableForDeliveryDateTime,
                    condition: this.state.condition,
                    consignee: this.state.consignee,
                    clientRef: this.state.clientRef,
                    customsAgent: this.state.customsAgent,
                    description: this.state.description,
                    customsEntryNumber: this.state.customsEntryNumber,
                    releaseCarrier: this.state.releaseCarrier,
                    hasRadio: this.state.hasRadio,
                    keyNumber: this.state.keyNumber,
                    locationOnPort: this.state.locationOnPort,
                    releaseVehicle: this.state.releaseVehicle,
                    loadPort: this.state.loadPort,
                    carrierRemarks: this.state.carrierRemarks,
                    freightForwarder: this.state.freightForwarder,
                    selectAll: this.state.selectAll,
                }
            }
        )
        this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"}) 
    }

    render() { 
        return ( 
            <div className="modal-background">
                <div className="organise-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                        <span>Organise</span>
                    </div>
                    <div className="modal-content-container">
                    <form onSubmit={this.handleSubmit}>
                        <table className="organise-table">
                        <thead>
                            <tr>
                            <th></th>
                            <th>Show</th>
                            <th></th>
                            <th></th>
                            <th>Show</th>                            
                            </tr>
                        </thead>
                        <tbody>  
                            <tr>
                                <td>Vessel:</td>
                                <td><input name="vessel"  type="checkbox" checked={this.state.vessel} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Available for Delivery:</td>
                                <td><input name="availableForDeliveryDateTime"  type="checkbox" checked={this.state.availableForDeliveryDateTime} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Ocean Bill:</td>
                                <td><input name="oceanBillOfLading"  type="checkbox" checked={this.state.oceanBillOfLading} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Condition:</td>
                                <td><input name="condition"  type="checkbox" checked={this.state.condition} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Voyage Code:</td>
                                <td><input name="portVoyageCode"  type="checkbox" checked={this.state.portVoyageCode} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Consignee:</td>
                                <td><input name="consignee"  type="checkbox" checked={this.state.consignee} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>House Bill:</td>
                                <td><input name="houseBillOfLading"  type="checkbox" checked={this.state.houseBillOfLading} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Client Ref:</td>
                                <td><input name="clientRef"  type="checkbox" checked={this.state.clientRef} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Operator:</td>
                                <td><input name="operator"  type="checkbox" checked={this.state.operator} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Customs Agent:</td>
                                <td><input name="customsAgent"  type="checkbox" checked={this.state.customsAgent} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>VIN:</td>
                                <td><input name="id"  type="checkbox" checked={this.state.id} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Description:</td>
                                <td><input name="description"  type="checkbox" checked={this.state.description} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Stops:</td>
                                <td><input name="stops"  type="checkbox" checked={this.state.stops} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Customs Entry Number:</td>
                                <td><input name="customsEntryNumber"  type="checkbox" checked={this.state.customsEntryNumber} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>MPI Status:</td>
                                <td><input name="mafStatus"  type="checkbox" checked={this.state.mafStatus} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Release Carrier:</td>
                                <td><input name="releaseCarrier"  type="checkbox" checked={this.state.releaseCarrier} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Operator Stop:</td>
                                <td><input name="hasOperatorStop"  type="checkbox" checked={this.state.hasOperatorStop} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Has Radio:</td>
                                <td><input name="hasRadio"  type="checkbox" checked={this.state.hasRadio} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Freight Forwarder Stop:</td>
                                <td><input name="hasFreightForwarderStop"  type="checkbox" checked={this.state.hasFreightForwarderStop} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Key Number:</td>
                                <td><input name="keyNumber"  type="checkbox" checked={this.state.keyNumber} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Locality:</td>
                                <td><input name="locality"  type="checkbox" checked={this.state.locality} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Location On Port:</td>
                                <td><input name="locationOnPort"  type="checkbox" checked={this.state.locationOnPort} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Customs Agent Stop:</td>
                                <td><input name="hasCustomsAgentStop"  type="checkbox" checked={this.state.hasCustomsAgentStop} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Release Vehicle:</td>
                                <td><input name="releaseVehicle"  type="checkbox" checked={this.state.releaseVehicle} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Received Date:</td>
                                <td><input name="receivedDateTime"  type="checkbox" checked={this.state.receivedDateTime} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Load Port:</td>
                                <td><input name="loadPort"  type="checkbox" checked={this.state.loadPort} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Released Date:</td>
                                <td><input name="releasedDateTime"  type="checkbox" checked={this.state.releasedDateTime} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Carrier Remarks:</td>
                                <td><input name="carrierRemarks"  type="checkbox" checked={this.state.carrierRemarks} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Last Day Of Free Storage:</td>
                                <td><input name="lastDayOfFreeStorage"  type="checkbox" checked={this.state.lastDayOfFreeStorage} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Freight Forwarder:</td>
                                <td><input name="freightForwarder"  type="checkbox" checked={this.state.freightForwarder} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Select All:</td>
                                <td><input type="checkbox" checked={this.state.selectAll} onChange={this.handleSelectAll}/></td>
                            </tr>
                        </tbody>
                        </table>
                        <div className="save-button-container">
                        <div><input type="submit" className="submit-button save-button"  value="Show"/></div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
     vehicles: state.organiseTable.vehicles
    }
  }  

export default connect(mapStateToProps)(OrganiseVehiclesTable);