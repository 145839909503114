import React from "react"
import { connect } from "react-redux";
import { deleteSavedSetting, saveSetting } from "../../actions"
import { loadShippingSettings } from "../../actions/settings"
import { searchShipping } from "../../actions/search"



class SettingsShipping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            radio: "-1",
            saveName: "",
            index: -1,
        }
    }

    componentDidMount(){
        this.nameInput.focus();
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }
  
    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }

    handleRadioChange = event => {
        this.setState({radio:event.target.name})
        this.setState({index:event.target.value})
    }

    handleElementClick = index => {
        var indexToString = index.toString()
        this.setState({radio:indexToString})
        this.setState({index:indexToString})
    }

    handleDelete = event =>{
        if(this.state.index >=0){
            var shippingSettings = this.props.allSettings[this.state.index]
            var deleteBody = {	
                "savedSetting" : {
                        "name": shippingSettings.name,
                        "type": 0
                    },
                "user" :{
                    "userObjectString": this.props.userInfo.user.userObjectString
                },
                "sessionId" : this.props.userInfo.sessionId
            }
           this.props.dispatch(deleteSavedSetting(deleteBody, this.props.userInfo.user.userObjectString))
           console.log(deleteBody)
        }else{
            alert("Please select an option to delete")
        }
    }

    handleLoad = event =>{
        //if text input is selected, index= -1.
        if(this.state.index >=0 ){
            //Load settings, then search table, then close settings box
            this.props.dispatch(loadShippingSettings(this.props.allSettings[this.state.index]))
            this.props.dispatch(searchShipping())
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})

        }else{
            alert("Please select an option to load")
        }


        }

    handleSave = (event) =>{
        var shippingSettings = this.props.allSettings[this.state.index]
        var saveName = ""
        if(this.state.index<0){
            saveName= this.state.saveName
        }else{
            saveName = shippingSettings.name
        }
        var postBody = {	
            "savedSetting" : {
                    "cargoItemFilter": null,
                    "columnSettings": [
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Date",
                            "name": "movementDate",
                            "position": this.props.columnOrder.movementDatePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.movementDate,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.movementDateWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Pilot Time",
                            "name": "movementTime",
                            "position": this.props.columnOrder.movementTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.movementTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.movementTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Berth Time",
                            "name": "berthTime",
                            "position": this.props.columnOrder.berthTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.berthTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.berthTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Vessel Name",
                            "name": "vesselName",
                            "position": this.props.columnOrder.vesselNamePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.vesselName,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.vesselNameWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Voyage Code",
                            "name": "voyageCode",
                            "position": this.props.columnOrder.voyageCodePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.voyageCode,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.voyageCodeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Imports",
                            "name": "importsLoaded",
                            "position": this.props.columnOrder.importsLoadedPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.importsLoaded,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.importsLoadedWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Type",
                            "name": "moveType",
                            "position": this.props.columnOrder.moveTypePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.moveType,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.moveTypeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "From/To Port",
                            "name": "fromToPort",
                            "position": this.props.columnOrder.fromToPortPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.fromToPort,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.fromToPortWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Primary Cargo",
                            "name": "primaryCargo",
                            "position": this.props.columnOrder.primaryCargoPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.primaryCargo,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.primaryCargoWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Agent",
                            "name": "agent",
                            "position": this.props.columnOrder.agentPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.agent,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.agentWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Berth",
                            "name": "berth",
                            "position": this.props.columnOrder.berthPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.berth,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.berthWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Arrive Harbour Date",
                            "name": "arriveHarbourDate",
                            "position": this.props.columnOrder.arriveHarbourDatePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.arriveHarbourDate,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.arriveHarbourDateWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Arrive Harbour Time",
                            "name": "arriveHarbourTime",
                            "position": this.props.columnOrder.arriveHarbourTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.arriveHarbourTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.arriveHarbourTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Comments",
                            "name": "comments",
                            "position": this.props.columnOrder.commentsPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.comments,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.commentsWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Depart Berth Date",
                            "name": "departBerthDate",
                            "position": this.props.columnOrder.departBerthDatePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.departBerthDate,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.departBerthDateWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Depart Berth Time",
                            "name": "departBerthTime",
                            "position": this.props.columnOrder.departBerthTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.departBerthTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.departBerthTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Has Pending Changes",
                            "name": "hasPendingChanges",
                            "position": this.props.columnOrder.hasPendingChangesPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.hasPendingChanges,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.hasPendingChangesWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Is Complete",
                            "name": "isComplete",
                            "position": this.props.columnOrder.isCompletePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.isComplete,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.isCompleteWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Launch Time OnBoard",
                            "name": "launchTimeOnBoard",
                            "position": this.props.columnOrder.launchTimeOnBoardPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.launchTimeOnBoard,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.launchTimeOnBoardWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Side To",
                            "name": "sideTo",
                            "position": this.props.columnOrder.sideToPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.sideTo,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.sideToWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Trade",
                            "name": "trade",
                            "position": this.props.columnOrder.tradePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.trade,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.tradeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Tugs",
                            "name": "tugs",
                            "position": this.props.columnOrder.tugsPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.tugs,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.tugsWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Tugs Time OnBoard",
                            "name": "tugsTimeOnBoard",
                            "position": this.props.columnOrder.tugsTimeOnBoardPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.tugsTimeOnBoard,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.tugsTimeOnBoardWidth
                        }, 
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "LLoyds Number",
                            "name": "lloydsNumber",
                            "position": this.props.columnOrder.lloydsNumberPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.lloydsNumber,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.lloydsNumberWidth
                        }
                    ],
                    "columnSorts":this.props.columnInfo.defaultSorted,
                    "name": saveName,
                    "shippingMovementFilter": {
                        "savedSetting": null,
                        "fromDate": this.props.shippingSearch.fromDate,
                        "includeArrivals": this.props.shippingSearch.includeArrivals,
                        "includeDepartures": this.props.shippingSearch.includeDepartures,
                        "includeShifts": this.props.shippingSearch.includeShifts,
                        "inPortDate": this.props.shippingSearch.inPortDate,
                        "movementDateDynamic": this.props.shippingSearch.movementDateDynamic,
                        "primaryCargoType": null,
                        "primaryCargoTypeObjStr": this.props.shippingSearch.primaryCargoTypeObjStr,
                        "toDate": this.props.shippingSearch.toDate,
                        "vessel": null,
                        "vesselObjStr": this.props.shippingSearch.vesselObjStr,
                        "vesselType": null,
                        "vesselTypeObjStr": this.props.shippingSearch.vesselTypeObjStr
                    },
                    "type": 0
                },
            "user" :{
                "userObjectString": this.props.userInfo.user.userObjectString
            },
            "sessionId" : this.props.userInfo.sessionId
        }

        if(this.state.index<0 && this.state.saveName.length<1){
            alert("Please choose an option or make a save name")
        } else {
            this.props.dispatch(saveSetting(postBody, this.props.userInfo.user.userObjectString))
            // this.props.dispatch(searchContainers())
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
        console.log(postBody)
    }

    render() { 
        return ( 
            <div className="modal-background">
            <div className="favourites-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                    <span>Favourites</span>
                </div>
                <div className="modal-content-container">
                <form>
                    <p>
                    <input type="radio" className="favourites-radio"  value="-1" name="-1" checked={this.state.radio==="-1"} onChange={this.handleRadioChange}/>
                    <input 
                        type="text" 
                        className="favourites-text-input" 
                        name="saveName" 
                        value={this.state.saveName} 
                        onChange={this.handleChange} 
                        placeholder="Create New Setting"
                        ref={(inputEl) => this.nameInput = inputEl}
                        onClick={()=>this.handleElementClick(-1)}
                    />
                    </p>
                    {  this.props.allSettings.map((item,index)=>{
                        if(item.type ===0 && item.shippingMovementFilter){
                            return(
                                <p key={"p"+index}>
                                <input 
                                type="radio" 
                                className="favourites-radio" 
                                value={index} name={index} 
                                checked={this.state.radio===index.toString()} 
                                onChange={this.handleRadioChange} 
                                key={index}
                                />
                                <span key={"span"+index} onClick={()=>this.handleElementClick(index)}>{item.name}</span>
                                </p>
                            )
                        } else {
                            return ""
                        }
        })}
                    <input type="button" value="Load" className="submit-button save-button" onClick={this.handleLoad}/>
                    <input type="button" value="Save" className="submit-button save-button middle-button" onClick={this.handleSave}/>
                    <input type="button" value="Delete" className="submit-button save-button" onClick={this.handleDelete}/>


                </form>
                </div>
            </div>
        </div>

         );
    }
}

function mapStateToProps(state){
    return {
    allSettings: state.settings.allSettings,
    userInfo: state.userInfo,
    shippingSearch : state.shippingSearch,
    columnInfo: state.organiseTable.shipping,
    columnOrder: state.organiseTable.shippingColOrder

    }
  }  
 
export default connect(mapStateToProps)(SettingsShipping);