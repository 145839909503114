const initialState = []

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case "UPDATE_CONTAINERS_RETURN":
        return action.containersReturn
        break;
    case "RESET_CONTAINERS_RETURN":
        return initialState
        break;
    case "UPDATE_CONTAINERS_RETURN_AT_INDEX":
        return (
            state.slice(0,action.payload.index).concat(action.payload.value).concat(state.slice(action.payload.index + 1))
        )
        break;
    case "UPDATE_CONTAINERS_RETURN_CHECKBOX":
        return [
                       ...state.slice(0, action.payload.index), 
                       {
                          ...state[action.payload.index],
                          "canUpdateCarrier": action.payload.value
                       },
                       ...state.slice(action.payload.index + 1),
                    ]
        break;
    case "UPDATE_CONTAINERS_RETURN_F_F_STOP":
        return [
                        ...state.slice(0, action.payload.index), 
                        {
                            ...state[action.payload.index],
                            "hasFreightForwarderStop": action.payload.value
                        },
                        ...state.slice(action.payload.index + 1),
                    ]
        break;
    case "UPDATE_CONTAINERS_RETURN_OPERATOR_STOP":
        return [
                        ...state.slice(0, action.payload.index), 
                        {
                            ...state[action.payload.index],
                            "hasOperatorStop": action.payload.value
                        },
                        ...state.slice(action.payload.index + 1),
                    ]
        break;
    case "UPDATE_CONTAINERS_RETURN_CARRIER_REMARKS":
        return [
                        ...state.slice(0, action.payload.index), 
                        {
                            ...state[action.payload.index],
                            "carrierRemarks": action.payload.value
                        },
                        ...state.slice(action.payload.index + 1),
                    ]
        break;
    case "UPDATE_CONTAINERS_RETURN_FF_OBJ_STR":
        return [
                        ...state.slice(0, action.payload.index), 
                        {
                            ...state[action.payload.index],
                            "freightForwarderObjectString": action.payload.freightForwarderObjectString
                        },
                        ...state.slice(action.payload.index + 1),
                    ]
        break;
    case "UPDATE_CONTAINERS_RETURN_CARRIER_OBJ_STR":
    return [
                    ...state.slice(0, action.payload.index), 
                    {
                        ...state[action.payload.index],
                        "releaseCarrierObjectString": action.payload.releaseCarrierObjectString
                    },
                    ...state.slice(action.payload.index + 1),
                ]
    break;
    case "UPDATE_CONTAINERS_RETURN_CONSIGNEE_OBJ_STR":
        return [
                        ...state.slice(0, action.payload.index), 
                        {
                            ...state[action.payload.index],
                            "consigneeObjectString": action.payload.consigneeObjectString
                        },
                        ...state.slice(action.payload.index + 1),
                    ]
        break;
    case "UPDATE_CONTAINERS_RETURN_AT_INDEX":
        return (
            state.slice(0,action.payload.index).concat(action.payload.value).concat(state.slice(action.payload.index + 1))
        )
        break;
    case "LOGOUT":
        return initialState
        break; 
    default:
        return state
    }
}

export default reducer