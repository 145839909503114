import React from 'react'
import MainContainer from "./MainContainer"

const App = () => {
  return (
    <div id="main-container">
      <MainContainer/>
    </div>
  )
}

export default App
