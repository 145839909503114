import React from "react"
import { connect } from 'react-redux'
import ContainersDetails from './ContainersDetails'
import ContainersTransactions from "./ContainersTransactions"
class ContainersSubTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    renderSubComponent = ()=>{
        if(this.props.containersSubTab==="Details"){
            return <ContainersDetails/>
        } else if(this.props.containersSubTab==="Transactions"){
            return <ContainersTransactions/>
        } else{
            return ""
        }
    }

    render() { 
        return ( 
            <React.Fragment>
            <div  className="flex-row">
                <div id="container-tab">
                    <div id={this.props.containersSubTab ==="Details"?"tabactivated":""} className="tabunactive tabactive" onClick={() => this.props.dispatch({type: "CHANGE_VISIBLE_CONTAINERS_SUBTAB", containersSubTab: "Details"})}>Details</div>
                    {this.props.isLoggedIn && <div id={this.props.containersSubTab ==="Transactions"?"tabactivated":""} className="tabunactive" onClick={() => this.props.dispatch({type: "CHANGE_VISIBLE_CONTAINERS_SUBTAB", containersSubTab: "Transactions"})}>Transactions</div>}
                </div>
                <div>
                    <img id="expanded-details-arrow" src="./images/chevron-circle-left.svg" onClick={()=>this.props.dispatch({type:"COLLAPSE_EXPAND_DETAILS", value:true})}/>
                </div>
            </div>
            {this.renderSubComponent()}
            </React.Fragment>
         );
    }
}
function mapStateToProps(state){
    return {
        containersSubTab: state.visibleComponents.containersSubTab,
        isLoggedIn: state.visibleComponents.isLoggedIn
    }
  } 
export default connect(mapStateToProps)(ContainersSubTabs);