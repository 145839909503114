export const apiCall = "https://jmt.centreport.co.nz/bin_public/jadehttp.dll"
export const apiPath = "https://jmt.centreport.co.nz/bin_public/jadehttp.dll/vessels?CentricRestService"
export const apiPost = "https://jmt.centreport.co.nz/bin_public/jadehttp.dll/shippingmovements?CentricRestService"
export const apiPrimaryCargoTypes = "https://jmt.centreport.co.nz/bin_public/jadehttp.dll/primarycargotypes?CentricRestService"
export const apiVesselTypes = "https://jmt.centreport.co.nz/bin_public/jadehttp.dll/vesseltypes?CentricRestService"

export function convertDate(dateString){

    var epochString = dateString.replace(/[^0-9]/g, "")
    var epochDate = parseInt(epochString)
    var d = new Date(epochDate);
    var day = d.getDate();
    var month = d.getMonth();
    var year = d.getFullYear()
     if (dateString.length > 0){

         return day+"/"+month+"/"+year
     }
    }