import React from "react"
import { connect } from "react-redux"
import { changeUserPassword, changeUserPasswordSuccess } from "../../actions";

class ChangePasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            userName: "",
            password: "",
            newPassword: "",
            confirmNewPassword: "",
         }
    }
    handleOnChange = event => {
        this.setState({[event.target.name]: event.target.value})
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            this.props.dispatch({type: "CHANGE_PASSWORD_ERROR_TEXT", changePasswordErrorText: ""})

        }
    }

    handlePasswordSubmit = event =>{
        event.preventDefault()
        if(!this.state.password || !this.state.newPassword || !this.state.confirmNewPassword){
            this.props.dispatch({type: "CHANGE_PASSWORD_ERROR_TEXT", changePasswordErrorText: "Please enter in all fields."})
        } else if(this.state.newPassword === this.state.confirmNewPassword){
            this.props.dispatch({type: "CHANGE_PASSWORD_ERROR_TEXT", changePasswordErrorText: ""})

               // this.props.isLoggedIn && userName = ADD USERNAME PATH HERE
               var postbody = {
                   "user" :{
                                   "loginId": this.props.username,
                                   "password": this.state.password,
                                   "userObjectString": this.props.userObjectString
                               },
                               "newPassword" : this.state.newPassword,
                               "sessionId": this.props.sessionId,
                               }
            return this.props.dispatch(changeUserPassword(postbody))
            .then(res =>{
                if(res.body.errorText){
                    this.props.dispatch({type: "CHANGE_PASSWORD_ERROR_TEXT", changePasswordErrorText: res.body.errorText})
                } else if(res.status === 200 && !res.body.errorText){
                    this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
                    alert("Your password was changed successfully")
                    // console.log("form")
                    // this.props.dispatch(changeUserPasswordSuccess())

                } else {
                    alert("An error has occured changing your password.")
                }
                
            })
            .catch(err =>{
                console.log(err)
            })
            
        }else{
            this.setState((state)=>{
                return {
                    newPassword: "",
                    confirmNewPassword: "",
                    showMatchingPasswordFail: true
                }
            })
            this.props.dispatch({type: "CHANGE_PASSWORD_ERROR_TEXT", changePasswordErrorText: "New password and Confirmed new password do not match."})

        }
    }

    render() { 
        return ( 
            <div className="modal-background">
                <div className="change-password-container">
                    <div className="close" onClick={this.handleCancel}>&times;</div>
                    <div className="modal-header">
                        <span>Change Password</span>
                    </div>
                    <div className="modal-content-container">
                        <form onSubmit={this.handlePasswordSubmit}>
                            {this.props.changePasswordErrorText && <div className="change-password-error-text">{this.props.changePasswordErrorText}</div>}

                            <div className="input-div-long">
                                <p>Username: {this.props.username}</p>
                            </div>                     
                            <div className="input-div-long">
                                <input type="password" className="input-text-long" name = "password" value = {this.state.password} placeholder="Current Password" onChange={this.handleOnChange}/>
                            </div>
                            <br/>
                            <div className="input-div-long">
                                <input type="password" className="input-text-long" name = "newPassword" value={this.state.newPassword} placeholder="New Password" onChange={this.handleOnChange}/>
                            </div>
                            <br/>
                            <div className="input-div-long">
                                <input type="password" className="input-text-long" name = "confirmNewPassword" value={this.state.confirmNewPassword} placeholder="Confirm New Password" onChange={this.handleOnChange}/>
                            </div>
                            <br/>
                            <div id="login-buttons-container">
                            <input type="submit" className="submit-button" id="login-button" value="Submit"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         );
    }
}
function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        changePasswordErrorText: state.visibleComponents.changePasswordErrorText,
        username: state.userInfo.user.loginId,
        sessionId: state.userInfo.sessionId,
        userObjectString: state.userInfo.user.userObjectString
    }
  }  
 

export default connect(mapStateToProps)(ChangePasswordForm);