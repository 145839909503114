const initialState = {
    fromDate: "", 
    toDate: "", 
    primaryCargoTypeObjStr: "", 
    vesselTypeObjStr:"", 
    movementDateDynamic: "", 
    vesselObjStr: "", 
    inPortDate: "", 
    includeArrivals: true, 
    includeDepartures: true, 
    includeShifts: true,
    dateRangeNext: "Next", 
    dateRangeValue: 1, 
    dateRangeType: "Week(s)",
    radioButton: "fromDateToDate"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_POST_PRIMARY_CARGO_TYPE":
        return Object.assign({}, state, {primaryCargoTypeObjStr: action.primaryCargoTypeObjStr})
        break;
        case "CHANGE_POST_VESSEL_TYPES":
        return Object.assign({}, state, {vesselTypeObjStr: action.vesselTypeObjStr})
        break;
        case "CHANGE_POST_MOVEMENT_DATE_DYNAMIC":
            return Object.assign({}, state, {movementDateDynamic: action.movementDateDynamic})
            break;
        case "CHANGE_POST_VESSEL_OBJECT":
            return Object.assign({}, state, {vesselObjStr: action.vesselObjStr})
            break;
        case "CHANGE_IN_PORT_DATE":
            return Object.assign({}, state, {inPortDate: action.inPortDate})
            break;
        case "CHANGE_SEARCH_SHIPPING_INPUT":
            return Object.assign({}, state, {[action.payload.key]: action.payload.value})
        case "LOAD_SHIPPING_SETTINGS":
            return Object.assign({}, state, {
                fromDate: action.payload.fromDate,
                includeArrivals: action.payload.includeArrivals,
                includeDepartures: action.payload.includeDepartures,
                includeShifts: action.payload.includeShifts,
                inPortDate: action.payload.inPortDate,
                movementDateDynamic: action.payload.movementDateDynamic,
                primaryCargoType: action.payload.primaryCargoType,//need?
                primaryCargoTypeObjStr: action.payload.primaryCargoTypeObjStr,
                toDate: action.payload.toDate,
                vessel: action.payload.vessel,//need?
                vesselObjStr: action.payload.vesselObjStr,
                vesselType: action.payload.vesselType,//need?
                vesselTypeObjStr: action.payload.vesselTypeObjStr,
                radioButton: action.radioButton,
            })
            break;
        case "LOAD_SHIPPING_DYNAMIC_DATE_SETTINGS":
            return Object.assign({}, state, {
                dateRangeNext: action.dateRangeNext, 
                dateRangeValue: action.dateRangeValue, 
                dateRangeType: action.dateRangeType,
            })
            break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer