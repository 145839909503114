import React from "react"
import { connect } from "react-redux"
import { sendEmail } from "../../actions"


class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            name:"",
            email:"",
            phone:"",
            message:"",
            radio:"Account"
         }
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }
    handleRadioChange = event =>{
       this.setState({radio:event.target.name})
    }

    handleOnChange = event => {
        this.setState({[event.target.name]: event.target.value})
    }

    validateEmail = email =>{
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return emailRegex.test(email)
    }

    handleSubmit = (event) =>{
        event.preventDefault()
        var mandatoryFieldsFilled = false
        if(this.state.name && this.state.email && this.state.phone && this.state.message){
            mandatoryFieldsFilled = true
        }
        var postBody = {
            "subject": "Contact",
            "body": `\nFull Name: ${this.state.name}\n
                    Subject: ${this.state.radio}\n
                    Email: ${this.state.email}\n
                    Phone:${this.state.phone}\n
                    Message:${this.state.message}`
        }
        if(this.validateEmail(this.state.email) && mandatoryFieldsFilled){
           this.props.dispatch(sendEmail(postBody)) 
        } else if(mandatoryFieldsFilled === false){
            alert("Please enter all fields")
        }
        else {
            alert("Please enter a valid email address")
        }


    }


    render() { 
        return ( 
            <div className="modal-background">
            <div className="contact-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                    <span>Contact Us</span>
                </div>
                <div className="modal-content-container">
                    <form onSubmit={this.handleSubmit} className="contact-form">
                        <div>
                            <span>Full Name:<b className="red">*</b></span>
                            <input 
                                type="text" 
                                value={this.state.name}
                                onChange={this.handleOnChange}
                                name="name"
                                className="contact-input"
                            />
                        </div>
                        <div>
                            <span>E-mail address:<b className="red">*</b></span>
                            <input 
                                type="text" 
                                value={this.state.email}
                                onChange={this.handleOnChange}
                                name="email"
                                className="contact-input"
                            />
                        </div>
                        <div>
                            <span>Phone number:<b className="red">*</b></span>
                            <input 
                                type="text" 
                                value={this.state.phone}
                                onChange={this.handleOnChange}
                                name="phone"
                                className="contact-input"
                            />
                        </div>
                        <div>
                        <span>Enquiry Type:</span>
                        <input type="checkbox" name="Cargo/Shipping" checked={this.state.radio === "Cargo/Shipping"} onChange={this.handleRadioChange}></input><span className="contact-radio-span">Cargo &amp; Shipping</span>
                        <br/>
                        <span></span><input type="checkbox" name="Account" checked={this.state.radio === "Account"} onChange={this.handleRadioChange}></input><span className="contact-radio-span">Commercial &amp; Account</span>
                        <br/>
                        <span></span><input type="checkbox" name="Other" checked={this.state.radio === "Other"} onChange={this.handleRadioChange}></input><span className="contact-radio-span">Other</span>
                        </div>
                        <div>
                            <label>Message:<b className="red">*</b></label>
                            <textarea 
                                value={this.state.message}
                                onChange={this.handleOnChange}
                                rows="10"
                                name="message"
                            />
                        </div>
                        <div >
                            <input 
                                type="submit" 
                                value="Submit" 
                                className="submit-button float-right"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
         );
    }
}

function mapStateToProps(state){
    return {

    }
  }  
 
export default connect(mapStateToProps)(ContactUs);