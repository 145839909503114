import React from "react"
import {connect} from "react-redux"
import { vehiclesPost } from "../../actions"
import { todayDate, setDynamicDateRange } from "../../utils"
import { searchVehicles } from "../../actions/search"





class VehiclesSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount(){
        //If logged in and have not yet searched vehicles, a search is triggered
        this.props.isLoggedIn && !this.props.vehiclesLoggedInSearched && this.props.dispatch(searchVehicles())
        this.props.isLoggedIn && !this.props.vehiclesLoggedInSearched && this.props.dispatch({type:"SET_LOGGED_IN_SEARCHED", key:"vehiclesLoggedInSearched"})

    }
    handleSubmit = event =>{
        event.preventDefault()
        this.props.dispatch(searchVehicles())
    }

    handleCheckbox = event => {
        this.props.dispatch(
            {
                type: "CHANGE_SEARCH_VEHICLES_INPUT",
                payload:{
                   key: event.target.name,
                    value:event.target.checked
                }
            }
        )
    }

    handleInput = event => {
        this.props.dispatch(
            {
                type: "CHANGE_SEARCH_VEHICLES_INPUT",
                payload:{
                   key: event.target.name,
                    value:event.target.value
                }
            }
        )
    }

    renderOperatorSelect = ()=>{
        if(this.props.isLoggedIn === true){
          return( 
                <div className="input-div two-items-div">
                    <span>Operator: </span>
                    <select 
                    className="input-select" 
                    onChange={this.handleInput} 
                    name="operatorObjStr"
                    value={this.props.vehiclesSearch.operatorObjStr}
                    >
                        <option value=""></option>
                        {this.props.operators && this.props.operators.map((item, i)=>{
                    return(
                        <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                    )
            })}
                    </select>
                </div>
          )
        }
    }

    handleReleasedRadioChange = event =>{
        if(event.target.checked === false){
            this.props.dispatch({type:"CHANGE_SEARCH_VEHICLES_INPUT", payload:{ key:"radioReleasedDate", value: "none"}})

        }else{
            this.props.dispatch({type:"CHANGE_SEARCH_VEHICLES_INPUT", payload:{ key:"radioReleasedDate", value: event.target.name}})

        }
    }

    render() { 
        return ( 
            
            <React.Fragment>
                <br/>
                <form onSubmit={this.handleSubmit} className="search-form">
                <div className="submit-conatiner">
                    <div>
                    {this.props.isLoggedIn && 
                            <input className="submit-button" type="button" value="Save/Load Search" onClick={()=>{this.props.dispatch({type:"CHANGE_VISIBLE_MODAL_BOX", visibleModalBox:"settingsVehicles"})}}></input>
                        }
                    </div>
                    <div>
                        <input className={this.props.vehiclesSearchLoading?"button-loading submit-button":"submit-button"} type="submit"></input>
                    </div>
                </div>
                <hr></hr>
                <div className="textarea-div two-items-div">
                        <span>VINs:</span>
                        <textarea className="input-date" rows="3" name="ids" value={this.props.vehiclesSearch.ids} onChange={this.handleInput}></textarea>
                </div>
                <div className={this.props.isLoggedIn === true?"":"not-logged-in"}>
                    <div className="input-div two-items-div">
                            <span>Voyage: </span>
                            <select 
                                className="input-select" 
                                name="voyageObjStr" 
                                onChange={this.handleInput}
                                value={this.props.vehiclesSearch.voyageObjStr}
                            >
                                <option value=""></option>
                                {this.props.voyages && this.props.voyages.map((item, i)=>{
                            return(
                                <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                            )
                        })}
                            </select>
                        </div>
                        <div className="input-div two-items-div">
                            <span>Ocean BOL: </span>
                            <select 
                                className="input-select" 
                                value={this.props.vehiclesSearch.oceanBOL} 
                                name="oceanBOL" 
                                onChange={this.handleInput}
                            >
                                <option value=""></option>
                            {this.props.oceanBOLs && this.props.oceanBOLs.map((item, i)=>{
                            return(
                                <option key={i} value={item}>{item}</option>
                            )
                        })}
                            </select>
                        </div>
                        <div className="input-div two-items-div">
                            <span>House BOL: </span>
                            <input 
                                className="input-select" 
                                value={this.props.vehiclesSearch.houseBOL} 
                                list="houseBOL" 
                                name="houseBOL" 
                                onChange={this.handleInput}
                            />
                            <datalist id="houseBOL">
                            {this.props.houseBOLs && this.props.houseBOLs.map((item, i)=>{
                            return(
                                <option key={i} value={item}/>
                            )
                        })}
                            </datalist>
                        </div>
                        <div className="input-div two-items-div">
                            <span>Client Reference: </span>
                            <select 
                                className="input-select" 
                                value={this.props.vehiclesSearch.clientReference} 
                                name="clientReference" 
                                onChange={this.handleInput}
                            >
                            <option value=""></option>
                            {this.props.clientRefs && this.props.clientRefs.map((item, i)=>{
                            return(
                                <option key={i} value={item}>{item}</option>
                            )
                        })}
                            </select>
                        </div>
                        <div className="input-div two-items-div">
                            <span>Customs Entry Number: </span>
                            <input 
                                className="input-select" 
                                value={this.props.vehiclesSearch.customsEntryNumber} 
                                list="customsEntryNumber" 
                                name="customsEntryNumber" 
                                onChange={this.handleInput}
                            />
                            <datalist id="customsEntryNumber">
                            {this.props.customsEntryNumbers && this.props.customsEntryNumbers.map((item, i)=>{
                            return(
                                <option key={i} value={item}/>
                            )
                        })}
                            </datalist>
                        </div>
                        {this.renderOperatorSelect()}
                        <div className="input-div two-items-div">
                            <span>Storage Days: </span>
                            <input type="number" className="input-select" name="storageDays" value={this.props.vehiclesSearch.storageDays} onChange={this.handleInput}></input>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">Cleared: </span><input type="checkbox" name="includeCleared" checked={this.props.vehiclesSearch.includeCleared} onChange={this.handleCheckbox}></input></div>
                            <div className="checkbox-div"><span className="checkbox-name">On Hold: </span><input type="checkbox" name="includeOnHold" checked={this.props.vehiclesSearch.includeOnHold} onChange={this.handleCheckbox}></input></div>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">Ready to Deliver: </span><input type="checkbox" name="includeReadyToDeliver" checked={this.props.vehiclesSearch.includeReadyToDeliver} onChange={this.handleCheckbox}></input></div>
                            <div className="checkbox-div"><span className="checkbox-name">Not Ready to Deliver: </span><input type="checkbox" name="includeNotReadyToDeliver" checked={this.props.vehiclesSearch.includeNotReadyToDeliver} onChange={this.handleCheckbox}></input></div>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">On Port: </span><input type="checkbox" name="includeOnPort" checked={this.props.vehiclesSearch.includeOnPort} onChange={this.handleCheckbox}></input></div>
                            <div className="checkbox-div"><span className="checkbox-name">On Ship: </span><input type="checkbox" name="includeOnShip" checked={this.props.vehiclesSearch.includeOnShip} onChange={this.handleCheckbox}></input></div>
                        </div>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">Off Port: </span><input type="checkbox" name="includeOffPort" checked={this.props.vehiclesSearch.includeOffPort} onChange={this.handleCheckbox}></input></div>
                        </div>
                    </div>
                    <div className={this.props.vehiclesSearch.includeOffPort === true && this.props.isLoggedIn ===true?"":"not-logged-in"}>
                        <div className="input-div">
                            <p>Off Port Date:</p>
                            <input type="checkbox" name="dynamicDate" checked={this.props.vehiclesSearch.radioReleasedDate === "dynamicDate"} onChange={this.handleReleasedRadioChange}></input>
                            <span className="input-title">Last: </span>
                            <input type="number" className="number-input" value={this.props.vehiclesSearch.dateRangeNumber} name="dateRangeNumber" onChange={this.handleInput}></input>
                            <select className="input-select"  name="dateRangeType" onChange={this.handleInput}>
                                <option value="Day(s)">Day(s)</option>
                                <option value="Week(s)">Week(s)</option>
                                <option value="Month(s)">Month(s)</option>
                                <option value="Year(s)">Year(s)</option>
                            </select>
                        </div>
                        <div className="input-div">
                            <input type="checkbox" name="fromDateToDate" checked={this.props.vehiclesSearch.radioReleasedDate === "fromDateToDate"} onChange={this.handleReleasedRadioChange}></input>
                            <span className="input-title">From:</span>
                            <input type="date" className="input-date" name="releasedFromDate" onChange={this.handleInput}></input>
                                <span className="input-title">To:</span>
                            <input type="date" className="input-date" name="releasedToDate" onChange={this.handleInput}></input>
                        </div>
                        <br/>
                    </div>
                </form>
            </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        voyages: state.dropdowns.vehiclesVoyages,
        operators: state.dropdowns.operators,
        oceanBOLs: state.vehiclesFilterLookup.oceanBOLs,
        houseBOLs: state.vehiclesFilterLookup.houseBOLs,
        clientRefs: state.vehiclesFilterLookup.clientRefs,
        customsEntryNumbers: state.vehiclesFilterLookup.customsEntryNumbers,
        userInfo: state.userInfo,
        vehiclesSearch: state.vehiclesSearch,
        vehiclesSearchLoading: state.loading.vehiclesSearchLoading,
        vehiclesLoggedInSearched: state.visibleComponents.vehiclesLoggedInSearched

    }
  }  

export default connect(mapStateToProps)(VehiclesSearch);