import { convertArrayToString, convertStringToArray } from "../utils"


export function loadShippingSettings (settings){
    return dispatch =>{
    //Handling of column settings
        var shippingColumns = settings.columnSettings
        var columnSorts = settings.columnSorts
        if(!columnSorts){columnSorts = []}
        shippingColumns.map((item)=>{
            var widthVal = item.width
            item.width < 20? widthVal = 75:widthVal = item.width //to prevent 0 value widths for table
        
            //Set each column settings
            dispatch({
                type:"CHANGE_ORG_SHIP_COLS",
                payload:{
                    showCol:item.name,
                    show:item.show,
                    widthCol:item.name+"Width",
                    width:widthVal,
                }
            })
        })
        //Set sort order
        dispatch({type:"CHANGE_ORG_SHIP_COL_ITEM", payload:{key:"defaultSorted", value:columnSorts}})
        
        //Set column positions
        var colPositions = shippingColumns.reduce(
            (obj, item) => Object.assign(obj, { [item.name+"Position"]: item.position }), {});
        dispatch({type:"UPDATE_SHIP_COL_ORDER", shippingColOrder:colPositions})
        //Key needs to be altered so draggable table will rerender. Otherwise column order will not update.
        dispatch({type:"INCREMENT_SHIPPING_KEY"})

    //Handling of Search Settings
        var shippingSettings = settings.shippingMovementFilter
        // if Setting has movementDateDynamic string, it is applied to the corresponding inputs. Otherwise remains unchanged
        if(shippingSettings.movementDateDynamic){
            var movementDateDynamicArray = shippingSettings.movementDateDynamic.split(",")
            dispatch({
                type: "LOAD_SHIPPING_DYNAMIC_DATE_SETTINGS",
                dateRangeNext: movementDateDynamicArray[0], 
                dateRangeValue: parseInt(movementDateDynamicArray[1]), 
                dateRangeType: movementDateDynamicArray[2],
            })
        }

        //Sets which radio button is active
        var radioButtonValue = "none"
        if(shippingSettings.movementDateDynamic){
            radioButtonValue = "dynamicDate"
        } else if(shippingSettings.inPortDate){
            radioButtonValue = "inPortDate"
        } else{
            radioButtonValue = "fromDateToDate"
        }
        
        dispatch({
            type: "LOAD_SHIPPING_SETTINGS",
            payload: shippingSettings,
            radioButton: radioButtonValue,
        })
    //    dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"}) //Move to component?
        
        
    }

}

export function loadContainersSettings(settings){
    return dispatch =>{
               //Handling of Column Settings
                    var containersColumns = settings.columnSettings
                    var columnSorts = settings.columnSorts
                    if(!columnSorts){columnSorts = []}
                    containersColumns.map((item)=>{
                        var widthVal = item.width
                        item.width < 20? widthVal = 75:widthVal = item.width //to prevent 0 value widths for table
                        dispatch({
                            type:"CHANGE_ORG_CONT_COLS",
                            payload:{
                                showCol:item.name,
                                show:item.show,
                                widthCol:item.name+"Width",
                                width:widthVal
                            }
                        })
                    })
                    dispatch({type:"CHANGE_ORG_CONT_COL_ITEM", payload:{key:"defaultSorted", value:columnSorts}}) 

        //Set column positions
        var colPositions = containersColumns.reduce(
            (obj, item) => Object.assign(obj, { [item.name+"Position"]: item.position }), {});
        dispatch({type:"UPDATE_CONT_COL_ORDER", containersColOrder:colPositions})
        // Key needs to be altered so draggable table will rerender. Otherwise column order will not update.
        dispatch({type:"INCREMENT_CONTAINERS_KEY"})

                
                
                //Handling of Search Settings
                    var containersSettings = settings.cargoItemFilter
                    var storageDaysVal = containersSettings.storageDays
                    if(storageDaysVal< 1 || typeof(storageDaysVal) !== "number"){storageDaysVal = ""}
                    dispatch({
                        type: "LOAD_CONTAINERS_SETTINGS",
                        payload: containersSettings,
                        ids:convertArrayToString(containersSettings.ids),
                        cedos:convertArrayToString(containersSettings.cedos),
                        storageDays: storageDaysVal
                    })            
    }
}

export function loadVehiclesSettings(settings){
    return dispatch =>{
               //Handling of Column Settings
                var vehiclesColumns = settings.columnSettings
                var columnSorts = settings.columnSorts
                if(!columnSorts){columnSorts = []}
                vehiclesColumns.map((item)=>{
                    var widthVal = item.width
                    item.width < 20? widthVal = 76:widthVal = item.width //to prevent 0 value widths for table
                    dispatch({
                        type:"CHANGE_ORG_VEH_COLS",
                        payload:{
                            showCol:item.name,
                            show:item.show,
                            widthCol:item.name+"Width",
                            width:widthVal
                        }
                    })
                })
                dispatch({type:"CHANGE_ORG_VEH_COL_ITEM", payload:{key:"defaultSorted", value:columnSorts}})
    
                //Set column positions
                var colPositions = vehiclesColumns.reduce(
                    (obj, item) => Object.assign(obj, { [item.name+"Position"]: item.position }), {});
                dispatch({type:"UPDATE_VEH_COL_ORDER", vehiclesColOrder:colPositions})
                //Key needs to be altered so draggable table will rerender. Otherwise column order will not update.
                dispatch({type:"INCREMENT_VEHICLES_KEY"})

            
            //Handling of Search Settings
            //if text input is selected, index= -1.
                var vehiclesSettings = settings.cargoItemFilter
                var storageDaysVal = vehiclesSettings.storageDays
               if(storageDaysVal < 1 || typeof(storageDaysVal) !== "number"){storageDaysVal = ""}
                dispatch({
                    type: "LOAD_VEHICLES_SETTINGS",
                    payload: vehiclesSettings,
                    ids:convertArrayToString(vehiclesSettings.ids),
                    storageDays: storageDaysVal

                })    
    }
}