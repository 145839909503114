import React from 'react'
import { connect } from 'react-redux'
import ShippingSubTabs from './ShippingSubTabs'
import ContainersDetails from './ContainersDetails'
import ContainersSubTabs from './ContainersSubTabs'
import VehiclesSubTabs from "./VehiclesSubTabs"
import CollapsedDetails from './CollapsedDetails'

class DetailsSubTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }



    renderSubTabs = ()=>{
        if(this.props.collapsedDetails === true){
            return <CollapsedDetails/>
        } else if(this.props.visibleTab==="Shipping"){
            return <ShippingSubTabs/>
        } else if(this.props.visibleTab==="Containers"){
            return <ContainersSubTabs/>
        } else if(this.props.visibleTab==="Vehicles"){
            return <VehiclesSubTabs/>
        } else{
            return ""
        }
    }

    render() { 
        return ( 
                <React.Fragment>
                <div id="container-sub-tabs">
                    {this.renderSubTabs()}
                </div>
                <div>
                </div>
                </React.Fragment>
         );
    }
}
 
function mapStatetoProps(state){
    return{
        visibleTab: state.visibleComponents.visibleTab,
        collapsedDetails: state.visibleComponents.collapsedDetails
    }
    }

export default connect(mapStatetoProps)(DetailsSubTab);