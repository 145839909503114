import React from "react"
import { connect } from "react-redux";
import { todayDate, nextWeekDate } from "../../../utils"

class DateRange extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            movementDateDynamic:"",
            dateRangeNext:"Next",
            dateRangeValue:"1",
            dateRangeType:"Week(s)",
         }
    }

    componentDidMount(){
        //Set today date value and next week date value if not set
        var todayValue = todayDate()
        var nextWeekValue = (nextWeekDate())
        this.props.fromDate === "" && this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:"fromDate", value:todayValue}})
        this.props.toDate === "" && this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:"toDate", value:nextWeekValue}})
       
      }



    handeRadioDateFromChange = event =>{
        this.props.dispatch()
    }



    handleChange = event =>{
        this.props.dispatch({
           type: "CHANGE_SEARCH_SHIPPING_INPUT" ,
           payload:{key:event.target.name, value:event.target.value}
        })
    }
    render() { 
        return ( 
            <div className="date-range-conatiner">
                <p>Date Range:</p>
            <div className="input-div">
                  <input type="radio" name="dynamicDate" value="" checked={this.props.shippingSearch.radioButton === "dynamicDate"} onChange={()=>{this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:"radioButton", value:event.target.name}})}}></input>
                <select className="input-select" value={this.props.dateRange.dateRangeNext} name="dateRangeNext" onChange={this.handleChange}>
                    <option value="Next">Next</option>
                    <option value="Last">Last</option>
                </select>
                <input type="number" className="number-input" value={this.props.dateRange.dateRangeValue} name="dateRangeValue" onChange={this.handleChange}></input>
                <select className="input-select" value ={this.props.dateRange.dateRangeType} name="dateRangeType" onChange={this.handleChange}>
                    <option value="Day(s)">Day(s)</option>
                    <option value="Week(s)">Week(s)</option>
                    <option value="Month(s)">Month(s)</option>
                    <option value="Year(s)">Year(s)</option>
                </select>
            </div>
                <br/>
            <div className="input-div">
                <input type="radio" name="fromDateToDate" value="" checked ={this.props.shippingSearch.radioButton === "fromDateToDate"} onChange={()=>{this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:"radioButton", value:event.target.name}})}}></input>
                <span className="input-title">From:</span>
                <input type="date" className="input-date" name="fromDate" value={this.props.fromDate} onChange={this.handleChange}></input>
                    <span className="input-title">To:</span>
                <input type="date" className="input-date" name="toDate" value={this.props.toDate} onChange={this.handleChange}></input>
            
              
            
            </div>
            </div>
         );
    }
}
function mapStateToProps(state){
    return {
        dateRange: state.shippingSearch,
        fromDate: state.shippingSearch.fromDate,
        toDate: state.shippingSearch.toDate,
        shippingSearch: state.shippingSearch
    }
  } 
export default connect(mapStateToProps)(DateRange)