import React from "react"
import { connect } from 'react-redux'
import ShippingDetails from './ShippingDetails'
class ShippingSubTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    renderSubComponent = ()=>{
        if(this.props.shippingSubTab==="Details"){
            return <ShippingDetails/>
        }else{
            return ""
        }
    }

    render() { 
        return ( 
            <React.Fragment>
            <div className="flex-row">
                <div className="container-tab">
                    <div id={this.props.shippingSubTab ==="Details"?"tabactivated":""} className="tabunactive tabactive" onClick={() => this.props.dispatch({type: "CHANGE_VISIBLE_SHIPPING_SUBTAB", shippingSubTab: "Details"})}>Details</div>
                </div>
                <div>
                    <img id="expanded-details-arrow" src="./images/chevron-circle-left.svg" onClick={()=>this.props.dispatch({type:"COLLAPSE_EXPAND_DETAILS", value:true})}/>
                </div>
            </div>
            {this.renderSubComponent()}
            </React.Fragment>
         );
    }
}
function mapStateToProps(state){
    return {
        shippingSubTab: state.visibleComponents.shippingSubTab
    }
  } 
export default connect(mapStateToProps)(ShippingSubTabs);