import React from "react"
import { connect } from "react-redux";
import { formatEpochDate } from "../../utils"

class VehiclesDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    handleChange = event =>{}

    render() { 
        return ( 
            <div className="details-container vehicles-details">
                <div className="details-column">
                     <p><span>ID:</span>{this.props.details.id}</p>
                     <p><span>Stops:</span>{this.props.details.stops}</p>
                     <p><span>MPI Status:</span>{this.props.details.mafStatus}</p>
                     <p><span>Operator:</span>{this.props.details.operator}</p>
                {this.props.isLoggedIn && 
                    <React.Fragment>
                    <p><span>Customs Agent:</span>{this.props.details.customsAgent}</p>
                    <p><span>Consignee:</span>{this.props.details.consignee}</p>
                    <p><span>Notify Party:</span>{this.props.details.notifyParty}</p>
                    <p><span>Freight Forwarder:</span>{this.props.details.freightForwarder}</p>
                    <p><span>Release Carrier:</span>{this.props.details.releaseCarrier}</p>
                    <p><span>Release Vehicle:</span>{this.props.details.releaseVehicle}</p>
                    <p><span>Ocean Bill:</span>{this.props.details.oceanBillOfLading}</p>
                    <p><span>House Bill:</span>{this.props.details.houseBillOfLading}</p>
                    <p><span>Client Ref:</span>{this.props.details.clientRef}</p>
                    <p><span>Customs Entry:</span>{this.props.details.customsEntryNumber}</p>
                    </React.Fragment>
                }
                </div>
                <div className="details-column details-column-2">
                    <p><span>Vessel:</span>{this.props.details.vessel}</p>
                    {this.props.isLoggedIn && 
                    <p><span>Load Port:</span>{this.props.details.loadPort}</p>
                    }
                    <p><span>Voyage Code:</span>{this.props.details.portVoyageCode}</p>
                    <p><span>Locality:</span>{this.props.details.locality}</p>
                    {this.props.isLoggedIn && 
                    <p><span>Location On Port:</span>{this.props.details.locationOnPort}</p>
                    }
                    <p><span>Yard Location:</span>{this.props.details.yardLocation}</p>
                    <p><span>Received Date:</span>{this.props.details.receivedDateTime && formatEpochDate(this.props.details.receivedDateTime)}</p>
                    <p><span>Released Date:</span>{this.props.details.releasedDateTime && formatEpochDate(this.props.details.releasedDateTime)}</p>
                    <p><span>Last Day Of Free Storage:</span>{this.props.details.lastDayOfFreeStorage && formatEpochDate(this.props.details.lastDayOfFreeStorage)}</p>
                    {this.props.isLoggedIn && 
                    <React.Fragment>
                    <p><span>Available For Delivery:</span>{this.props.details.availableForDeliveryDateTime && formatEpochDate(this.props.details.availableForDeliveryDateTime)}</p>
                    <p><span>Key Number:</span>{this.props.details.keyNumber}</p>
                    <p><span>Condition:</span>{this.props.details.condition}</p>
                    <p><span>Has Radio:</span>{this.props.details.hasRadio}</p>
                    <p><span>Carrier Remarks:</span>{this.props.details.carrierRemarks}</p>
                    </React.Fragment>
                    }






                </div>
            </div>
         );
    }
}
 

function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        details: state.detailsTab.vehiclesDetails
    }
  }  


export default connect(mapStateToProps)(VehiclesDetails);