const initialState = {
    containersVoyages: [],
    vehiclesVoyages:[],
    consignees: [],
    consignors:[],
    freightForwarders:[],
    containerCarriers:[],
    carCarriers:[],
    operators:[]
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CONTAINERS_VOYAGES':
            return Object.assign({}, state, {containersVoyages: action.containersVoyages})
        break;
        case 'UPDATE_VEHICLES_VOYAGES':
            return Object.assign({}, state, {vehiclesVoyages: action.vehiclesVoyages})
        break;
        case 'UPDATE_CONSIGNEES_LIST':
            return Object.assign({}, state, {consignees: action.payload})
        case 'UPDATE_CONSIGNORS_LIST':
            return Object.assign({}, state, {consignors: action.payload})
        break;
        break;
        case 'UPDATE_FF_LIST':
            return Object.assign({}, state, {freightForwarders: action.payload})
        break;
        case 'UPDATE_CONTAINER_CARRIERS_LIST':
            return Object.assign({}, state, {containerCarriers: action.payload})
        break;
        case 'UPDATE_CAR_CARRIERS_LIST':
            return Object.assign({}, state, {carCarriers: action.payload})
        break;
        case 'UPDATE_OPERATORS_LIST':
            return Object.assign({}, state, {operators: action.payload})
        break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer