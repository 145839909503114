import React from "react"
import { connect } from "react-redux"
import { toggleLoginBox, loginStatus, loginUser, updateUserInfo } from "../../actions"

class LoginBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            userName: "",
            password: "",
            errorText: "",
            loading:false
         }
    }

    componentDidMount(){
        this.nameInput.focus();
    }

    handleLoginSubmit = event =>{
        event.preventDefault()
        this.setState({
            errorText: "",
            loading: true
        })
        return this.props.dispatch(loginUser({
            "user": {

                "loginId": this.state.userName,
                "password": this.state.password
            }
        }))
        .then(res =>{
            if(res === "loggedIn"){
                this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            } else if(res === "expired"){
                this.setState({loading:false})
                this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "changePasswordForm"})

            } else if(res === "error"){
                this.setState({errorText: "An error has occured"})
                this.setState({loading:false})
            } else {
                res && this.setState({errorText: res})
                this.setState({loading:false})
            }
        })
        .catch(err =>{
            console.log(err)
        })
        
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            this.props.dispatch({type: "CLEAR_USER_INFO"})
        }
    }

    handleOnChange = event => {
        this.setState({[event.target.name]: event.target.value})
        this.props.errorText?this.props.dispatch({type: "CLEAR_USER_INFO"}):""
    }

    render() { 
        return ( 
            <div className="modal-background">
                <div className="login-container">
                    <div className="close" onClick={this.handleCancel}>&times;</div>
                    <div className="modal-header">
                        <span>Login</span>
                    </div>
                    <div className="modal-content-container">
                    <form onSubmit={this.handleLoginSubmit}>
                        {this.state.errorText && <div id="login-error-text">{this.state.errorText}</div>}
                        <div className="input-div">
                            <input 
                            type="text" 
                            name = "userName" 
                            value = {this.state.userName} 
                            placeholder="Username" 
                            onChange={this.handleOnChange} 
                            ref={(inputEl) => this.nameInput = inputEl}
                            />
                        </div>
                        <br/>
                        <div className="input-div">
                            <input type="password" name = "password" value={this.state.password} placeholder="Password" onChange={this.handleOnChange} onSubmit={this.handleLoginSubmit}/>
                        </div>
                        <br/>
                        <div id="login-buttons-container">
                        <input type="submit" className={this.state.loading?"button-loading submit-button":"submit-button"} id="login-button" value="Login"/>
                        </div>
                        <br/>
                        <div className = "forgot-password" onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "forgotPassword"})}>Forgot Username or Password?</div>
                    </form>
                    </div>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
        loginBoxVisible : state.visibleComponents.loginBoxVisible,
        isLoggedIn: state.visibleComponents.isLoggedIn,
    }
  }  

export default connect(mapStateToProps)(LoginBox);