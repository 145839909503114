import React from "react"
import { connect } from "react-redux"
import { sendContainerUpdate } from "../../../actions"

class ContainersTableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          data: []
         }
    }

    postChanges = ()=>{
        this.props.containersSaveArray.map((index)=>{
          var postBody ={	"cargoItemUpdate" : {
            "carrierObjectString" : this.props.containersReturn[index].releaseCarrierObjectString,
            "carrierRemarks" : this.props.containersReturn[index].carrierRemarks,
            "consigneeObjectString" : this.props.containersReturn[index].consigneeObjectString,
            "freightForwarderObjectString" : this.props.containersReturn[index].freightForwarderObjectString,
            "hasCustomsAgentStop" : this.props.containersReturn[index].hasCustomsAgentStop,
            "hasFreightForwarderStop" : this.props.containersReturn[index].hasFreightForwarderStop,
            "hasOperatorStop" : this.props.containersReturn[index].hasOperatorStop,
            "objectEdition": this.props.containersReturn[index].objectEdition.toString(),
            "targetObjectString": this.props.containersReturn[index].cargoItemObjectString
          },
          "user" :{
              "userObjectString": this.props.userInfo.user.userObjectString
          },
          "sessionId": this.props.userInfo.sessionId
        
        }
        this.props.dispatch(sendContainerUpdate(postBody, index))
        console.log(postBody)
        })
      }


    renderEditAll= ()=>{
      if(
        this.props.visibleComponents.editAllConFFStop ||
        (this.props.visibleComponents.editAllConCarrier && this.props.dropdowns.containerCarriers.length > 0 ) ||
        this.props.visibleComponents.editAllConOperatorStop ||
        (this.props.visibleComponents.editAllConFF && this.props.dropdowns.freightForwarders.length > 0 ) ||
        this.props.visibleComponents.editAllConCarrierRemarks
        ){
        return <span className="table-header-buttons" onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "editAllContainers"})}>Edit All</span>
      }
    }

    render() { 
        return ( 
<React.Fragment>
    <div>Containers</div>
        <div>
            {this.props.isLoggedIn && this.props.containersSaveButton && <span className="table-header-buttons" onClick={this.postChanges}>Save</span>}
            {this.props.isLoggedIn && this.renderEditAll()}
            {this.props.isLoggedIn && <span className="table-header-buttons" onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "organiseContainersTable"})}>Organise</span>}
            <span className="table-header-buttons" onClick={this.props.handleExport}>Export</span>
        </div>
</React.Fragment>

         );
    }
}

function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        containersSaveButton: state.visibleComponents.containersSaveButton,
        containersSaveArray: state.visibleComponents.containersSaveArray,
        containersReturn: state.containersReturn,
        userInfo: state.userInfo,
        visibleComponents: state.visibleComponents,
        dropdowns: state.dropdowns
    }
  } 

export default connect(mapStateToProps)(ContainersTableHeader);