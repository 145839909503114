import React from "react"
import { connect } from "react-redux";
import { formatEpochDate } from "../../utils"

class ContainersDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    handleChange = event =>{
        console.log("do nothing")
        this.setState({note: "keep react happy"})
    }

    render() { 
        return ( 
            <div className="details-container vehicles-details">
                <div className="details-column">
                    <p><span>ID:</span>{this.props.details.id}</p>
                    {this.props.isLoggedIn && <p><span>CEDO2:</span>{this.props.details.cedo}</p>}
                    <p><span>Stops:</span>{this.props.details.stops}</p>
                    <p><span>IMEX Status:</span>{this.props.details.imexStatus}</p>
                    <p><span>Operator:</span>{this.props.details.operator}</p>
                    <p><span>Length/Width/Height:</span>{this.props.details.lengthWidthHeight}</p>
                    <p><span>Weight:</span>{this.props.details.weight}</p>
                    <p><span>VGM:</span>{this.props.details.isWeightCertified === true?"Yes":this.props.details.isWeightCertified === false?"No":""}</p>
                    <p><span>Has Tasks:</span>{this.props.details.hasTasks === true?"Yes":this.props.details.hasTasks === false?"No":""}</p>
                    {!this.props.isLoggedIn && <p><span>Is Reefer:</span>{this.props.details.isReefer === true?"Yes":this.props.details.isReefer === false?"No":""}</p>}
                    {this.props.isLoggedIn && <p><span>Powered Reefer:</span>{this.props.details.isReefer === true?"Yes":this.props.details.isReefer === false?"No":""}</p>}
                    {this.props.isLoggedIn && <p><span>Temperature:</span>{this.props.details.temperature}</p>}
                    <p><span>Is Overdimensional:</span>{this.props.details.isOverdimensional === true?"Yes":this.props.details.isOverdimensional === false?"No":""}</p>
                    <p><span>Hazardous Description:</span>{this.props.details.hazardousDescription}</p>
                    <p><span>Yard Location:</span>{this.props.details.yardLocation}</p>
                    {this.props.isLoggedIn && <p><span>Consignee:</span>{this.props.details.consignee}</p>}
                    {this.props.isLoggedIn && <p><span>Consignor:</span>{this.props.details.consignor}</p>}
                    {this.props.isLoggedIn && <p><span>Customs Entry:</span>{this.props.details.customsEntryNumber}</p>}
                    {this.props.isLoggedIn && <p><span>Booking Reference:</span>{this.props.details.bookingReference}</p>}
                    {this.props.isLoggedIn && <p><span>Receive Carrier:</span>{this.props.details.receiveCarrier}</p>}
                    {this.props.isLoggedIn && <p><span>Receive Vehicle:</span>{this.props.details.receiveVehicle}</p>}
                    <p><span>Release Carrier:</span>{this.props.details.releaseCarrier}</p>
                    {this.props.isLoggedIn && <p><span>Release Vehicle:</span>{this.props.details.releaseVehicle}</p>}
                    {this.props.isLoggedIn && <p><span>Freight Forwarder:</span>{this.props.details.freightForwarder}</p>}
                </div>
                <div className="details-column details-column-2">
                <p><span>Vessel:</span>{this.props.details.vessel}</p>
                {this.props.isLoggedIn && <p><span>Load Port:</span>{this.props.details.loadPort}</p>}
                {this.props.isLoggedIn && <p><span>Discharge Port:</span>{this.props.details.dischargePort}</p>}
                {this.props.isLoggedIn && <p><span>Destination Port:</span>{this.props.details.destinationPort}</p>}
                {this.props.isLoggedIn && <p><span>Transhipment:</span>{this.props.details.isTranshipment === true?"Yes":this.props.details.isTranshipment === false?"No":""}</p>}
                <p><span>Voyage Code:</span>{this.props.details.portVoyageCode}</p>
                <p><span>ISO Code:</span>{this.props.details.isoCode}</p>
                <p><span>Locality:</span>{this.props.details.locality}</p>
                <p><span>Receive Date:</span>{this.props.details.receivedDateTime && formatEpochDate(this.props.details.receivedDateTime)}</p>
                <p><span>Receive Mode:</span>{this.props.details.receiveMode}</p>
                <p><span>Release Date:</span>{this.props.details.releasedDateTime && formatEpochDate(this.props.details.releasedDateTime)}</p>
                <p><span>Release Mode:</span>{this.props.details.releaseMode}</p>
                <p><span>Last Day of Free Storage:</span>{this.props.details.lastDayOfFreeStorage && formatEpochDate(this.props.details.lastDayOfFreeStorage)}</p>
                <p><span>Expected Discharge Time:</span>{this.props.details.expectedDischargeDateTime && formatEpochDate(this.props.details.expectedDischargeDateTime)}</p>
                <p><span>Description:</span>{this.props.details.description}</p>
                {this.props.isLoggedIn && <p><span>PIN:</span>{this.props.details.pin}</p>}
                {this.props.isLoggedIn && <p><span>Carrier Remarks:</span>{this.props.details.carrierRemarks}</p>}
                {this.props.isLoggedIn && <p><span>Available for Delivery:</span>{this.props.details.availableForDeliveryDateTime && formatEpochDate(this.props.details.availableForDeliveryDateTime)}</p>}
                {this.props.isLoggedIn && <p><span>Commodity:</span>{this.props.details.commodity}</p>}
                {this.props.isLoggedIn && <p><span>Has Been Unpacked:</span>{this.props.details.hasBeenUnpacked === true?"Yes":this.props.details.hasBeenUnpacked === false?"No":""}</p>}
                {this.props.isLoggedIn && <p><span>Optimal Pick Up:</span>{this.props.details.optimalPickIp}</p>}
                {this.props.isLoggedIn && <p><span>Seal:</span>{this.props.details.seal}</p>}
                {this.props.isLoggedIn && <p><span>Connected Date:</span>{this.props.details.connectedDateTime && formatEpochDate(this.props.details.connectedDateTime)}</p>}

                </div>
            </div>
         );
    }
}
 

function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        details: state.detailsTab.containersDetails
    }
  }  


export default connect(mapStateToProps)(ContainersDetails);