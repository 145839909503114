import React from "react"
import {connect} from "react-redux"
import {changeSearchTab} from "../../actions"

class SearchTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div id="container-tab">
                <div id={this.props.visibleTab ==="Shipping"?"tabactivated":""} className="tabunactive tabactive" onClick={()=>this.props.dispatch(changeSearchTab("Shipping"))}>Shipping</div>
                <div id={this.props.visibleTab ==="Containers"?"tabactivated":""} className="tabunactive" onClick={()=>this.props.dispatch(changeSearchTab("Containers"))}>Containers</div>
                <div id={this.props.visibleTab ==="Vehicles"?"tabactivated":""} className="tabunactive" onClick={()=>this.props.dispatch(changeSearchTab("Vehicles"))}>Vehicles</div>
            </div>
         );
    }
}
function mapStateToProps(state){
    return {
        visibleTab: state.visibleComponents.visibleTab
    }
  } 
export default connect(mapStateToProps)(SearchTabs);