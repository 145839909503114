import React from "react"
import { connect } from "react-redux";
import { sendVehicleUpdate } from "../../actions"


class EditAllVehicles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            ffStop:false,
            ffStopValue: false,
            freightForwarder: false,
            freightForwarderValue:"",
            carrier:false,
            carrierValue:"",
            carrierRemarks:false,
            carrierRemarksValue:"",
            operatorStop:false,
            operatorStopValue:false,
            consignee:false,
            consigneeValue:"",
            customsAgentStop:false,
            customsAgentStopValue:false

        }
    }

    componentDidMount(){
  
          }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }

    handleCheckboxChange = event => {
        this.setState({[event.target.name]:event.target.checked})
    }

    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }

    handleSubmit = event =>{
        event.preventDefault()
        
        if(
            this.state.ffStop === true ||
            this.state.freightForwarder === true ||
            this.state.carrier === true ||
            this.state.carrierRemarks === true ||
            this.state.operatorStop === true ||
            this.state.consignee === true ||
            this.state.customsAgentStop === true 
            ){
            for(var i=0 ; i < this.props.vehiclesReturn.length; i++){
                var vehicleValues = this.props.vehiclesReturn[i]
                //post values are the original values unless the corresponding edit box is checked and item has permission to edit that value
                var consignee = vehicleValues.consigneeObjectString
                var hasCustomsAgentStop = vehicleValues.hasCustomsAgentStop
                var hasOperatorStop = vehicleValues.hasOperatorStop
                var hasFreightForwarderStop = vehicleValues.hasFreightForwarderStop
                var freightForwarder = vehicleValues.freightForwarderObjectString
                var carrierRemarks = vehicleValues.carrierRemarks
                var carrier = vehicleValues.releaseCarrierObjectString
                if (this.state.consignee && vehicleValues.canUpdateConsignee){consignee = this.state.consigneeValue}
                if (this.state.customsAgentStop && vehicleValues.canUpdateCustomsAgentStop){hasCustomsAgentStop = this.state.customsAgentStopValue}
                if (this.state.operatorStop && vehicleValues.canUpdateOperatorStop){hasOperatorStop = this.state.operatorStopValue}
                if (this.state.ffStop && vehicleValues.canUpdateFreightForwarderStop){hasFreightForwarderStop = this.state.ffStopValue}
                if (this.state.freightForwarder && vehicleValues.canUpdateFreightForwarder){freightForwarder = this.state.freightForwarderValue}
                if (this.state.carrierRemarks && vehicleValues.canUpdateCarrierRemarks){carrierRemarks = this.state.carrierRemarksValue}
                if (this.state.carrier && vehicleValues.canUpdateCarrier){carrier = this.state.carrierValue}
                console.log(vehicleValues)

                var postBody ={	"cargoItemUpdate" : {
                    "carrierObjectString": carrier,
                    "carrierRemarks": carrierRemarks,
                    "consigneeObjectString" : consignee,
                    "freightForwarderObjectString": freightForwarder,
                    "hasCustomsAgentStop" : hasCustomsAgentStop,
                    "hasFreightForwarderStop": hasFreightForwarderStop,
                    "hasOperatorStop": hasOperatorStop,
                    "objectEdition": this.props.vehiclesReturn[i].objectEdition.toString(),
                    "targetObjectString": this.props.vehiclesReturn[i].cargoItemObjectString
                },
                "user" :{
                    "userObjectString": this.props.userInfo.user.userObjectString
                },
                "sessionId": this.props.userInfo.sessionId
                }
                this.props.dispatch(sendVehicleUpdate(postBody, i))
                console.log(postBody)
            }
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            }else{
                alert("Please select an item to update")
            }
        
    }
    

    render() { 
        return ( 
            <div className="modal-background">
            <div className="organise-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                    <span>Edit All</span>
                </div>
                <div className="modal-content-container">
                <form onSubmit={this.handleSubmit}>
                <table className="edit-all-table">
                    <thead>
                        <tr>
                            <th>Edit:</th>
                            <th>Set to:</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>  
                        {this.props.visibleComponents.editAllVehOperatorStop &&
                        <tr>
                            <td><input type="checkbox" name="operatorStop" checked={this.state.operatorStop} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.operatorStop?"":"unactive"}>Operator Stop:</td>
                            <td className={this.state.operatorStop?"":"unactive"}><input name="operatorStopValue" checked={this.state.operatorStopValue} type="checkbox"  onChange={this.handleCheckboxChange}/></td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllVehFFStop &&
                        <tr>
                            <td><input type="checkbox" name="ffStop" checked={this.state.ffStop} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.ffStop?"":"unactive"}>Freight Forwarder Stop:</td>
                            <td className={this.state.ffStop?"":"unactive"}><input name="ffStopValue" checked={this.state.ffStopValue} type="checkbox" onChange={this.handleCheckboxChange}/></td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllVehFF  &&  this.props.freightForwarders.length > 0 &&
                        <tr>
                            <td><input type="checkbox" name="freightForwarder" checked={this.state.freightForwarder} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.freightForwarder?"":"unactive"}>FreightForwarder:</td>
                            <td className={this.state.freightForwarder?"":"unactive"}>
                                <select className="input-select" onChange={this.handleChange} name="freightForwarderValue">
                                    <option value=""></option>
                                    {this.props.freightForwarders.map((item, i)=>{
                                return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                )
                            })}
                                </select>
                            </td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllVehCustomsAgentStop &&
                        <tr>
                            <td><input type="checkbox" name="customsAgentStop" checked={this.state.customsAgentStop} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.customsAgentStop?"":"unactive"}>Customs Agent Stop:</td>
                            <td className={this.state.customsAgentStop?"":"unactive"}><input name="customsAgentStopValue" checked={this.state.customsAgentStopValue} type="checkbox" onChange={this.handleCheckboxChange}/></td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllVehCarrier  && this.props.carriers.length > 0 &&
                        <tr>
                            <td><input type="checkbox" name="carrier" checked={this.state.carrier} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.carrier?"":"unactive"}>Carrier:</td>
                            <td className={this.state.carrier?"":"unactive"}>
                            <select className="input-select" onChange={this.handleChange} name="carrierValue">
                                    <option value=""></option>
                                    {this.props.carriers.map((item, i)=>{
                                return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                )
                            })}
                                </select>
                            </td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllVehCarrierRemarks &&
                        <tr>
                            <td><input type="checkbox"  name="carrierRemarks" checked={this.state.carrierRemarks} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.carrierRemarks?"":"unactive"}>Carrier Remarks:</td>
                            <td className={this.state.carrierRemarks?"":"unactive"}><textarea rows="4" name={"carrierRemarksValue"} value={this.state.carrierRemarksValue} onChange={this.handleChange}/></td>
                        </tr>
                        }
                 {this.props.visibleComponents.editAllVehConsignee  &&  this.props.consignees.length > 0 &&
                        <tr>
                            <td><input type="checkbox" name="consignee" checked={this.state.consignee} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.consignee?"":"unactive"}>Consignee:</td>
                            <td className={this.state.consignee?"":"unactive"}>
                            <select className="input-select" onChange={this.handleChange} name="consigneeValue">
                                    <option value=""></option>
                                    {this.props.consignees.map((item, i)=>{
                                return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                )
                            })}
                                </select>
                            </td>
                        </tr>
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="table-button">
                                <input type="submit" value="Save" className="submit-button save-button"/>
                            </td>
                        </tr>
                    </tbody>
            </table>
                </form>
                </div>
            </div>
        </div>

         );
    }
}

function mapStateToProps(state){
    return {
        vehiclesReturn: state.vehiclesReturn,
        carriers: state.dropdowns.carCarriers,
        freightForwarders: state.dropdowns.freightForwarders,
        visibleComponents: state.visibleComponents,
        consignees: state.dropdowns.consignees,
        userInfo: state.userInfo
    }
  }  
 
export default connect(mapStateToProps)(EditAllVehicles);