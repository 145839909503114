import { formatEpochDate, formatEpochDateTime } from "./index"


export default function convertTableDataForCSV(data, visibleCols) {

    //Get list of visible columns
const visibleKeyNames = visibleCols.map((item)=>{
    return item.id
  })
  
  //console.log(visibleKeyNames)
  
  //reduce the data to visible columns info only
  const reducedData = data.map((item, key)=>{
    return Object.assign(...visibleKeyNames.map(name => ({ [name]: data[key][name] })))
  })
  
  //console.log(reducedData)

  
  function changeVals(obj, index){
      Object.keys(obj).forEach(key => {
          let value = obj[key];
          switch(key) {
            case "movementDate":
              obj[key] = formatEpochDate(value)
              break;
            case "arriveHarbourDate":
              obj[key] = formatEpochDate(value)
              break;
            case "departBerthDate":
              obj[key] = formatEpochDate(value)
              break;
            case "receivedDateTime":
                obj[key] = formatEpochDateTime(value)
                break;
            case "lastDayOfFreeStorage":
              obj[key] = formatEpochDate(value)
              break;
            case "releasedDateTime":
              obj[key] = formatEpochDateTime(value)
              break;
            case "connectedDateTime":
              obj[key] = formatEpochDateTime(value)
              break;
            case "availableForDeliveryDateTime":
              obj[key] = formatEpochDateTime(value)
              break;
            default:
                }
                
            });
            
        }
        
    //convert some of the values eg dates, objectSrings...
        
  for(var i=0; i< reducedData.length;i++){
    changeVals(reducedData[i],i)
  }
  
    //create header rows
  var headerObject = visibleCols.reduce(
    (obj, item) => Object.assign(obj, { [item.id]: item.Header.props.children }), {});
  
    //insert header rows in front
  reducedData.unshift(headerObject)
  //console.log(reducedData)
  
    //change array of objs to nested array
  var finalData = reducedData.map((item)=>{
    return Object.values(item)
  })
  //console.log(finalData)

  //return finalData
  return reducedData
} 