export default function formatEpochDateToDatePicker(dateString){

    var epochString = dateString.replace(/[^0-9]/g, "")
    var epochDate = parseInt(epochString)
    var d = new Date(epochDate);
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear()
     if (dateString.length > 10){
          return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2)
     } else {
         return dateString
     }
    }
