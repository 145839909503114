import React from 'react';
import { connect } from "react-redux";

class CollapsedSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
     
         }
    }

   


    render() { 
        return (  
            <React.Fragment>
               <img id="collapsed-arrow" src="./images/chevron-circle-left.svg" onClick={()=>this.props.dispatch({type:"COLLAPSE_EXPAND_SEARCH_TAB", value:false})}/>
                <br/>
            </React.Fragment>
        );
    }
}
 
function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
       

    }
  }  

export default connect(mapStateToProps)(CollapsedSearch)