import React from "react"
import { connect } from "react-redux"
import { resetPassword } from "../../actions"

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            email: "",
            errorText: ""
         }
    }

    componentDidMount(){
        this.nameInput.focus();
    }

    handleSubmit = event =>{
        event.preventDefault()
        this.setState({errorText: ""})

        var postBody = {
            "emailAddress" : this.state.email
        }
        return this.props.dispatch(resetPassword(postBody))
        .then(res =>{
            if(res.status === 200 && !res.body.errorText &&  res.body.informationText){
                alert(res.body.informationText)
                this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "loginBox"})

            } else if(res.status === 200 && res.body.errorText){
                this.setState({errorText: res.body.errorText})
            } else {
                alert("An error has occured.")
            }
        })
        .catch(err =>{
            console.log(err)
        })
        
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            this.props.dispatch({type: "CLEAR_USER_INFO"})
        }
    }

    handleOnChange = event => {
        this.setState({[event.target.name]: event.target.value})
    }

    render() { 
        return ( 
            <div className="modal-background">
                <div className="password-reset-container">
                    <div className="close" onClick={this.handleCancel}>&times;</div>
                    <div className="modal-header">
                        <span>Password Reset</span>
                    </div>
                    <div className="modal-content-container">
                    <form onSubmit={this.handleSubmit}>
                        <p className="password-reset-message">Please enter in your email address to reset your password.</p>
                        <div className="input-div">
                            <input 
                            className="input-text-extra-long"
                            type="text" 
                            name = "email" 
                            value = {this.state.email} 
                            placeholder="Email address" 
                            onChange={this.handleOnChange} 
                            ref={(inputEl) => this.nameInput = inputEl}
                            />
                        </div>
                         {this.state.errorText && <div className="password-reset-error-text">{this.state.errorText}</div>}
                        <br/>
                        <div id="login-buttons-container">
                        <input type="submit" className={this.state.loading?"button-loading submit-button":"submit-button"} id="login-button" value="Reset"/>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
    }
  }  

export default connect(mapStateToProps)(ForgotPassword);