import React from "react"
import ReactTable from "react-table-6"
import { connect } from "react-redux"
import withDraggableColumns from 'react-table-hoc-draggable-columns'
import { ExportToCsv } from 'export-to-csv'
import { formatEpochDate, convertColor, convertTableDataForCSV } from "../../utils"
import { updateDetails } from "../../actions"
import { debounce } from "lodash"
import ShippingTableHeader from "../header/tableHeaderComponents/ShippingTableHeader"

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);


class ShippingTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          colOrder: {}
         }
         this.reactTableShipping = React.createRef();

    }

    componentDidMount= ()=>{
      //Update column order when rendered. Column order not directly linked to store as causes issues with draggable component.
    this.setState({colOrder: this.props.colOrder})
    console.log("CDM shipping table")
    }

    handleWidthChange = debounce((newSizes) =>{
      newSizes.map((item)=>{
        this.props.dispatch({type:"CHANGE_ORG_SHIP_COL_WIDTH", payload:{key:item.id+"Width", value:item.value}})
      })
    },500)

    // handleColOrderChange = (cols) =>{
    //   console.log(cols)

    // }

    handleExport = ()=>{
      if(this.props.shippingReturn.length < 1){
        alert("There is no data to export")
      } else{

        
        const tableData = this.reactTableShipping.wrappedInstance.getResolvedState().sortedData;
        const visibleCols = this.reactTableShipping.wrappedInstance.getResolvedState().allVisibleColumns;
        var csvData = convertTableDataForCSV(tableData, visibleCols)
        
        
        const options = { 
          filename: "centric_shipping",
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: false,
          title: "",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(csvData);
        }
      }



    render() { 

        
const columns = [{
   Header: 'Date',
    accessor: 'movementDate',
    show: this.props.show.movementDate,
    width: this.props.show.movementDateWidth,
    position: this.state.colOrder.movementDatePosition,
    Cell: cellInfo => formatEpochDate(cellInfo.value)
  }, {
    Header: 'Pilot Time',
    accessor: 'movementTime',
    width: this.props.show.movementTimeWidth,
    position: this.state.colOrder.movementTimePosition,
    show: this.props.show.movementTime,
  },{
    Header: 'Berth Time',
    accessor: 'berthTime',
    width: this.props.show.berthTimeWidth,
    position: this.state.colOrder.berthTimePosition,
    show: this.props.show.berthTime,
  },{
    Header: 'Vessel Name',
    accessor: 'vesselName',
    width: this.props.show.vesselNameWidth,
    position: this.state.colOrder.vesselNamePosition,
    show: this.props.show.vesselName,
  }, {
    Header: 'Voyage Code',
    accessor: 'voyageCode',
    width: this.props.show.voyageCodeWidth,
    position: this.state.colOrder.voyageCodePosition,
    show: this.props.show.voyageCode,
  }, {
    Header: 'Imports',
    accessor: 'importsLoaded',
    width: this.props.show.importsLoadedWidth,
    position: this.state.colOrder.importsLoadedPosition,
    show: this.props.show.importsLoaded,
    Cell: cellInfo => cellInfo.value? "Yes" : "No"
  }, {
    Header: 'Type',
    accessor: 'moveType',
    width: this.props.show.moveTypeWidth,
    position: this.state.colOrder.moveTypePosition,
    show: this.props.show.moveType,
  }, {
    Header: 'From/To Port',
    accessor: 'fromToPort',
    width: this.props.show.fromToPortWidth,
    position: this.state.colOrder.fromToPortPosition,
    show: this.props.show.fromToPort,
  }, {
    Header: 'Primary Cargo',
    accessor: 'primaryCargo',
    width: this.props.show.primaryCargoWidth,
    position: this.state.colOrder.primaryCargoPosition,
    show: this.props.show.primaryCargo,
    Cell: cellInfo=><div className="primary-cargo-container"><div className="primary-cargo-cell"style={{backgroundColor: convertColor(cellInfo.original.primaryCargoColour)}}></div><div>{cellInfo.value}</div></div>
  }, {
    Header: 'Agent',
    accessor: 'agent',
    width: this.props.show.agentWidth,
    position: this.state.colOrder.agentPosition,
    show: this.props.show.agent,
  },
  {
    Header: 'Berth',
    accessor: 'berth',
    width: this.props.show.berthWidth,
    position: this.state.colOrder.berthPosition,
    show: this.props.show.berth,
  },{
    Header: 'Arrive Harbour Date',
    accessor: 'arriveHarbourDate',
    width: this.props.show.arriveHarbourDateWidth,
    position: this.state.colOrder.arriveHarbourDatePosition,
    show: this.props.show.arriveHarbourDate,
    Cell: cellInfo => formatEpochDate(cellInfo.value)

  },{
    Header: 'Arrive Harbour Time',
    accessor: 'arriveHarbourTime',
    width: this.props.show.arriveHarbourTimeWidth,
    position: this.state.colOrder.arriveHarbourTimePosition,
    show: this.props.show.arriveHarbourTime,
  },{
    Header: 'Comments',
    accessor: 'comments',
    width: this.props.show.commentsWidth,
    position: this.state.colOrder.commentsPosition,
    show: this.props.show.comments,
  },{
    Header: 'Depart Berth Date',
    accessor: 'departBerthDate',
    width: this.props.show.departBerthDateWidth,
    position: this.state.colOrder.departBerthDatePosition,
    show: this.props.show.departBerthDate,
    Cell: cellInfo => formatEpochDate(cellInfo.value)

  },{
    Header: 'Depart Berth Time',
    accessor: 'departBerthTime',
    width: this.props.show.departBerthTimeWidth,
    position: this.state.colOrder.departBerthTimePosition,
    show: this.props.show.departBerthTime,
  },{
    Header: 'Has Pending Changes',
    accessor: 'hasPendingChanges',
    width: this.props.show.hasPendingChangesWidth,
    position: this.state.colOrder.hasPendingChangesPosition,
    show: this.props.show.hasPendingChanges,
    Cell: cellInfo => {if(cellInfo.value === true){
        return "Yes"
      }else if(cellInfo.value === false){
        return "No"
      } else {
        return ""
      }
        }
  },{
    Header: 'Is Complete',
    accessor: 'isComplete',
    width: this.props.show.isCompleteWidth,
    position: this.state.colOrder.isCompletePosition,
    show: this.props.show.isComplete,
    Cell: cellInfo => {if(cellInfo.value === true){
      return "Yes"
    }else if(cellInfo.value === false){
      return "No"
    } else {
      return ""
    }
      }
  },{
    Header: 'Launch Time On Board',
    accessor: 'launchTimeOnBoard',
    width: this.props.show.launchTimeOnBoardWidth,
    position: this.state.colOrder.launchTimeOnBoardPosition,
    show: this.props.show.launchTimeOnBoard,
  },{
    Header: 'LLoyds Number',
    accessor: 'lloydsNumber',
    width: this.props.show.lloydsNumberWidth,
    position: this.state.colOrder.lloydsNumberPosition,
    show: this.props.show.lloydsNumber,
    Cell: cellInfo => cellInfo.value === "0"? "":cellInfo.value

  },{
    Header: 'Side To',
    accessor: 'sideTo',
    width: this.props.show.sideToWidth,
    position: this.state.colOrder.sideToPosition,
    show: this.props.show.sideTo,
  },{
    Header: 'Trade',
    accessor: 'trade',
    width: this.props.show.tradeWidth,
    position: this.state.colOrder.tradePosition,
    show: this.props.show.trade,
  },{
    Header: 'Tugs',
    accessor: 'tugs',
    width: this.props.show.tugsWidth,
    position: this.state.colOrder.tugsPosition,
    show: this.props.show.tugs,
    Cell: cellInfo => cellInfo.value && cellInfo.value.toString()
  },{
    Header: 'Tugs Time On Board',
    accessor: 'tugsTimeOnBoard',
    width: this.props.show.tugsTimeOnBoardWidth,
    position: this.state.colOrder.tugsTimeOnBoardPosition,
    show: this.props.show.tugsTimeOnBoard,
  },
]

const orderedCols = [...columns].sort((function (a, b) {
  return a.position - b.position;
}))

const draggableList = columns.map((item)=>{
  return item.accessor
})
        return ( 
          <React.Fragment>
          <div className="table-header-container">
           <ShippingTableHeader handleExport={this.handleExport}/>
         </div>
            <div id="react-table-container">
              <ReactTableDraggableColumns
                onChange={()=>{console.log("change")}}
                draggableColumns= {{
                  mode: 'reorder',
                  draggable: 
                  draggableList,
                  // ['movementDate', 'movementTime', "berthTime", "vesselName", "voyageCode", "importsLoaded", "moveType","fromToPort","primaryCargo","agent","berth","arriveHarbourDate","arriveHarbourTime","comments","departBerthDate","departBerthTime","hasPendingChanges","isComplete","launchTimeOnBoard","sideTo","trade","tugs","tugsTimeOnBoard"],
                  onDraggedColumnChange: function(columns){
                    console.log(columns)
                  }
                }}
                data={this.props.shippingReturn}
                columns={orderedCols}
                defaultSorted={this.props.defaultSorted}
                defaultPageSize={20}
                ref={(r)=>this.reactTableShipping=r}
                style={{
                  height: this.props.collapsedDetails?"calc(100vh - 175px)":"450px",
                  fontSize: "0.8em",
                  
                }}
                noDataText=""
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (e, t) => {
                        this.props.dispatch(updateDetails(this.props.shippingReturn[rowInfo.index]))
                        this.props.dispatch({type:"UPDATE_INDEX", tableIndex:rowInfo.index })
                        this.props.dispatch({type:"SET_SELECTED_ROW", payload:{key:"shippingTableSelectedRow", value:rowInfo.index}})
                      } ,
                      style: {
                        background: rowInfo.index === this.props.shippingTableSelectedRow? "rgb(50, 135, 214, 0.25)": null
                      }
                          }
                  }else{
                      return {}
                    }
                  }}
                  getTheadTrProps = {(info)=>{
                    return {
                      onMouseLeave: (e,t)=>{
                        var colOrder = info.columns.reduce(
                          (obj, item, index) => Object.assign(obj, { [item.accessor +"Position"]: index }), {});
                          // var colName = info.columns.reduce(
                          //   (obj, item, index) => Object.assign(obj, { [item.Header.props.children]: index }), {});
                    
                            //console.log(colOrder)
                            this.props.dispatch({type:"UPDATE_SHIP_COL_ORDER", shippingColOrder:colOrder})
                            if(
                              colOrder !== this.props.colOrder
                              ){
                              // console.log(colOrder)
                              // console.log(this.props.colOrder)
                            }
                            
                      }
                    }
                  }}
                className="-striped -highlight"
                onResizedChange={(newResized, event) => {
                  this.handleWidthChange(newResized)
                }}
                onSortedChange={(newSorted, column, shiftKey) => {
                  this.props.dispatch({type:"CHANGE_ORG_SHIP_COL_ITEM", payload:{key:"defaultSorted", value:newSorted}})
                  console.log("sorted")
                }}
        />
            </div>
           </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
    shippingReturn: state.shippingReturn,
    show: state.organiseTable.shipping,
    colOrder: state.organiseTable.shippingColOrder,
    defaultSorted: state.organiseTable.shipping.defaultSorted,
    shippingTableSelectedRow: state.visibleComponents.shippingTableSelectedRow,
    collapsedDetails: state.visibleComponents.collapsedDetails,
    //need key to trigger refresh of table-draggable component. key is changed on loading of settings/login
    key: state.visibleComponents.shippingTableKey

    }
  }  

export default connect(mapStateToProps)(ShippingTable);