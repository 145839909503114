import {
            getPorts,
            searchShippingMovements,
            searchCargoItems,
            postUserInfo,
            postFilterLookupCars,
            getVoyages,
            postChangeUserPassword,
            getCargoTransactions,
            postShippingMovementUpdate,
            postUpdateCargoItem,
            getSimpleUserBasedGets,
            postDeleteSavedSetting,
            getBookingReference,
            getContainerNumber,
            sendPreNoteContainer,
            postSaveSetting,
            postEmail,
            postResetPassword
        } from "../api/index"

import { 
            loadShippingSettings, 
            loadContainersSettings, 
            loadVehiclesSettings
        } from "./settings"
import { searchShipping, searchContainers, searchVehicles } from "./search"

import { canUserUpdate } from "../utils"

export function shippingPost(postBody){
    return dispatch => {
        dispatch({type:"START_LOADING_STATUS", key:"shippingSearchLoading"})
        return searchShippingMovements(postBody).then(results =>{
            results.errorText && alert(results.errorText)
            results.errorText === "Session expired - you will need to log in again." && dispatch({type: "LOGOUT"})
            !results.errorText && results.shippingMovementArray.length === 0 && alert("No Items matched your search")
            if(results.shippingMovementArray.length>0){
            dispatch(updateShippingList(results.shippingMovementArray))
            dispatch(updateDetails(results.shippingMovementArray[0]))
            dispatch({type:"UPDATE_INDEX", tableIndex: 0 })
            dispatch({type:"SET_SELECTED_ROW", payload:{key:"shippingTableSelectedRow", value:0}})
            }else{
                console.log("returned 0 array")
                dispatch({type: "RESET_SHIPPING_RETURN"})
                dispatch({type: "RESET_SHIPPING_DETAILS"})
            }
        dispatch({type:"STOP_LOADING_STATUS", key:"shippingSearchLoading"})

        })
        .catch(err =>{
            console.log("Error" + err)
        dispatch({type:"STOP_LOADING_STATUS", key:"shippingSearchLoading"})

        })
    }
}

export function containersPost(postBody){
    return dispatch => {
        dispatch({type:"START_LOADING_STATUS", key:"containersSearchLoading"})
        return searchCargoItems(postBody).then(results =>{
            results.errorText && alert(results.errorText)
            results.errorText === "Session expired - you will need to log in again." && dispatch({type: "LOGOUT"})
            !results.errorText && results.cargoItems.length === 0 && alert("No Items matched your search")

            if(results.cargoItems.length>0){
            dispatch({type:"UPDATE_CONTAINERS_RETURN", containersReturn: results.cargoItems})
            dispatch({type: "UPDATE_CONTAINERS_DETAILS", containersDetails: results.cargoItems[0]})
            dispatch({type:"SET_SELECTED_ROW", payload:{key:"containersTableSelectedRow", value:0}})
            dispatch(setCargoTransactions(results.cargoItems[0].cargoItemObjectString, "UPDATE_CONTAINERS_TRANSACTIONS"))

            //set which fields can be updated in editAll
            canUserUpdate(results.cargoItems, "canUpdateCarrier") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllConCarrier", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateCarrierRemarks") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllConCarrierRemarks", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateFreightForwarder") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllConFF", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateFreightForwarderStop") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllConFFStop", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateOperatorStop") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllConOperatorStop", value: true}})

        }else{
            console.log("returned 0 array")
            dispatch({type: "RESET_CONTAINERS_RETURN"})
            dispatch({type: "RESET_CONTAINERS_DETAILS"})

        }
            dispatch({type:"STOP_LOADING_STATUS", key:"containersSearchLoading"})
        })
        .catch(err =>{
            console.log("Error" + err)
            dispatch({type:"STOP_LOADING_STATUS", key:"containersSearchLoading"})

        })
    }
}

export function vehiclesPost(postBody){
    return dispatch => {
        dispatch({type:"START_LOADING_STATUS", key:"vehiclesSearchLoading"})

        return searchCargoItems(postBody).then(results =>{
            results.errorText && alert(results.errorText)
            results.errorText === "Session expired - you will need to log in again." && dispatch({type: "LOGOUT"})
            !results.errorText && results.cargoItems.length === 0 && alert("No Items matched your search")
        if(results.cargoItems.length>0){
           dispatch({type:"UPDATE_VEHICLES_RETURN", vehiclesReturn: results.cargoItems})
           dispatch({type: "UPDATE_DETAILS_COMPONENT", payload:{key:"vehiclesDetails", value: results.cargoItems[0]}})
           dispatch({type:"SET_SELECTED_ROW", payload:{key:"vehiclesTableSelectedRow", value:0}})
           dispatch(setCargoTransactions(results.cargoItems[0].cargoItemObjectString, "UPDATE_VEHICLES_TRANSACTIONS"))

            //set which fields can be updated in editAll
            canUserUpdate(results.cargoItems, "canUpdateCarrier") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehCarrier", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateCarrierRemarks") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehCarrierRemarks", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateFreightForwarder") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehFF", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateFreightForwarderStop") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehFFStop", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateConsignee") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehConsignee", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateCustomsAgentStop") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehCustomsAgentStop", value: true}})
            canUserUpdate(results.cargoItems, "canUpdateOperatorStop") && dispatch({type: "SET_EDIT_ALL_VALUE", payload:{key:"editAllVehOperatorStop", value: true}})

        }else{
            console.log("returned 0 array")
            dispatch({type: "RESET_VEHICLES_RETURN"})
            dispatch({type: "RESET_VEHICLES_DETAILS"})
        }
        dispatch({type:"STOP_LOADING_STATUS", key:"vehiclesSearchLoading"})

        })
        .catch(err =>{
            console.log("Error" + err)
            dispatch({type:"STOP_LOADING_STATUS", key:"vehiclesSearchLoading"})

        })
    }
}

export function sendShippingUpdate(postBody, tableIndex){
    return dispatch => {
        return postShippingMovementUpdate(postBody).then(results =>{
            results.errorText && alert(results.errorText)
            results.errorText === "Session expired - you will need to log in again." && dispatch({type: "LOGOUT"})
            if(results.shippingMovementArray){
                dispatch({type:"UPDATE_SHIPPING_MOVEMENT_AT_INDEX", payload:{ value:results.shippingMovementArray ,index:tableIndex}})
                dispatch({type: "UPDATE_DETAILS", details:results.shippingMovementArray[0]})
            }
            console.log(results)
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function sendContainerUpdate(postBody, tableIndex){
    return dispatch => {
        return postUpdateCargoItem(postBody).then(results =>{
            results.errorText && alert(results.errorText)
            results.errorText === "Session expired - you will need to log in again." && dispatch({type: "LOGOUT"})           
           results.cargoItems && dispatch({type:"UPDATE_CONTAINERS_RETURN_AT_INDEX", payload:{ value:results.cargoItems[0] ,index:tableIndex}})
            dispatch({type: "CHANGE_CONTAINERS_SAVE_BUTTON", containersSaveButton: false})
            dispatch({type: "RESET_CONTAINERS_SAVE_ARRAY"})
            console.log("Table index" + tableIndex)
            console.log(results)
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function sendVehicleUpdate(postBody, tableIndex){
    return dispatch => {
        return postUpdateCargoItem(postBody).then(results =>{
            results.errorText && alert(results.errorText)
            results.errorText === "Session expired - you will need to log in again." && dispatch({type: "LOGOUT"})           
           results.cargoItems && dispatch({type:"UPDATE_VEHICLES_RETURN_AT_INDEX", payload:{ value:results.cargoItems[0] ,index:tableIndex}})
            dispatch({type: "CHANGE_VEHICLES_SAVE_BUTTON", vehiclesSaveButton: false})
            dispatch({type: "RESET_VEHICLES_SAVE_ARRAY"})
            console.log("Table index" + tableIndex)
            console.log(results)
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function setCargoTransactions(cargoItemObjectString, actionType){
    return dispatch => {
        return getCargoTransactions(cargoItemObjectString).then(results =>{
            results.status === 200 && dispatch({type: actionType, transactions: results.body})
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}


export function loginUser(postBody){
    return (dispatch, getState) =>{
        dispatch({type:"LOADING_CURSOR"})
        const state = getState()
        return postUserInfo(postBody)
        .then(results =>{
            if((!results.body.errorText || results.body.errorText === "Your password has expired.") && results.status === 200){
                var userObjStr = results.body.user.userObjectString
                            dispatch(updateUserInfo(results.body))
                            dispatch(loginStatus(true))
                            dispatch(vehiclesFilterLookup({
                                "user" :{
                                    "userObjectString": userObjStr
                                },
                                "sessionId" : results.body.sessionId
                            }))
                            //Get dropdown lists and settings
                            dispatch(voyagesContainersLookup())
                            dispatch(voyagesVehiclesLookup())
                            dispatch(updateSimpleUserBasedGet("car_carriers", userObjStr, "UPDATE_CAR_CARRIERS_LIST"))
                            dispatch(updateSimpleUserBasedGet("consignees", userObjStr, "UPDATE_CONSIGNEES_LIST"))
                            dispatch(updateSimpleUserBasedGet("consignors", userObjStr, "UPDATE_CONSIGNORS_LIST"))
                            dispatch(updateSimpleUserBasedGet("container_carriers", userObjStr, "UPDATE_CONTAINER_CARRIERS_LIST"))
                            dispatch(updateSimpleUserBasedGet("freight_forwarders", userObjStr, "UPDATE_FF_LIST"))
                            dispatch(updateSimpleUserBasedGet("operators", userObjStr, "UPDATE_OPERATORS_LIST"))
                            dispatch(updateSavedSettings("saved_settings", userObjStr))
                            
                            //Find correct index of last used settings an load settings
                            const settings = results.body.lastUsedSettings
                            const shippingValue = (element) => element.type === 0
                            const containersValue = (element) => element.type === 1
                            const vehiclesValue = (element) => element.type === 7
                            const shippingIndex = settings.findIndex(shippingValue)
                            const containersIndex = settings.findIndex(containersValue)
                            const vehiclesIndex = settings.findIndex(vehiclesValue)
                            var shippingSettings = settings[shippingIndex] || null
                            var containersSettings = settings[containersIndex] || null
                            var vehiclesSettings = settings[vehiclesIndex] || null

                            shippingSettings && dispatch(loadShippingSettings(shippingSettings))
                            containersSettings && dispatch(loadContainersSettings(containersSettings))
                            vehiclesSettings && dispatch(loadVehiclesSettings(vehiclesSettings))

                            //Trigger search based on last search made when logged in /or visible tab
                            var cookieValue= state.visibleComponents.visibleTab
                            if (document.cookie.indexOf('lastSearchTab') > -1 ) {
                                cookieValue = document.cookie
                                    .split('; ')
                                    .find(row => row.startsWith('lastSearchTab'))
                                    .split('=')[1];
                             }
                            
                            if (cookieValue==="Containers"){
                                dispatch(changeSearchTab("Containers"))
                                dispatch(searchContainers())
                                //track which search has been triggered on login
                                dispatch({type:"SET_LOGGED_IN_SEARCHED", key:"containersLoggedInSearched"})
                            } else if (cookieValue==="Vehicles"){
                                dispatch(changeSearchTab("Vehicles"))
                                dispatch(searchVehicles())
                                dispatch({type:"SET_LOGGED_IN_SEARCHED", key:"vehiclesLoggedInSearched"})
                            }else {
                                dispatch(changeSearchTab("Shipping"))
                                dispatch(searchShipping())
                                dispatch({type:"SET_LOGGED_IN_SEARCHED", key:"shippingLoggedInSearched"})
                            }
                            
                           if(results.body.errorText === "Your password has expired."){
                               dispatch({type: "CHANGE_PASSWORD_ERROR_TEXT", changePasswordErrorText: "Your password has expired. Please create a new password."})
                               return "expired"
                                } else{
                                    return "loggedIn"
                                }
            }   else if (results.status === 200 && results.body.errorText){
                dispatch({type:"DEFAULT_CURSOR"})
                return results.body.errorText
            } else {        
                dispatch({type:"DEFAULT_CURSOR"})
                return "error"
            }
        })
        .catch(err =>{
            console.log("Error" + err)
            dispatch({type:"DEFAULT_CURSOR"})
        })
    }
}

export function changeUserPassword(postBody){
    return dispatch => {
        return postChangeUserPassword(postBody)
        .then(res =>{
            return res
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function changeUserPasswordSuccess(){
    return dispatch =>{
        console.log("action")
       return dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        .then(() =>{
            alert("Your password was changed successfully")
        
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}


export function vehiclesFilterLookup(postBody){
    return dispatch => {
        return postFilterLookupCars(postBody).then(results =>{
            dispatch({type:"UPDATE_VEHICLES_FILTER", vehicleFilter: results})
            // console.log(results)
    })
    }
}

export function voyagesContainersLookup(){
    return dispatch => {
        return getVoyages(1).then(results =>{
            results.objectFlyweightArray && dispatch({type:"UPDATE_CONTAINERS_VOYAGES", containersVoyages: results.objectFlyweightArray})
    })
    }
}

export function voyagesVehiclesLookup(){
    return dispatch => {
        return getVoyages(7).then(results =>{
            results.objectFlyweightArray && dispatch({type:"UPDATE_VEHICLES_VOYAGES", vehiclesVoyages: results.objectFlyweightArray})
    })
    }
}


export function portsLookup(){
    return dispatch => {
        return getPorts().then(results =>{
            results.objectFlyweightArray.sort((a,b) => (a.displayAttribute > b.displayAttribute) ? 1 : ((b.displayAttribute > a.displayAttribute) ? -1 : 0)); 
            dispatch({type:"UPDATE_PORTS", ports: results.objectFlyweightArray})
    })
    }
}

export function updateUserInfo(userInfo){
    return{
        type: "UPDATE_USER_INFO",
        userInfo

    }
}

export function updateShippingList(shippingMovements){
    return{
        type: "UPDATE_SHIPPING_MOVEMENTS",
        shippingMovements

    }
}


export function changePrimaryCargoType(primaryCargoType){
return{
    type: 'CHANGE_POST_PRIMARY_CARGO_TYPE',
    primaryCargoType
}
}

export function changeVesselTypes(vesselTypes){
    return{
        type: "CHANGE_POST_VESSEL_TYPES",
        vesselTypes
    }
}


export function changeMovementDateDynamic(movementDateDynamic){
    return{
        type: "CHANGE_POST_MOVEMENT_DATE_DYNAMIC",
        movementDateDynamic
    }
}


export function changeInPortDate(inPortDate){
    return{
        type: "CHANGE_IN_PORT_DATE",
        inPortDate
    }

}


export function updateDetails(details){
    return{
        type: "UPDATE_DETAILS",
        details
    }
}

export function toggleLoginBox(loginBoxVisible){
    return{
        type: "IS_VISIBLE_LOGIN_BOX",
        loginBoxVisible
    }
}

export function loginStatus(isLoggedIn){
return{
    type: "CHANGE_LOGIN_STATUS",
    isLoggedIn
}
}

export function changeSearchTab(visibleTab){
    return{
        type: "CHANGE_VISIBLE_TAB",
        visibleTab
    }
}

export function updateSimpleUserBasedGet(getType, userObjStr, actionType,){
    return dispatch => {
        return getSimpleUserBasedGets(getType, userObjStr)
        .then(res =>{
            res.objectFlyweightArray && dispatch({type: actionType, payload: res.objectFlyweightArray })
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function updateSavedSettings(getType, userObjStr){
    return dispatch => {
        return getSimpleUserBasedGets(getType, userObjStr)
        .then(res =>{
            res && dispatch({type: "SET_ALL_SETTINGS", allSettings: res})
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function deleteSavedSetting(postBody, userObjStr){
    return dispatch => {
        return postDeleteSavedSetting(postBody)
        .then(res =>{
            res.status === 200 && !res.body.errorText &&  dispatch(updateSavedSettings("saved_settings", userObjStr))
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function saveSetting(postBody, userObjStr){
    return dispatch => {
        return postSaveSetting(postBody)
        .then(res =>{
            res.status === 200 && !res.body.errorText &&  dispatch(updateSavedSettings("saved_settings", userObjStr))
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}
// for first step of Export Pre-notes
export function sendBookingReference(bookingRef){
    return dispatch => {
        return getBookingReference(bookingRef)
        .then(res =>{
            if (Array.isArray(res.body)) {
				if(res.status === 200 && res.body.length === 1){
				dispatch({type:"UPDATE_PRE_NOTES", preNotes:res.body})
				dispatch({type: "CHANGE_PRE_NOTES_INDEX", preNotesIndex: 0})
				dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "exportPreNotes2"})
				} else if(res.status === 200 && res.body.length > 1){
					dispatch({type:"UPDATE_PRE_NOTES", preNotes:res.body})
					dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "exportPreNotesVoyageSelect"})
				} else if(res.body && res.body.length === 0){
					alert("Booking Reference not found. Please confirm with the shipping line that the booking is valid. For further enquiries please contact the Cargo Receipt and Delivery team at CentrePort - use the Contact Us link at the top right to make contact.")
				} else {
					alert("An error has occured")
				}
			} else {
				if(res.status === 200 && res.body.errorText){
					alert(res.body.errorText)
				} else if(res.status === 200 && res.body.objects.length === 1){
				dispatch({type:"UPDATE_PRE_NOTES", preNotes:res.body.objects})
				dispatch({type: "CHANGE_PRE_NOTES_INDEX", preNotesIndex: 0})
				dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "exportPreNotes2"})
				} else if(res.status === 200 && res.body.objects.length > 1){
					dispatch({type:"UPDATE_PRE_NOTES", preNotes:res.body.objects})
					dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "exportPreNotesVoyageSelect"})
				} else if(res.body && (res.body.objects === null || res.body.objects.length === 0)){
					alert("Booking Reference not found. Please confirm with the shipping line that the booking is valid. For further enquiries please contact the Cargo Receipt and Delivery team at CentrePort - use the Contact Us link at the top right to make contact.")
				} else {
					alert("An error has occured. Please refresh your browser toString try again")
				}
			}
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

// for second step of Export Pre-notes
export function sendContainerNumber(containerNumber){
    return dispatch => {
        return getContainerNumber(containerNumber)
        .then(res =>{
            console.log(res)
            res.text !=="null" && alert(res.body.informationText)
            return res
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

// for third step of Export Pre-notes (SAVE)
export function postPreNoteContainer(postBody){
    return dispatch => {
        dispatch({type:"LOADING_CURSOR"})
        return sendPreNoteContainer(postBody)
        .then(res =>{
            console.log(res)
            res.text !=="null" && alert(res.body.informationText)
            dispatch({type:"DEFAULT_CURSOR"})

            return res
        })
        .catch(err =>{
            console.log("Error" + err)
            alert("An error has occured")
            dispatch({type:"DEFAULT_CURSOR"})

        })
    }
}

// for third step of Export Pre-notes (SAVE/COPY)
export function copyPostPreNoteContainer(postBody){
    return dispatch => {
        dispatch({type:"LOADING_CURSOR"})
        return sendPreNoteContainer(postBody)
        .then(res =>{
            console.log(res)
            res.text !=="null" && alert(res.body.informationText)
            dispatch({type:"DEFAULT_CURSOR"})

            return res
        })
        .catch(err =>{
            console.log("Error" + err)
            dispatch({type:"DEFAULT_CURSOR"})

        })
    }
}

export function sendEmail(postBody){
    return dispatch => {
        return postEmail(postBody)
        .then(res =>{
            console.log(res)
            res.body.errorText && alert(res.body.errorText)
            res.status === 200 && !res.body.errorText && dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})

        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

export function resetPassword(postBody){
    return dispatch => {
        return postResetPassword(postBody)
        .then(res =>{
            console.log(res)
            return res
        })
        .catch(err =>{
            console.log("Error" + err)
        })
    }
}

