const initialState = {
    movementDate: "",
    tableIndex: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_DETAILS":
                return action.details;
                break;
        case "UPDATE_DETAILS_FROM_TO_PORT":
                return Object.assign({}, state, {fromToPortObjectString: action.fromToPortObjectString})
                break;
        case "UPDATE_INDEX":
                return Object.assign({}, state, {tableIndex: action.tableIndex})
                break;
        case "RESET_SHIPPING_DETAILS":
                return initialState
                break;
        case "UPDATE_SHIP_DETAILS_MOVEMENT_DATE":
                return Object.assign({}, state, {movementDate: action.movementDate})
                break;
        case "UPDATE_SHIP_DETAILS_MOVEMENT_TIME":
                return Object.assign({}, state, {movementTime: action.movementTime})
                break;
        case "UPDATE_SHIP_DETAILS_COMMENTS":
                return Object.assign({}, state, {comments: action.comments})
                break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer