import React from "react"
import { connect } from 'react-redux'
import ShippingDetails from './ShippingDetails'
class CollapsedDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>

            <div className="collapsed-details-div">
                <img className="collapsed-details-arrow" src="./images/chevron-circle-left.svg" onClick={()=>this.props.dispatch({type:"COLLAPSE_EXPAND_DETAILS", value:false})}/>

            </div>
       
            </React.Fragment>
         );
    }
}
function mapStateToProps(state){
    return {
        shippingSubTab: state.visibleComponents.shippingSubTab
    }
  } 
export default connect(mapStateToProps)(CollapsedDetails);