import React from "react"
import { connect } from 'react-redux'
import VehiclesDetails from "./VehiclesDetails"
import VehiclesTransactions from "./VehiclesTransactions"
class VehiclesSubTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    renderSubComponent = ()=>{
        if(this.props.vehiclesSubTab==="Details"){
            return <VehiclesDetails/>
        } else if(this.props.vehiclesSubTab==="Transactions"){
            return <VehiclesTransactions/>
        }else{
            return ""
        }
    }

    render() { 
        return ( 
            <React.Fragment>
                <div className="flex-row">
                    <div id="container-tab">
                        <div id={this.props.vehiclesSubTab ==="Details"?"tabactivated":""} className="tabunactive tabactive" onClick={() => this.props.dispatch({type: "CHANGE_VISIBLE_VEHICLES_SUBTAB", vehiclesSubTab: "Details"})}>Details</div>
                        {this.props.isLoggedIn && <div id={this.props.vehiclesSubTab ==="Transactions"?"tabactivated":""} className="tabunactive" onClick={() => this.props.dispatch({type: "CHANGE_VISIBLE_VEHICLES_SUBTAB", vehiclesSubTab: "Transactions"})}>Transactions</div>}
                    </div>
                    <div>
                        <img id="expanded-details-arrow" src="./images/chevron-circle-left.svg" onClick={()=>this.props.dispatch({type:"COLLAPSE_EXPAND_DETAILS", value:true})}/>
                    </div>
                </div>
                {this.renderSubComponent()}
            </React.Fragment>
         );
    }
}
function mapStateToProps(state){
    return {
        vehiclesSubTab: state.visibleComponents.vehiclesSubTab,
        isLoggedIn: state.visibleComponents.isLoggedIn
    }
  } 
export default connect(mapStateToProps)(VehiclesSubTabs);