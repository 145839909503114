import React from "react"
import { connect } from "react-redux";
import { formatEpochDateToDatePicker, formatEpochDate, formatEpochDateTime } from "../../utils"
import { sendShippingUpdate, updateDetails } from "../../actions"

class ShippingDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            fromToPortObjectString: "",
            movementDate: "",
         }
    }

 
    handleEdit = ()=>{

        var editPost = {	
        "shippingMovementUpdate":{
            "comments": this.props.details.comments,
            "fromToPortObjectString": this.state.fromToPortObjectString? this.state.fromToPortObjectString:this.props.details.fromToPortObjectString,
            "movementDate": formatEpochDateToDatePicker(this.props.details.movementDate),
            "movementTime": this.props.details.movementTime,
            "objectEdition": this.props.details.objectEdition.toString(),
            "targetObjectString": this.props.details.movementObjectString,
        },
            "user": {
                "userObjectString": this.props.userObjectString
            },
            "sessionId" : this.props.sessionId
        }
            console.log(editPost)
            this.props.dispatch(sendShippingUpdate(editPost, this.props.details.tableIndex))
    }

    renderCanEdit = ()=>{
    }

    handleChange = event =>{}

    handleDateChange = (event) =>{
        this.props.dispatch({type:"UPDATE_SHIP_DETAILS_MOVEMENT_DATE", movementDate: event.target.value})
    }

    handleTimeChange = (event) =>{
        this.props.dispatch({type:"UPDATE_SHIP_DETAILS_MOVEMENT_TIME", movementTime: event.target.value})
    }

    handleCommentsChange = (event) =>{
        this.props.dispatch({type:"UPDATE_SHIP_DETAILS_COMMENTS", comments: event.target.value})
    }

    handleToPortChange = (event) =>{
        this.setState({fromToPortObjectString: event.target.value})
        this.props.dispatch({type:"UPDATE_DETAILS_FROM_TO_PORT", fromToPortObjectString: event.target.value})
    }
    handleCheckboxChange = ()=>{

    }

    renderMovementDate = ()=>{
        if(this.props.isLoggedIn === true && this.props.details.userCanUpdate ===true){
            return (
             <p className="details-row" ><span className="details-name">Movement Date:</span><span><input className="input-date" type="date" value={formatEpochDateToDatePicker(this.props.details.movementDate)} onChange={this.handleDateChange}/></span><span><input className="input-time" type="text" onChange={this.handleTimeChange} value={this.props.details.movementTime}/></span></p>

            )
        } else {
            return (
               <p className="details-row" ><span className="details-name">Movement Date:</span><span>{this.props.details.movementDate? formatEpochDate(this.props.details.movementDate):""}</span><span className="span3">{this.props.details.movementTime}</span></p>

            )
        }
    }
    renderToPort = () => {
        if(this.props.isLoggedIn === true && this.props.details.userCanUpdate ===true){
            return (
                <p className="details-row" ><span className="details-name">{this.props.details.moveType === "Arrive"?"From Port:":"To Port:"}</span>
                                <select 
                                    onChange={this.handleToPortChange} 
                                    value={this.props.details.fromToPortObjectString}
                                >
                                    {/* <option value={this.props.details.fromToPortObjectString}>{this.props.details.fromToPort}</option> */}
                                    {this.props.ports.slice().sort().map((item,i)=>{
                                    return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                                    })}
                                </select>
                        </p>
            )
        } else {
            return (
                <p className="details-row" ><span className="details-name">{this.props.details.moveType === "Arrive"?"From Port:":"To Port:"}</span>{this.props.details.fromToPort}</p>

            )
        }

    }

    renderComments = () =>{
        if(this.props.isLoggedIn === true && this.props.details.userCanUpdate ===true){
            return (
                <p className="details-row" ><span className="details-name">Comments:</span><textarea rows="5" value={this.props.details.comments} onChange={this.handleCommentsChange}></textarea></p>

            )
        } else {
            return (
                <p className="details-row" ><span className="details-name">Comments:</span><textarea rows="5" value={this.props.details.comments} onChange={this.handleCommentsChange} readOnly={true}></textarea></p>

            )
        }
    }

    renderEtaEtd = () =>{
        var splitETA = ["",""]
        var splitETD = ["",""]
        var hasETA = false
        var hasETD = false
       
        if(this.props.details.estimatedArrival){
            hasETA = formatEpochDateTime(this.props.details.estimatedArrival)
            splitETA = formatEpochDateTime(this.props.details.estimatedArrival).split(" ")
        }
        if(this.props.details.estimatedDeparture){
            hasETD = formatEpochDateTime(this.props.details.estimatedDeparture)
            splitETD = formatEpochDateTime(this.props.details.estimatedDeparture).split(" ")
        }
        return(
            <React.Fragment>
                        {hasETA && <p className="details-row" ><span className="details-name">Estimated Arrival:</span><span>{splitETA[0]}</span><span className="span3">    {splitETA[1]}</span></p>}
                        {hasETD && <p className="details-row" ><span className="details-name">Estimated Departure:</span><span>{splitETD[0]}</span><span className="span3">    {splitETD[1]}</span></p>}
            </React.Fragment>
        )
    }

    renderEarliestLatest = () =>{
        //For displaying Earliest Export Receival Time & Earliest Export Cut Off Time
        var splitERD = ["",""]
        var splitLRT = ["",""]
        var splitEETA = ["",""]
        var hasERD = false
        var hasLRT = false
        var hasEETA = false

        if(this.props.details.earliestReceivalDate){
            hasERD = formatEpochDateTime(this.props.details.earliestReceivalDate)
            splitERD = formatEpochDateTime(this.props.details.earliestReceivalDate).split(" ")
        }
        if(this.props.details.latestReceivalTime){
            hasLRT = formatEpochDateTime(this.props.details.latestReceivalTime)
            splitLRT = formatEpochDateTime(this.props.details.latestReceivalTime).split(" ")
        }
        if(this.props.details.earliestEstimatedArrival){
            hasEETA = formatEpochDateTime(this.props.details.earliestEstimatedArrival)
            splitEETA = formatEpochDateTime(this.props.details.earliestEstimatedArrival).split(" ")
        }
        return(
            <React.Fragment>
                        {hasERD && <p className="details-row" ><span className="details-name">Earliest Export Receival Time:</span><span>{splitERD[0]}</span><span className="span3">    {splitERD[1]}</span></p>}
                        {hasLRT && <p className="details-row" ><span className="details-name">Latest Export Cut Off Time:</span><span>{splitLRT[0]}</span><span className="span3">    {splitLRT[1]}</span></p>}
                        {hasEETA && <p className="details-row" ><span className="details-name">Earliest Estimated Arrival:</span><span>{splitEETA[0]}</span><span className="span3">    {splitEETA[1]}</span></p>}
            </React.Fragment>
        )
    }
     

    render() { 
       
        return ( 
            <React.Fragment>
                <div className="details-container">
                    <div className="details-column">
                        <p className="details-row" ><span className="details-name">Vessel Name:</span>{this.props.details.vesselName}</p>
                        <p className="details-row" ><span className="details-name">IMO Number:</span>{this.props.details.lloydsNumber === "0"? "":this.props.details.lloydsNumber}</p>
                        <p className="details-row" ><span className="details-name">Voyage Code:</span>{this.props.details.voyageCode}</p>
                        <p className="details-row" ><span className="details-name">Movement Type:</span>{this.props.details.moveType}</p>
                        {this.renderMovementDate()}
                        <p className="details-row" ><span className="details-name">Arrive Harbour Date:</span><span>{this.props.details.arriveHarbourDate? formatEpochDate(this.props.details.arriveHarbourDate): ""}</span><span className="span3">    {this.props.details.arriveHarbourTime}</span></p>
                        <p className="details-row" ><span className="details-name">Arrive Berth Date:</span><span>{this.props.details.arriveBerthDate? formatEpochDate(this.props.details.arriveBerthDate):""}</span><span className="span3">    {this.props.details.arriveBerthTime}</span></p>
                        <p className="details-row" ><span className="details-name">Depart Berth Date:</span><span>{this.props.details.departBerthDate? formatEpochDate(this.props.details.departBerthDate):""}</span><span className="span3">    {this.props.details.departBerthTime}</span></p>
                        <p className="details-row" ><span className="details-name">Depart Harbour Date:</span><span>{this.props.details.departHarbourDate? formatEpochDate(this.props.details.departHarbourDate):""}</span><span className="span3">    {this.props.details.departHarbourTime}</span></p>
                        {this.renderEtaEtd()}
                        {this.renderEarliestLatest()}
                        <p className="details-row" ><span className="details-name">Agent:</span>{this.props.details.agent}</p>
                        <p className="details-row" ><span className="details-name">Berth:</span>{this.props.details.berth}</p>
                        {this.renderToPort()}
                        <p className="details-row" ><span className="details-name">Primary Cargo:</span>{this.props.details.primaryCargo}</p>
                        <p className="details-row" ><span className="details-name">Trade:</span>{this.props.details.trade}</p>
                        <p className="details-row" ><span className="details-name">Imports Loaded:</span><span>{this.props.details.importsLoaded === true? "Yes": (this.props.details.importsLoaded === false? "No": "")}</span></p>
                    </div>
                   {this.props.isLoggedIn && 
                    <div className="details-column details-column-2">
                         {this.renderComments()}
                         <p className="details-row" ><span className="details-name">Has Changes Pending:</span><span>{this.props.details.hasPendingChanges===true?"Yes":this.props.details.hasPendingChanges===false?"No":""}</span></p>
                         <p className="details-row" ><span className="details-name">Launch Time On Board:</span>{this.props.details.launchTimeOnBoard}</p>
                         <p className="details-row" ><span className="details-name">Side To:</span>{this.props.details.sideTo}</p>
                         <p className="details-row" ><span className="details-name">Tugs:</span>{this.props.details.tugs}</p>
                         <p className="details-row" ><span className="details-name">Tugs Time On Board:</span>{this.props.details.tugsTimeOnBoard}</p>
                         <p className="details-row" ><span className="details-name">LLoyds Number:</span>{this.props.details.lloydsNumber === "0"?"":this.props.details.lloydsNumber}</p>

                   <div className="save-button-container">
                        <div><input type="submit" className="submit-button save-button" value="Update" onClick={this.handleEdit}/></div>
                        </div>
                    </div>
                       }
                </div>
            </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
        details: state.details,
        isLoggedIn: state.visibleComponents.isLoggedIn,
        userObjectString: state.userInfo.user.userObjectString,
        sessionId: state.userInfo.sessionId,
        ports: state.ports,
    }
  }  

export default connect(mapStateToProps)(ShippingDetails);