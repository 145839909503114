import { combineReducers } from "redux";
import shippingSearch from "./shippingSearch"
import shippingReturn from "./shippingReturn"
import details from "./details"
import visibleComponents from "./visibleComponents"
import userInfo from "./userInfo"
import containersSearch from "./containersSearch"
import containersReturn from "./containersReturn"
import detailsTab from "./detailsTab"
import vehiclesFilterLookup from "./vehiclesFilterLookup"
import dropdowns from "./dropdowns"
import organiseTable from "./organiseTable"
import ports from "./ports"
import vehiclesSearch from "./vehiclesSearch"
import vehiclesReturn from "./vehiclesReturn"
import settings from "./settings"
import preNotes from "./preNotes"
import loading from "./loading"

export default combineReducers({
    shippingSearch,
    shippingReturn,
    details,
    visibleComponents,
    userInfo,
    containersSearch,
    containersReturn,
    detailsTab,
    vehiclesFilterLookup,
    dropdowns,
    organiseTable,
    ports,
    vehiclesSearch,
    vehiclesReturn,
    settings,
    preNotes,
    loading,
  });