import React from 'react';
import InPortDate from "./InPortDate"
import { searchShipping } from "../../../actions/search"
import DateRange from "./DateRange"
import { connect } from "react-redux";
import { todayDate, nextWeekDate, setDynamicDateRange } from "../../../utils"

class ShippingSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
     
         }
    }

    componentDidMount(){
        //If logged in and have not yet searched shipping, a search is triggered
        this.props.isLoggedIn && !this.props.shippingLoggedInSearched && this.props.dispatch(searchShipping())
        this.props.isLoggedIn && !this.props.shippingLoggedInSearched && this.props.dispatch({type:"SET_LOGGED_IN_SEARCHED", key:"shippingLoggedInSearched"})

        var initialFromDate = todayDate()
        var initialToDate = nextWeekDate()
        var startSearch = {
            "shippingMovementsFilter": {
                "fromDate": initialFromDate,
                "inPortDate": "",
                "includeArrivals": "true",
                "includeDepartures": "true",
                "includeShifts": "true",
                "movementDateDynamic": "",
                "primaryCargoTypeObjStr": "",
                "toDate": initialToDate,
                "vesselObjStr": "",
                "vesselTypeObjStr": ""
            }
        
    }

       }

    handleSubmit = event =>{
        event.preventDefault();
        this.props.dispatch(searchShipping())
    }


    render() { 
        return (  
            <React.Fragment>
                <br/>
                <form onSubmit={this.handleSubmit}>
                <div className="submit-conatiner">
                    <div>
                    {this.props.isLoggedIn && 
                        <input className="submit-button" type="button" value="Save/Load Search" onClick={()=>{this.props.dispatch({type:"CHANGE_VISIBLE_MODAL_BOX", visibleModalBox:"settingsShipping"})}}></input>
                    }
                    </div>
                    <div>
                        <input className={this.props.shippingSearchLoading?"button-loading submit-button":"submit-button"} type="submit"></input>
                    </div>
                </div>
                <hr></hr>
                <DateRange/>
                <hr></hr>
                <InPortDate/>
                <br/>
                <hr></hr>
                <p>Show:</p>
                <div id="show-container">
                    <div><span>Arrivals: </span><input type="checkbox" name="includeArrivals" checked={this.props.shippingSearch.includeArrivals} onChange={()=>{this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:event.target.name, value:event.target.checked}})}}></input></div>
                    <div><span>Departures: </span><input type="checkbox" name="includeDepartures" checked={this.props.shippingSearch.includeDepartures} onChange={()=>{this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:event.target.name, value:event.target.checked}})}}></input></div>
                    <div><span>Shifts: </span><input type="checkbox" name="includeShifts" checked={this.props.shippingSearch.includeShifts} onChange={()=>{this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:event.target.name, value:event.target.checked}})}}></input></div>
                </div>
                <br/>
                <hr></hr>
                <div>
                    <br/>
                <div className="dropdown-container">
                    <span>Vessels: </span>
                    <select 
                    onChange={(event)=>this.props.dispatch({type: "CHANGE_POST_VESSEL_OBJECT", vesselObjStr:event.target.value})} 
                    value={this.props.shippingSearch.vesselObjStr}
                    >
                        <option value=""></option>
                    {this.props.vesselsList.map((item,i)=>{
                    return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                    })}
                    </select>
                </div>
                <div className="dropdown-container">
                    <span>Vessel Types: </span>
                    <select 
                    onChange={(event)=>this.props.dispatch({type: "CHANGE_POST_VESSEL_TYPES", vesselTypeObjStr:event.target.value})}
                    value={this.props.shippingSearch.vesselTypeObjStr}
                    >
                        <option value=""></option>
                    {this.props.vesselTypesList.map((item,i)=>{
                    return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                    })}
                    </select>
                </div>
                <div className="dropdown-container">
                    <span>Primary Cargo Type: </span>
                    <select 
                    onChange={(event)=>this.props.dispatch({type: "CHANGE_POST_PRIMARY_CARGO_TYPE", primaryCargoTypeObjStr:event.target.value})}
                    value={this.props.shippingSearch.primaryCargoTypeObjStr}
                    >
                        <option value=""></option>
                    {this.props.primaryCargoTypesList.map((item,i)=>{
                    return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                    })}
                    </select>
                </div>
                <br/>
                </div>
                </form>
            </React.Fragment>
        );
    }
}
 
function mapStateToProps(state){
    return {
        shippingSearch: state.shippingSearch,
        // dateRange: state.shippingSearch,
        isLoggedIn: state.visibleComponents.isLoggedIn,
        userInfo: state.userInfo,
        shippingSearchLoading: state.loading.shippingSearchLoading,
        shippingLoggedInSearched: state.visibleComponents.shippingLoggedInSearched

    }
  }  

export default connect(mapStateToProps)(ShippingSearch)