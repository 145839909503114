import formatDate from "./formatDate"

export default function setDynamicDateRange(dateDirection, dateValue, dateType){
    var today = new Date()
    var toDate = new Date()
    var fromDate = new Date()
    var dateValue = parseInt(dateValue) || 0


      if(dateDirection === "Next"){
        switch(dateType){
          case "Day(s)": toDate.setDate(today.getDate() + dateValue);
          break;
          case "Week(s)": toDate.setDate(today.getDate() + (dateValue * 7));
          break;
          case "Month(s)": toDate.setMonth(today.getMonth() + dateValue);
          break;
          case "Year(s)": toDate.setFullYear(today.getFullYear() + dateValue);
          break;
          default: console.log("error")
    
        }
        return formatDate(toDate)
      } else if (dateDirection === "Last"){
            switch(dateType){
          case "Day(s)": fromDate.setDate(today.getDate() - dateValue);
          break;
          case "Week(s)": fromDate.setDate(today.getDate() - (dateValue * 7));
          break;
          case "Month(s)": fromDate.setMonth(today.getMonth() - dateValue);
          break;
          case "Year(s)": fromDate.setFullYear(today.getFullYear() - dateValue);
          break;
          default: console.log("error")
    
        }
        return formatDate(fromDate)
      } else {
        console.log("error")
      }}