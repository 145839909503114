const initialState = {
    cursorLoading:false,
    contactUsLoading: false,
    shippingSearchLoading: false,
    containersSearchLoading: false,
    vehiclesSearchLoading:false,
    
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_LOADING_STATUS":
            return Object.assign({}, state, {[action.payload.key]: action.payload.value})
            break;
        case "START_LOADING_STATUS":
            return Object.assign({}, state, {[action.key]: true, cursorLoading:true})
            break;
        case "STOP_LOADING_STATUS":
            return Object.assign({}, state, {[action.key]: false, cursorLoading:false})
            break;
        case "LOADING_CURSOR":
            return Object.assign({}, state, {cursorLoading: true})
            break;
        case "DEFAULT_CURSOR":
            return Object.assign({}, state, {cursorLoading: false})
            break;
        default:
            return state
    }
}

export default reducer