import React from "react"
import {connect} from "react-redux"
import { containersPost } from "../../actions"
import { todayDate, setDynamicDateRange } from "../../utils"
import { searchContainers } from "../../actions/search"




class ContainersSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount(){
        //If logged in and have not yet searched containers, a search is triggered noted so search only happens once.
        this.props.isLoggedIn && !this.props.containersLoggedInSearched && this.props.dispatch(searchContainers())
        this.props.isLoggedIn && !this.props.containersLoggedInSearched && this.props.dispatch({type:"SET_LOGGED_IN_SEARCHED", key:"containersLoggedInSearched"})

    }

    handleSubmit =event =>{
        event.preventDefault()
        this.props.dispatch(searchContainers())
    }

    handleReleasedRadioChange = event =>{
        if(event.target.checked === false){
            this.props.dispatch({type:"CHANGE_CONTAINER_RADIO_RELEASED_DATE", radioReleasedDate: "none"})

        }else{
            this.props.dispatch({type:"CHANGE_CONTAINER_RADIO_RELEASED_DATE", radioReleasedDate: event.target.name})

        }
    }

    handleReceivedRadioChange = event =>{
        if(event.target.checked === false){
            this.props.dispatch({type:"CHANGE_CONTAINER_RADIO_RECEIVED_DATE", radioReceivedDate: "none"})

        }else{
            this.props.dispatch({type:"CHANGE_CONTAINER_RADIO_RECEIVED_DATE", radioReceivedDate: event.target.name})

        }
    }

    handleInput = event => {
        this.props.dispatch(
            {
                type: "CHANGE_SEARCH_CONTAINERS_INPUT",
                payload:{
                   key: event.target.name,
                    value:event.target.value
                }
            }
        )
    }

    handleVoyageChange = event =>{
        this.props.dispatch({type: "CHANGE_CONTAINER_VOYAGE_OBJ_STR", voyageObjStr: event.target.value})
    }

    handleOperatorChange = event =>{
        this.props.dispatch({type: "CHANGE_CONTAINER_OPERATOR_OBJ_STR", operatorObjStr: event.target.value})
    }

    renderOperatorSelect = ()=>{
        if(this.props.isLoggedIn === true){
          return( 
                <div className="input-div two-items-div">
                    <span>Operator: </span>
                    <select 
                        className="input-select" 
                        onChange={this.handleOperatorChange}
                        value={this.props.c.operatorObjStr}
                    >
                        <option value=""></option>
                        {this.props.operators && this.props.operators.map((item, i)=>{
                    return(
                        <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                    )
            })}
                    </select>
                </div>
          )
        }
    }

   handleImportsAndOnPortLogic = (event)=>{
    //    console.log(this.props.c.includeOnPort)
       
       if(event.target.name === "includeImports"){
           this.props.dispatch({type: "TOGGLE_INCLUDEIMPORTS"})
        //    console.log(event.target.name)
          // console.log(event.target.checked)

    } else if ((event.target.name === "includeOnPort")){
        this.props.dispatch({type: "TOGGLE_INCLUDEONPORT"})
    }

    if(
        event.target.name === "includeImports" && event.target.checked === false && this.props.c.includeOnPort === false ||
        event.target.name === "includeOnPort" && event.target.checked === false && this.props.c.includeImports === false
    ){
        //disable storage days and

    } else if ((event.target.name === "includeOnPort")){
        this.props.dispatch({type: "TOGGLE_INCLUDEONPORT"})
    }
   }

    render() { 
        return ( 
            <React.Fragment>
                <form onSubmit = {this.handleSubmit} className="search-form">
                <br/>
                <div className="submit-conatiner">
                    <div>
                    {this.props.isLoggedIn && 
                        <input className="submit-button" type="button" value="Save/Load Search" onClick={()=>{this.props.dispatch({type:"CHANGE_VISIBLE_MODAL_BOX", visibleModalBox:"settingsContainers"})}}></input>
                    }
                    </div>
                    <div>
                        <input className={this.props.containersSearchLoading?"button-loading submit-button":"submit-button"} type="submit" ></input>
                    </div>
                </div>
                <hr></hr>
                    <div className="textarea-div two-items-div">
                        <span>IDs:</span>
                        <textarea className="input-date" rows="3" value={this.props.c.ids} onChange={()=> this.props.dispatch({type:"CHANGE_CONTAINER_IDS", ids: event.target.value})}></textarea>
                    </div>
                    <div className="textarea-div two-items-div">
                        <span>CEDOs:</span>
                        <textarea className="input-date" rows="3" value={this.props.c.cedos} onChange={()=> this.props.dispatch({type:"CHANGE_CONTAINER_CEDOS", cedos: event.target.value})}></textarea>
                    </div>
                    <div className="input-div two-items-div">
                        <span>Booking Reference:</span>
                        <input className="input-date" type="text" value={this.props.c.bookingReference} onChange={()=> this.props.dispatch({type:"CHANGE_CONTAINER_BOOKING_REFERENCE", bookingReference: event.target.value})}></input>
                    </div>
                    <hr></hr>
                    <div className="input-div">
                        <div className="checkbox-row">
                        <div className="checkbox-div"><span className="checkbox-name">On Port: </span><input type="checkbox" name="includeOnPort" checked={this.props.c.includeOnPort} onChange={this.handleImportsAndOnPortLogic}></input></div>
                        <div className="checkbox-div"><span className="checkbox-name">On Ship: </span><input type="checkbox" name="includeOnShip" checked={this.props.c.includeOnShip} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEONSHIP"})}></input></div>
                        </div>
                        <div className="checkbox-row">
                        <div className="checkbox-div"><span className="checkbox-name">Off Port: </span><input type="checkbox" name="includeOffPort" checked={this.props.c.includeOffPort}  onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEOFFPORT"})}></input></div>
                        <div className="checkbox-div"><span className="checkbox-name">Pre-noted: </span><input type="checkbox" name="includeNotified" checked={this.props.c.includeNotified} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDENOTIFIED"})}></input></div>
                        </div>
                    </div>
                    <div className={this.props.c.includeOffPort === true?"":"not-logged-in"}>
                        <div className="input-div">
                            <p>Off Port Date:</p>
                            <input type="checkbox" name="dynamicDate" checked={this.props.c.radioReleasedDate === "dynamicDate"} onChange={this.handleReleasedRadioChange} value="dynamicDate"></input><span className="input-title">Last: </span>
                            <input type="number" className="number-input" value={this.props.c.releasedDateRangeNumber} name="releasedDateRangeNumber" onChange={this.handleInput}></input>
                            <select className="input-select"  name="releasedDateRangeType" onChange={this.handleInput} value={this.props.c.releasedDateRangeType}>
                                <option value="Day(s)">Day(s)</option>
                                <option value="Week(s)">Week(s)</option>
                                <option value="Month(s)">Month(s)</option>
                                <option value="Year(s)">Year(s)</option>
                            </select>
                        </div>
                        <div className="input-div">
                            <input type="checkbox" name="fromDateToDate" checked={this.props.c.radioReleasedDate === "fromDateToDate"} onChange={this.handleReleasedRadioChange} value="fromDateToDate"></input>
                            <span className="input-title">From:</span>
                            <input type="date" className="input-date" name="releasedFromDate" onChange={this.handleInput}></input>
                                <span className="input-title">To:</span>
                            <input type="date" className="input-date" name="releasedToDate" onChange={this.handleInput}></input>
                        </div>
                    </div>
                    <div className={this.props.isLoggedIn?"":"not-logged-in"}>
                    <hr></hr>
                        <div className="input-div two-items-div">
                            <span>Voyage: </span>
                            <select 
                            className="input-select" 
                            onChange={this.handleVoyageChange}
                            value={this.props.c.voyageObjStr}
                            >
                                <option></option>
                                {this.props.voyages && this.props.voyages.map((item, i)=>{
                            return(
                                <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                            )
                        })}
                            </select>
                        </div>
                        <div className="input-div two-items-div">
                            <span>Customs Entry Number: </span>
                            <input type="text" className="input-select" value={this.props.c.customsEntryNumber} onChange={()=> this.props.dispatch({type:"CHANGE_CONTAINER_CUSTOMS_ENTRY_NUMBER", customsEntryNumber: event.target.value})}></input>
                        </div>
                        <div className="input-div two-items-div">
                            <span>Operator Pin: </span>
                            <input type="text" className="input-select" value={this.props.c.operatorPIN} onChange={()=> this.props.dispatch({type:"CHANGE_CONTAINER_OPERATOR_PIN", operatorPIN: event.target.value})}></input>
                        </div>
                        {this.renderOperatorSelect()}
                        <div className="input-div two-items-div">
                            <span>Storage Days: </span>
                            <input type="number" className="input-select" value={this.props.c.storageDays} onChange={()=> this.props.dispatch({type:"CHANGE_CONTAINER_STORAGE_DAYS", storageDays: event.target.value})}></input>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                        <div className="checkbox-div"><span className="checkbox-name">Exports: </span><input type="checkbox" name="includeExports" checked={this.props.c.includeExports} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEEXPORTS"})}></input></div>
                        <div className="checkbox-div"><span className="checkbox-name">Imports: </span><input type="checkbox" name="includeImports" checked={this.props.c.includeImports} onChange={this.handleImportsAndOnPortLogic}></input></div>
                        </div>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">DLR: </span><input type="checkbox" name="includeDLR" checked={this.props.c.includeDLR} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEDLR"})}></input></div>
                            <div className="checkbox-div"><span className="checkbox-name">Storage: </span><input type="checkbox" name="includeStorage" checked={this.props.c.includeStorage} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDESTORAGE"})}></input></div>
                        </div>
                        <div className="checkbox-row">
                        <div className="checkbox-div"><span className="checkbox-name">SOB: </span><input type="checkbox" name="includeSOB" checked={this.props.c.includeSOB} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDESOB"})}></input></div>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                        <div className="checkbox-div"><span className="checkbox-name">Cleared: </span><input type="checkbox" name="includeCleared" checked={this.props.c.includeCleared} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDECLEARED"})}></input></div>
                        <div className="checkbox-div"><span className="checkbox-name">On Hold: </span><input type="checkbox" name="includeOnHold" checked={this.props.c.includeOnHold} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEONHOLD"})}></input></div>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">Ready to Deliver: </span><input type="checkbox" name="includeReadyToDeliver" checked={this.props.c.includeReadyToDeliver} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEREADYTODELIVER"})}></input></div>
                            <div className="checkbox-div"><span className="checkbox-name">Not Ready to Deliver: </span><input type="checkbox" name="includeNotReadyToDeliver" checked={this.props.c.includeNotReadyToDeliver} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDENOTREADYTODELIVER"})}></input></div>
                        </div>
                        <hr></hr>
                        <div className="checkbox-row">
                        <div className="checkbox-div"><span className="checkbox-name">Dry: </span><input type="checkbox" name="includeDry" checked={this.props.c.includeDry} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEDRY"})}></input></div>
                        <div className="checkbox-div"><span className="checkbox-name">Powered Reefers: </span><input type="checkbox" name="includeReefers"  checked={this.props.c.includeReefers} onChange={() => this.props.dispatch({type: "TOGGLE_INCLUDEREEFERS"})}></input></div>
                        </div>
                        <div className="checkbox-row">
                            <div className="checkbox-div"><span className="checkbox-name">No CEDO Only: </span><input type="checkbox" name="noCedoOnly" checked={this.props.c.noCedoOnly} onChange={() => this.props.dispatch({type: "TOGGLE_NOCEDOONLY"})}></input></div>
                        </div>
                        <hr></hr>
                        <div className="input-div">
                            <p>Received Date:</p>
                            <input type="checkbox" name="dynamicDate" checked={this.props.c.radioReceivedDate === "dynamicDate"} value="dynamicDate" onChange={this.handleReceivedRadioChange}></input><span className="input-title">Last: </span>
                            <input type="number" className="number-input" value={this.props.c.receivedDateRangeNumber} name="receivedDateRangeNumber" onChange={this.handleInput}></input>
                            <select className="input-select"  name="receivedDateRangeType" onChange={this.handleInput}  value={this.props.c.receivedDateRangeType}>
                                <option value="Day(s)">Day(s)</option>
                                <option value="Week(s)">Week(s)</option>
                                <option value="Month(s)">Month(s)</option>
                                <option value="Year(s)">Year(s)</option>
                            </select>
                        </div>
                        <div className="input-div">
                            <input type="checkbox" name="fromDateToDate" checked={this.props.c.radioReceivedDate === "fromDateToDate"} value="fromDateToDate" onChange={this.handleReceivedRadioChange}></input>
                            <span className="input-title">From:</span>
                            <input type="date" className="input-date" name="receivedFromDate" onChange={this.handleInput}></input>
                                <span className="input-title">To:</span>
                            <input type="date" className="input-date" name="receivedToDate" onChange={this.handleInput}></input>
                        </div>
                    </div>
                    <br/>
                </form>
            </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        c: state.containersSearch,
        userInfo: state.userInfo,
        voyages: state.dropdowns.containersVoyages,
        operators: state.dropdowns.operators,
        containersSearchLoading: state.loading.containersSearchLoading,
        containersLoggedInSearched: state.visibleComponents.containersLoggedInSearched

    }
  }  

export default connect(mapStateToProps)(ContainersSearch);