import React from "react"
import { connect } from "react-redux"
import { sendContainerNumber, postPreNoteContainer, copyPostPreNoteContainer } from "../../actions"

class ExportPreNotes2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            radio: "0",
            index: 0,
         }

         
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_PRE_NOTES_INDEX", preNotesIndex: 0})
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            this.props.dispatch({type: "RESET_PRE_NOTES"})


        }
    }

    handleRadioChange = event => {
        var valueAsNumber = parseInt(event.target.value)
        this.setState({radio:event.target.name})
        this.setState({index:valueAsNumber})
    }

    handleElementClick = index => {
        var indexToString = index.toString()
        this.setState({radio:indexToString})
        this.setState({index:index})
    }

    handleSubmit = event =>{
        //used to set the voyage index for the pre-notes
        event.preventDefault()
        this.props.dispatch({type: "CHANGE_PRE_NOTES_INDEX", preNotesIndex: this.state.index})
        this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "exportPreNotes2"})
        
    }


    render() { 
        return ( 
            <div className="modal-background">
                <div className="export-pre-notes-container">
                    <div className="close" onClick={this.handleCancel}>&times;
                    </div>
                    <div className="modal-header">
                        <span>Export Pre-notes</span>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="modal-content-container">
                            <div className="details-row">
                            <span>Booking Reference: </span>
                            <span className="pre-notes-span-2"></span>
                                <span>{this.props.preNotes[0].bookingReferenceNumber}</span>
                            </div>
                            <div>
                                {this.props.preNotes.map((item, index)=>{
                                    return(
                                        <div key={"p"+index} className="pre-notes-voyage-list">
                                        <input 
                                            type="radio" 
                                            className="favourites-radio" 
                                            value={index} 
                                            name={index} 
                                            checked={this.state.radio===index.toString()} 
                                            onChange={this.handleRadioChange} 
                                            key={index}
                                            />
                                        <span key={"span"+index} name={index} onClick={()=>this.handleElementClick(index)}>{item.voyage.displayAttribute}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <br/>
                            <div>
                                <input type="submit" value="Next >>" className="submit-button save-button"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
    preNotes: state.preNotes,

    }
  }  

export default connect(mapStateToProps)(ExportPreNotes2);