import React from "react"
import { connect } from "react-redux"
import { deleteSavedSetting, saveSetting } from "../../actions"
import { loadContainersSettings } from "../../actions/settings"
import { convertArrayToString, convertStringToArray } from "../../utils"
import { searchContainers } from "../../actions/search"
// import { saveContainersSettings } from "../../actions/saveSettings"

class SettingsContainers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            radio: "-1",
            saveName: "",
            index: -1,
        }
    }

    componentDidMount(){
        this.nameInput.focus();
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }
  
    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }

    handleRadioChange = event => {
        this.setState({radio:event.target.name})
        this.setState({index:event.target.value})
    }

    handleElementClick = index => {
        var indexToString = index.toString()
        this.setState({radio:indexToString})
        this.setState({index:indexToString})
    }
    handleDelete = event =>{
        if(this.state.index >=0){
            var containerSetting = this.props.allSettings[this.state.index]
            var deleteBody = {	
                "savedSetting" : {
                        "name": containerSetting.name,
                        "type": 1
                    },
                "user" :{
                    "userObjectString": this.props.userInfo.user.userObjectString
                },
                "sessionId" : this.props.userInfo.sessionId
            }
           this.props.dispatch(deleteSavedSetting(deleteBody, this.props.userInfo.user.userObjectString))
           console.log(deleteBody)
        }else{
            alert("Please select an option to delete")
        }
    }

    handleLoad = event =>{
        //if text input is selected, index= -1.
           if(this.state.index >=0 ){
            // Load settings, then search table, then close settings box
            this.props.dispatch(loadContainersSettings(this.props.allSettings[this.state.index]))
            this.props.dispatch(searchContainers())
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        } else{
            alert("Please select an option to load")
        }

        }

        handleSave = (event) =>{
            var containersSettings = this.props.allSettings[this.state.index]
            var saveName = ""
            if(this.state.index<0){
                saveName= this.state.saveName
            }else{
                saveName = containersSettings.name
            }
            var postBody = {	
                "savedSetting" : {
                    "cargoItemFilter": {
                        "savedSetting": null,
                        "bookingReference": this.props.containersSearch.bookingReference,
                        "cargoType": 1,
                        "cedos": convertStringToArray(this.props.containersSearch.cedos),
                        //"clientReference": this.props.containersSearch.clientReference,
                        "customsEntryNumber": this.props.containersSearch.customsEntryNumber,
                        //"houseBOL": "",
                        "ids": convertStringToArray(this.props.containersSearch.ids),
                        "includeCleared": this.props.containersSearch.includeCleared,
                        "includeDLR": this.props.containersSearch.includeDLR,
                        "includeDry": this.props.containersSearch.includeDry,
                        "includeExports": this.props.containersSearch.includeExports,
                        "includeImports": this.props.containersSearch.includeImports,
                        "includeNotified": this.props.containersSearch.includeNotified,
                        "includeNotReadyToDeliver": this.props.containersSearch.includeNotReadyToDeliver,
                        "includeOffPort": this.props.containersSearch.includeOffPort,
                        "includeOnHold": this.props.containersSearch.includeOnHold,
                        "includeOnPort": this.props.containersSearch.includeOnPort,
                        "includeOnShip": this.props.containersSearch.includeOnShip,
                        "includeReadyToDeliver": this.props.containersSearch.includeReadyToDeliver,
                        "includeReefers": this.props.containersSearch.includeReadyToDeliver,
                        "includeSOB": this.props.containersSearch.includeSOB,
                        "includeStorage": this.props.containersSearch.includeStorage,
                        "noCedoOnly": this.props.containersSearch.noCedoOnly,
                        //"oceanBOL": "",
                        "operator": null,
                        "operatorObjStr": this.props.containersSearch.operatorObjStr,
                        "operatorPIN": this.props.containersSearch.operatorPIN,
                        "receivedDateDynamic": "5,Week(s)", //ADD
                        "receivedFromDate": this.props.containersSearch.receivedFromDate,
                        "receivedToDate": this.props.containersSearch.receivedToDate,
                        "releasedDateDynamic": "3,Month(s)", //ADD
                        "releasedFromDate": this.props.containersSearch.releasedFromDate,
                        "releasedToDate": this.props.containersSearch.releasedToDate,
                        "storageDays": this.props.containersSearch.storageDays,
                        "voyage": null,
                        "voyageObjStr": this.props.containersSearch.voyageObjStr
                    },
                    "columnSettings": [
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Freight Forwarder",
                            "name": "freightForwarder",
                            "position": this.props.columnOrder.freightForwarderPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.freightForwarder,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.freightForwarderWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "ID",
                            "name": "id",
                            "position": this.props.columnOrder.idPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.id,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.idWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Vessel",
                            "name": "vessel",
                            "position": this.props.columnOrder.vesselPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.vessel,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.vesselWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": true,
                            "heading": "Voyage Code",
                            "name": "portVoyageCode",
                            "position": this.props.columnOrder.portVoyageCodePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.portVoyageCode,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.portVoyageCodeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Operator",
                            "name": "operator",
                            "position": this.props.columnOrder.operatorPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.operator,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.operatorWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "IMEX Status",
                            "name": "imexStatus",
                            "position": this.props.columnOrder.imexStatusPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.imexStatus,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.imexStatusWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Operator Stop",
                            "name": "hasOperatorStop",
                            "position": this.props.columnOrder.hasOperatorStopPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.hasOperatorStop,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.hasOperatorStopWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Stops",
                            "name": "stops",
                            "position": this.props.columnOrder.stopsPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.stops,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.stopsWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Has Tasks",
                            "name": "hasTasks",
                            "position": this.props.columnOrder.hasTasksPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.hasTasks,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.hasTasksWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Freight Forwarder Stop",
                            "name": "hasFreightForwarderStop",
                            "position": this.props.columnOrder.hasFreightForwarderStopPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.hasFreightForwarderStop,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.hasFreightForwarderStopWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Locality",
                            "name": "locality",
                            "position": this.props.columnOrder.localityPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.locality,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.localityWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "ISO Code",
                            "name": "isoCode",
                            "position": this.props.columnOrder.isoCodePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.isoCode,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.isoCodeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Length/Width/Height",
                            "name": "lengthWidthHeight",
                            "position": this.props.columnOrder.lengthWidthHeightPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.lengthWidthHeight,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.lengthWidthHeightWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Is Reefer",
                            "name": "isReefer",
                            "position": this.props.columnOrder.isReeferPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.isReefer,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.isReeferWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Is Overdimensional",
                            "name": "isOverdimensional",
                            "position": this.props.columnOrder.isOverdimensionalPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.isOverdimensional,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.isOverdimensionalWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Hazardous Description",
                            "name": "hazardousDescription",
                            "position": this.props.columnOrder.hazardousDescriptionPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.hazardousDescription,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.hazardousDescriptionWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Receive Date",
                            "name": "receivedDateTime",
                            "position": this.props.columnOrder.receivedDateTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.receivedDateTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.receivedDateTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Receive Mode",
                            "name": "receiveMode",
                            "position": this.props.columnOrder.receiveModePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.receiveMode,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.receiveModeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Release Date",
                            "name": "releasedDateTime",
                            "position": this.props.columnOrder.releasedDateTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.releasedDateTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.releasedDateTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Release Mode",
                            "name": "releaseMode",
                            "position": this.props.columnOrder.releaseModePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.releaseMode,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.releaseModeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Last Day Of Free Storage",
                            "name": "lastDayOfFreeStorage",
                            "position": this.props.columnOrder.lastDayOfFreeStoragePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.lastDayOfFreeStorage,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.lastDayOfFreeStorageWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Available For Delivery Date",
                            "name": "availableForDeliveryDateTime",
                            "position": this.props.columnOrder.availableForDeliveryDateTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.availableForDeliveryDateTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.availableForDeliveryDateTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Booking Reference",
                            "name": "bookingReference",
                            "position": this.props.columnOrder.bookingReferencePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.bookingReference,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.bookingReferenceWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "CEDO",
                            "name": "cedo",
                            "position": this.props.columnOrder.cedoPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.cedo,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.cedoWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Commodity",
                            "name": "commodity",
                            "position": this.props.columnOrder.commodityPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.commodity,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.commodityWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Consignee",
                            "name": "consignee",
                            "position": this.props.columnOrder.consigneePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.consignee,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.consigneeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Consignor",
                            "name": "consignor",
                            "position": this.props.columnOrder.consignorPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.consignor,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.consignorWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Customs Entry Number",
                            "name": "customsEntryNumber",
                            "position": this.props.columnOrder.customsEntryNumberPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.customsEntryNumber,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.customsEntryNumberWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Has Been Unpacked",
                            "name": "hasBeenUnpacked",
                            "position": this.props.columnOrder.hasBeenUnpackedPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.hasBeenUnpacked,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.hasBeenUnpackedWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Optimal Pick Up",
                            "name": "optimalPickUp",
                            "position": this.props.columnOrder.optimalPickUpPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.optimalPickUp,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.optimalPickUpWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "PIN",
                            "name": "pin",
                            "position": this.props.columnOrder.pinPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.pin,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.pinWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Carrier Remarks",
                            "name": "carrierRemarks",
                            "position": this.props.columnOrder.carrierRemarksPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.carrierRemarks,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.carrierRemarksWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Receive Carrier",
                            "name": "receiveCarrier",
                            "position": this.props.columnOrder.receiveCarrierPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.receiveCarrier,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.receiveCarrierWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Receive Vehicle",
                            "name": "receiveVehicle",
                            "position": this.props.columnOrder.receiveVehiclePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.receiveVehicle,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.receiveVehicleWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Release Carrier",
                            "name": "releaseCarrier",
                            "position": this.props.columnOrder.releaseCarrierPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.releaseCarrier,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.releaseCarrierWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Release Vehicle",
                            "name": "releaseVehicle",
                            "position": this.props.columnOrder.releaseVehiclePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.releaseVehicle,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.releaseVehicleWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Seal",
                            "name": "seal",
                            "position": this.props.columnOrder.sealPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.seal,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.sealWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Temperature",
                            "name": "temperature",
                            "position": this.props.columnOrder.temperaturePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.temperature,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.temperatureWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Load Port",
                            "name": "loadPort",
                            "position": this.props.columnOrder.loadPortPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.loadPort,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.loadPortWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Discharge Port",
                            "name": "dischargePort",
                            "position": this.props.columnOrder.dischargePortPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.dischargePort,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.dischargePortWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Connected Date",
                            "name": "connectedDateTime",
                            "position": this.props.columnOrder.connectedDateTimePosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.connectedDateTime,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.connectedDateTimeWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Weight",
                            "name": "weight",
                            "position": this.props.columnOrder.weightPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.weight,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.weightWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Destination Port",
                            "name": "destinationPort",
                            "position": this.props.columnOrder.destinationPortPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.destinationPort,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.destinationPortWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Is Transhipment",
                            "name": "isTranshipment",
                            "position": this.props.columnOrder.isTranshipmentPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.isTranshipment,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.isTranshipmentWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "Yard Location",
                            "name": "yardLocation",
                            "position": this.props.columnOrder.yardLocationPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.yardLocation,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.yardLocationWidth
                        },
                        {
                            "ascending": false,
                            "groupBy": false,
                            "heading": "VGM",
                            "name": "isWeightCertified",
                            "position": this.props.columnOrder.isWeightCertifiedPosition,
                            "savedSetting": null,
                            "show": this.props.columnInfo.isWeightCertified,
                            "sortOrder": 0,
                            "width": this.props.columnInfo.isWeightCertifiedWidth
                        }
                    ],
                    "columnSorts":this.props.columnInfo.defaultSorted,
                    "name": saveName,
                    "shippingMovementFilter": null,
                    "type": 1,
                    },
                "user" :{
                    "userObjectString": this.props.userInfo.user.userObjectString
                },
                "sessionId" : this.props.userInfo.sessionId
            }
    
            if(this.state.index<0 && this.state.saveName.length<1){
                alert("Please choose an option or make a save name")
            } else {
                this.props.dispatch(saveSetting(postBody, this.props.userInfo.user.userObjectString))
                // this.props.dispatch(searchContainers())
                this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            }
            console.log(postBody)
        }

    render() { 
        return ( 
            <div className="modal-background">
            <div className="favourites-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                    <span>Favourites</span>
                </div>
                <div className="modal-content-container">
                <form>
                    <p>
                    <input type="radio" className="favourites-radio"  value="-1" name="-1" checked={this.state.radio==="-1"} onChange={this.handleRadioChange}/>
                    <input 
                        type="text" 
                        className="favourites-text-input" 
                        name="saveName" value={this.state.saveName} 
                        onChange={this.handleChange} 
                        placeholder="Create New Setting" 
                        ref={(inputEl) => this.nameInput = inputEl}
                        onClick={()=>this.handleElementClick(-1)}
                        />
                    </p>
                    {  this.props.allSettings.map((item,index)=>{
                        if(item.type ===1 && item.cargoItemFilter){
                            return(
                                <p key={"p"+index}>
                                <input 
                                    type="radio" 
                                    className="favourites-radio" 
                                    value={index} 
                                    name={index} 
                                    checked={this.state.radio===index.toString()} 
                                    onChange={this.handleRadioChange} 
                                    key={index}
                                    />
                                <span key={"span"+index} name={index} onClick={()=>this.handleElementClick(index)}>{item.name}</span>
                                </p>
                            )
                        } else {
                            return ""
                        }
        })}
                    <input type="button" value="Load" className="submit-button save-button" onClick={this.handleLoad}/>
                    <input type="button" value="Save" className="submit-button save-button middle-button" onClick={this.handleSave}/>
                    <input type="button" value="Delete" className="submit-button save-button" onClick={this.handleDelete}/>


                </form>
                </div>
            </div>
        </div>

         );
    }
}

function mapStateToProps(state){
    return {
    allSettings: state.settings.allSettings,
    userInfo: state.userInfo,
    containersSearch : state.containersSearch,
    columnInfo: state.organiseTable.containers,
    columnOrder: state.organiseTable.containersColOrder

    }
  }  
 
export default connect(mapStateToProps)(SettingsContainers);