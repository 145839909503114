const initialState = {
    isLoggedIn: false,
    loginErrorText: "",
    changePasswordErrorText: "",
    visibleTab: "Shipping",
    collapsedSearchTab: false,
    collapsedDetails: false,
    // visibleModalBox: "forgotPassword",
    //visibleModalBox: "changePasswordForm",
    visibleModalBox: "none",
    containersSubTab: "Details",
    shippingSubTab: "Details",
    vehiclesSubTab: "Details",
    containersSaveButton: false,
    vehiclesSaveButton: false,
    containersSaveArray: [],
    vehiclesSaveArray: [],
    editAllConOperatorStop: false,
    editAllConFFStop: false,
    editAllConFF: false,
    editAllConCarrier: false,
    editAllConCarrierRemarks: false,
    editAllVehOperatorStop: false,
    editAllVehFFStop: false,
    editAllVehCarrier: false,
    editAllVehCarrierRemarks: false,
    editAllVehConsignee: false,
    editAllVehFF: false,
    editAllVehCustomsAgentStop: false,
    shippingTableSelectedRow:null,
    containersTableSelectedRow:null,
    vehiclesTableSelectedRow:null,
    shippingLoggedInSearched:false,
    containersLoggedInSearched:false,
    vehiclesLoggedInSearched:false,
    //TableKeys used to trigger rerender of draggable table headers on loadSettings/login
    shippingTableKey:0,
    containersTableKey:0,
    vehiclesTableKey:0,
    preNotesIndex: 0 // for prenotes if booking reference has multiple voyages
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_LOGIN_STATUS":
            return Object.assign({}, state, {isLoggedIn: action.isLoggedIn})
            break;
        case "CHANGE_VISIBLE_TAB":
            return Object.assign({}, state, {visibleTab: action.visibleTab})
            break;    
        case "CHANGE_VISIBLE_CONTAINERS_SUBTAB":
            return Object.assign({}, state, {containersSubTab: action.containersSubTab})
            break;   
        case "CHANGE_VISIBLE_SHIPPING_SUBTAB":
            return Object.assign({}, state, {shippingSubTab: action.shippingSubTab})
            break;   
        case "CHANGE_VISIBLE_VEHICLES_SUBTAB":
            return Object.assign({}, state, {vehiclesSubTab: action.vehiclesSubTab})
            break;  
        case "CHANGE_VISIBLE_MODAL_BOX":
            return Object.assign({}, state, {visibleModalBox: action.visibleModalBox})
            break;  
        case "CHANGE_CONTAINERS_SAVE_BUTTON":
            return Object.assign({}, state, {containersSaveButton: action.containersSaveButton})
            break;  
        case "CHANGE_VEHICLES_SAVE_BUTTON":
            return Object.assign({}, state, {vehiclesSaveButton: action.vehiclesSaveButton})
            break;  
        case "UPDATE_CONTAINERS_SAVE_ARRAY":
            return Object.assign({}, state, {containersSaveArray: [...state.containersSaveArray, action.index]})
            break; 
        case "RESET_CONTAINERS_SAVE_ARRAY":
            return Object.assign({}, state, {containersSaveArray: []})
            break; 
        case "UPDATE_VEHICLES_SAVE_ARRAY":
            return Object.assign({}, state, {vehiclesSaveArray: [...state.vehiclesSaveArray, action.index]})
            break; 
        case "RESET_VEHICLES_SAVE_ARRAY":
            return Object.assign({}, state, {vehiclesSaveArray: []})
            break; 
        case "SET_EDIT_ALL_VALUE":
            return Object.assign({}, state, {[action.payload.key]: action.payload.value})
            break; 
        case "SET_SELECTED_ROW":
            return Object.assign({}, state, {[action.payload.key]: action.payload.value})
            break; 
        case "SET_LOGGED_IN_SEARCHED":
            return Object.assign({}, state, {[action.key]: true})
            break; 
        case "RESET_EDIT_ALL_CON":
            return Object.assign({}, state, {
                editAllConOperatorStop: false,
                editAllConFFStop: false,
                editAllConFF: false,
                editAllConCarrier: false,
                editAllConCarrierRemarks: false})
            break; 
        case "RESET_EDIT_ALL_VEH":
            return Object.assign({}, state, {
                editAllVehOperatorStop: false,
                editAllVehFFStop: false,
                editAllVehCarrier: false,
                editAllVehCarrierRemarks: false,
                editAllVehConsignee: false,
                editAllVehFF: false,
                editAllVehCustomsAgentStop: false})
            break; 
        case "INCREMENT_SHIPPING_KEY":
            return Object.assign({}, state, {shippingTableKey: state.shippingTableKey+1})
            break; 
        case "INCREMENT_CONTAINERS_KEY":
            return Object.assign({}, state, {containersTableKey: state.containersTableKey+1})
            break; 
        case "INCREMENT_VEHICLES_KEY":
            return Object.assign({}, state, {vehiclesTableKey: state.vehiclesTableKey+1})
            break; 
        case "COLLAPSE_EXPAND_SEARCH_TAB":
            return Object.assign({}, state, {collapsedSearchTab: action.value})
            break; 
        case "COLLAPSE_EXPAND_DETAILS":
            return Object.assign({}, state, {collapsedDetails: action.value})
            break; 
        case "CHANGE_PASSWORD_ERROR_TEXT":
            return Object.assign({}, state, {changePasswordErrorText: action.changePasswordErrorText})
            break; 
        case "CHANGE_PRE_NOTES_INDEX":
            return Object.assign({}, state, {preNotesIndex: action.preNotesIndex})
            break; 
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer