const initialState = {}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_VEHICLES_FILTER':
                return action.vehicleFilter
        break;
        default:
            return state
    }
}

export default reducer