const initialState = {
    shipping:{
        defaultSorted:[],
        selectAll: false,
        movementDate: true,
        movementTime: true,
        berthTime: true,
        vesselName: true,
        voyageCode: true,
        importsLoaded: true,
        moveType: true,
        fromToPort: true,
        primaryCargo: true,
        agent: true,
        berth: true,
        arriveHarbourDate: false,
        arriveHarbourTime: false,
        comments: false,
        departBerthDate: false,
        departBerthTime: false,
        hasPendingChanges: false,
        isComplete: false,
        launchTimeOnBoard: false,
        lloydsNumber: false,
        sideTo: false,
        trade: false,
        tugs: false,
        tugsTimeOnBoard: false,
        selectAllWidth: 100,
        movementDateWidth: 100,
        movementTimeWidth: 100,
        berthTimeWidth: 100,
        vesselNameWidth: 100,
        voyageCodeWidth: 100,
        importsLoadedWidth: 100,
        moveTypeWidth: 100,
        fromToPortWidth: 100,
        primaryCargoWidth: 100,
        agentWidth: 100,
        berthWidth: 100,
        arriveHarbourDateWidth: 100,
        arriveHarbourTimeWidth: 100,
        commentsWidth: 100,
        departBerthDateWidth: 100,
        departBerthTimeWidth: 100,
        hasPendingChangesWidth: 100,
        isCompleteWidth: 100,
        launchTimeOnBoardWidth: 100,
        lloydsNumberWidth: 100,
        sideToWidth: 100,
        tradeWidth: 100,
        tugsWidth: 100,
        tugsTimeOnBoardWidth: 100,
    },
    shippingColOrder:{
        movementDatePosition: 0,
        movementTimePosition: 1,
        berthTimePosition: 2,
        vesselNamePosition: 3,
        voyageCodePosition: 4,
        importsLoadedPosition: 5,
        moveTypePosition: 6,
        fromToPortPosition: 7,
        primaryCargoPosition: 8,
        agentPosition: 9,
        berthPosition: 10,
        arriveHarbourDatePosition: 11,
        arriveHarbourTimePosition: 12,
        commentsPosition: 13,
        departBerthDatePosition: 14,
        departBerthTimePosition: 15,
        hasPendingChangesPosition: 16,
        isCompletePosition: 17,
        launchTimeOnBoardPosition: 18,
        lloydsNumberPosition: 19,
        sideToPosition: 20,
        tradePosition: 21,
        tugsPosition: 22,
        tugsTimeOnBoardPosition: 23,
    },

    containers:{
        defaultSorted:[],
        selectAll: false,
        freightForwarder:false,
        id: true,
        vessel: true,
        portVoyageCode: true,
        operator: true,
        imexStatus: true,
        hasOperatorStop: false,
        stops: true,
        hasTasks: true,
        hasFreightForwarderStop: false,
        locality: true,
        isoCode: true,
        lengthWidthHeight: true,
        isReefer: true,
        isOverdimensional: true,
        hazardousDescription: true,
        receivedDateTime: true,
        receiveMode: true,
        releasedDateTime:true,
        releaseMode:true,
        lastDayOfFreeStorage: true,
        availableForDeliveryDateTime: false,
        bookingReference: false,
        cedo: false,
        commodity: false,
        consignee:false,
        consignor: false,
        customsEntryNumber: false,
        hasBeenUnpacked: false,
        optimalPickUp: false,
        pin: false,
        carrierRemarks: false,
        receiveCarrier: false,
        receiveVehicle: false,
        releaseCarrier: false,
        releaseVehicle: false,
        seal: false,
        temperature: false,
        loadPort: false,
        dischargePort:false,
        connectedDateTime:false,
        weight:false,
        destinationPort:false,
        isTranshipment:false,
        yardLocation: false,
        isWeightCertified:false,
        freightForwarderWidth:100,
        idWidth: 100,
        vesselWidth: 100,
        portVoyageCodeWidth: 100,
        operatorWidth: 100,
        imexStatusWidth: 100,
        hasOperatorStopWidth: 100,
        stopsWidth: 100,
        hasTasksWidth: 100,
        hasFreightForwarderStopWidth: 100,
        localityWidth: 100,
        isoCodeWidth: 100,
        lengthWidthHeightWidth: 100,
        isReeferWidth: 100,
        isOverdimensionalWidth: 100,
        hazardousDescriptionWidth: 100,
        receivedDateTimeWidth: 100,
        receiveModeWidth: 100,
        releasedDateTimeWidth:100,
        releaseModeWidth:100,
        lastDayOfFreeStorageWidth: 100,
        availableForDeliveryDateTimeWidth: 100,
        bookingReferenceWidth: 100,
        cedoWidth: 100,
        commodityWidth: 100,
        consigneeWidth:100,
        consignorWidth: 100,
        customsEntryNumberWidth: 100,
        hasBeenUnpackedWidth: 100,
        optimalPickUpWidth: 100,
        pinWidth: 100,
        carrierRemarksWidth: 100,
        receiveCarrierWidth: 100,
        receiveVehicleWidth: 100,
        releaseCarrierWidth: 100,
        releaseVehicleWidth: 100,
        sealWidth: 100,
        temperatureWidth: 100,
        loadPortWidth: 100,
        dischargePortWidth:100,
        connectedDateTimeWidth:100,
        weighWidtht:100,
        destinationPorWidtht:100,
        isTranshipmenWidtht:100,
        yardLocationWidth: 100,
        isWeightCertifiedWidth:100,
        },
    containersColOrder:{
        freightForwarderPosition:0,
        idPosition: 1,
        vesselPosition: 2,
        portVoyageCodePosition: 3,
        operatorPosition: 4,
        imexStatusPosition: 5,
        hasOperatorStopPosition: 6,
        stopsPosition: 7,
        hasTasksPosition: 8,
        hasFreightForwarderStopPosition: 9,
        localityPosition: 10,
        isoCodePosition: 11,
        lengthPositionHeightPosition: 12,
        isReeferPosition: 13,
        isOverdimensionalPosition: 14,
        hazardousDescriptionPosition: 15,
        receivedDateTimePosition: 16,
        receiveModePosition: 17,
        releasedDateTimePosition:18,
        releaseModePosition:19,
        lastDayOfFreeStoragePosition: 20,
        availableForDeliveryDateTimePosition: 21,
        bookingReferencePosition: 22,
        cedoPosition: 23,
        commodityPosition: 24,
        consigneePosition:25,
        consignorPosition: 26,
        customsEntryNumberPosition: 27,
        hasBeenUnpackedPosition: 28,
        optimalPickUpPosition: 29,
        pinPosition: 30,
        carrierRemarksPosition: 31,
        receiveCarrierPosition: 32,
        receiveVehiclePosition: 33,
        releaseCarrierPosition: 34,
        releaseVehiclePosition: 35,
        sealPosition: 36,
        temperaturePosition: 37,
        loadPortPosition: 38,
        dischargePortPosition:39,
        connectedDateTimePosition:40,
        weighPositiont:41,
        destinationPorPositiont:42,
        isTranshipmenPositiont:43,
        yardLocationPosition: 44,
        isWeightCertifiedPosition:45,
    },

    vehicles:{
        defaultSorted:[],
        selectAll: false,
        vessel: true,
        oceanBillOfLading: true,
        portVoyageCode: false,
        houseBillOfLading: true,
        operator: false,
        id: true,
        stops: true,
        mafStatus: false,
        hasOperatorStop: false,
        hasFreightForwarderStop: false,
        locality: false,
        hasCustomsAgentStop: false,
        receivedDateTime: true,
        releasedDateTime: false,
        lastDayOfFreeStorage: true,
        availableForDeliveryDateTime: false,
        condition: false,
        consignee: true,
        clientRef: false,
        customsAgent: false,
        description: false,
        customsEntryNumber: false,
        releaseCarrier: false,
        hasRadio: false,
        keyNumber: true,
        locationOnPort: false,
        releaseVehicle: false,
        loadPort: false,
        carrierRemarks: false,
        freightForwarder: true,
        vesselWidth: 100,
        oceanBillOfLadingWidth: 100,
        portVoyageCodeWidth: 100,
        houseBillOfLadingWidth: 100,
        operatorWidth: 100,
        idWidth: 100,
        stopsWidth: 100,
        mafStatusWidth: 100,
        hasOperatorStopWidth: 100,
        hasFreightForwarderStopWidth: 100,
        localityWidth: 100,
        hasCustomsAgentStopWidth: 100,
        receivedDateTimeWidth: 100,
        releasedDateTimeWidth: 100,
        lastDayOfFreeStorageWidth: 100,
        availableForDeliveryDateTimeWidth: 100,
        conditionWidth: 100,
        consigneeWidth: 100,
        clientRefWidth: 100,
        customsAgentWidth: 100,
        descriptionWidth: 100,
        customsEntryNumberWidth: 100,
        releaseCarrierWidth: 100,
        hasRadioWidth: 100,
        keyNumberWidth: 100,
        locationOnPortWidth: 100,
        releaseVehicleWidth: 100,
        loadPortWidth: 100,
        carrierRemarksWidth: 100,
        freightForwarderWidth: 100,
    },
vehiclesColOrder:{
    vesselPosition: 0,
    oceanBillOfLadingPosition: 1,
    portVoyageCodePosition: 2,
    houseBillOfLadingPosition: 3,
    operatorPosition: 4,
    idPosition: 5,
    stopsPosition: 6,
    mafStatusPosition: 7,
    hasOperatorStopPosition: 8,
    hasFreightForwarderStopPosition: 9,
    localityPosition: 10,
    hasCustomsAgentStopPosition: 11,
    receivedDateTimePosition: 12,
    releasedDateTimePosition: 13,
    lastDayOfFreeStoragePosition: 14,
    availableForDeliveryDateTimePosition: 15,
    conditionPosition: 16,
    consigneePosition: 17,
    clientRefPosition: 18,
    customsAgentPosition: 19,
    descriptionPosition: 20,
    customsEntryNumberPosition: 21,
    releaseCarrierPosition: 22,
    hasRadioPosition: 23,
    keyNumberPosition: 24,
    locationOnPortPosition: 25,
    releaseVehiclePosition: 26,
    loadPortPosition: 27,
    carrierRemarksPosition: 28,
    freightForwarderPosition: 29,
}
   
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_ORG_SHIP_COLS":
            return {...state, shipping:{...state.shipping,
                [action.payload.showCol]: action.payload.show,
                [action.payload.widthCol]: action.payload.width,
                // [action.payload.positionName]: action.payload.position
            }}
            break;
        case "CHANGE_ORG_CONT_COLS":
            return {...state, containers:{...state.containers,
                [action.payload.showCol]: action.payload.show,
                [action.payload.widthCol]: action.payload.width
            }}
            break;
        case "CHANGE_ORG_VEH_COLS":
            return {...state, vehicles:{...state.vehicles,
                [action.payload.showCol]: action.payload.show,
                [action.payload.widthCol]: action.payload.width
            }}
            break;
        case "CHANGE_ORG_SHIP_COL_WIDTH":
            return {...state, shipping:{...state.shipping, [action.payload.key]: action.payload.value}}
            break;
        case "CHANGE_ORG_CONT_COL_WIDTH":
            return {...state, containers:{...state.containers, [action.payload.key]: action.payload.value}}
            break;
        case "CHANGE_ORG_VEH_COL_WIDTH":
            return {...state, vehicles:{...state.vehicles, [action.payload.key]: action.payload.value}}
            break;
        case "CHANGE_ORG_SHIP_COL_ITEM":
            return {...state, shipping:{...state.shipping, [action.payload.key]: action.payload.value}}
            break;
        case "CHANGE_ORG_CONT_COL_ITEM":
            return {...state, containers:{...state.containers, [action.payload.key]: action.payload.value}}
            break;
        case "CHANGE_ORG_VEH_COL_ITEM":
            return {...state, vehicles:{...state.vehicles, [action.payload.key]: action.payload.value}}
            break;
        case "CHANGE_ORG_SHIP_CHECKBOX":
            return {...state, shipping:{...state.shipping, [action.payload.key]: action.payload.value}}
            break;
        case "UPDATE_ALL_ORG_SHIP_CHECKBOX":
                return {...state, shipping:{...state.shipping, 
                    movementDate: action.payload.movementDate,
                    movementTime: action.payload.movementTime,
                    berthTime: action.payload.berthTime,
                    vesselName: action.payload.vesselName,
                    voyageCode: action.payload.voyageCode,
                    importsLoaded: action.payload.importsLoaded,
                    moveType: action.payload.moveType,
                    fromToPort: action.payload.fromToPort,
                    primaryCargo: action.payload.primaryCargo,
                    agent: action.payload.agent,
                    berth: action.payload.berth,
                    arriveHarbourDate: action.payload.arriveHarbourDate,
                    arriveHarbourTime: action.payload.arriveHarbourTime,
                    comments: action.payload.comments,
                    departBerthDate: action.payload.departBerthDate,
                    departBerthTime: action.payload.departBerthTime,
                    hasPendingChanges: action.payload.hasPendingChanges,
                    isComplete:action.payload.isComplete,
                    launchTimeOnBoard: action.payload.launchTimeOnBoard,
                    lloydsNumber: action.payload.lloydsNumber,
                    sideTo: action.payload.sideTo,
                    trade: action.payload.trade,
                    tugs: action.payload.tugs,
                    tugsTimeOnBoard: action.payload.tugsTimeOnBoard,
                    selectAll: action.payload.selectAll
                }}
            break;
        case "CHANGE_ORG_CONT_CHECKBOX":
            return {...state, containers:{...state.containers, [action.payload.key]: action.payload.value}}
            break;
        case "UPDATE_ALL_ORG_CONT_CHECKBOX":
            return {...state, containers:{...state.containers, 
                id: action.payload.id,
                vessel: action.payload.vessel,
                portVoyageCode: action.payload.portVoyageCode,
                operator: action.payload.operator,
                imexStatus: action.payload.imexStatus,
                hasOperatorStop: action.payload.hasOperatorStop,
                stops: action.payload.stops,
                hasTasks: action.payload.hasTasks,
                hasFreightForwarderStop: action.payload.hasFreightForwarderStop,
                locality: action.payload.locality,
                isoCode: action.payload.isoCode,
                lengthWidthHeight: action.payload.lengthWidthHeight,
                isReefer: action.payload.isReefer,
                isOverdimensional: action.payload.isOverdimensional,
                hazardousDescription: action.payload.hazardousDescription,
                receivedDateTime: action.payload.receivedDateTime,
                receiveMode: action.payload.receiveMode,
                releasedDateTime:action.payload.releasedDateTime,
                releaseMode: action.payload.releaseMode,
                lastDayOfFreeStorage: action.payload.lastDayOfFreeStorage,
                availableForDeliveryDateTime: action.payload.availableForDeliveryDateTime,
                bookingReference: action.payload.bookingReference,
                cedo: action.payload.cedo,
                commodity: action.payload.commodity,
                consignee: action.payload.consignee,
                consignor: action.payload.consignor,
                customsEntryNumber: action.payload.customsEntryNumber,
                hasBeenUnpacked: action.payload.hasBeenUnpacked,
                optimalPickUp: action.payload.optimalPickUp,
                pin: action.payload.pin,
                carrierRemarks: action.payload.carrierRemarks,
                receiveCarrier: action.payload.receiveCarrier,
                receiveVehicle: action.payload.receiveVehicle,
                releaseCarrier: action.payload.releaseCarrier,
                releaseVehicle: action.payload.releaseVehicle,
                seal: action.payload.seal,
                temperature: action.payload.temperature,
                loadPort: action.payload.loadPort,
                dischargePort: action.payload.dischargePort,
                connectedDateTime: action.payload.connectedDateTime,
                weight: action.payload.weight,
                destinationPort:action.payload.destinationPort,
                isTranshipment: action.payload.isTranshipment,
                yardLocation: action.payload.yardLocation,
                isWeightCertified: action.payload.isWeightCertified,
                freightForwarder:action.payload.freightForwarder,
                selectAll: action.payload.selectAll
            }}
            break;
        case "CHANGE_ORG_VEH_CHECKBOX":
            return {...state, vehicles:{...state.vehicles, [action.payload.key]: action.payload.value}}
            break;
        case "UPDATE_ALL_ORG_VEH_CHECKBOX":
            return {...state, vehicles:{...state.vehicles, 
                vessel: action.payload.vessel,
                oceanBillOfLading: action.payload.oceanBillOfLading,
                portVoyageCode: action.payload.portVoyageCode,
                houseBillOfLading: action.payload.houseBillOfLading,
                operator: action.payload.operator,
                id: action.payload.id,
                stops: action.payload.stops,
                mafStatus: action.payload.mafStatus,
                hasOperatorStop: action.payload.hasOperatorStop,
                hasFreightForwarderStop: action.payload.hasFreightForwarderStop,
                locality: action.payload.locality,
                hasCustomsAgentStop: action.payload.hasCustomsAgentStop,
                receivedDateTime: action.payload.receivedDateTime,
                releasedDateTime: action.payload.releasedDateTime,
                lastDayOfFreeStorage: action.payload.lastDayOfFreeStorage,
                availableForDeliveryDateTime: action.payload.availableForDeliveryDateTime,
                condition: action.payload.condition,
                consignee:action.payload.consignee,
                clientRef: action.payload.clientRef,
                customsAgent: action.payload.customsAgent,
                description: action.payload.description,
                customsEntryNumber: action.payload.customsEntryNumber,
                releaseCarrier: action.payload.releaseCarrier,
                hasRadio: action.payload.hasRadio,
                keyNumber: action.payload.keyNumber,
                locationOnPort: action.payload.locationOnPort,
                releaseVehicle: action.payload.releaseVehicle,
                loadPort: action.payload.loadPort,
                carrierRemarks: action.payload.carrierRemarks,
                freightForwarder: action.payload.freightForwarder,
                selectAll: action.payload.selectAll
            }}
            break;
            case "UPDATE_SHIP_COL_ORDER":
                return {...state, shippingColOrder: action.shippingColOrder}
                break;
            case "UPDATE_CONT_COL_ORDER":
                return {...state, containersColOrder: action.containersColOrder}
                break;
            case "UPDATE_VEH_COL_ORDER":
                return {...state, vehiclesColOrder: action.vehiclesColOrder}
                break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer