import React from 'react'
import { connect } from "react-redux"
import request from 'superagent'
import { portsLookup, updateVessels, updatePrimaryCargoTypes } from "../actions"
import { apiCall } from "../../config"
import { searchShipping } from "../actions/search"
import ShippingSearch from './searchTabs/shippingSearchComponents/ShippingSearch'
import ContainersSearch from "./searchTabs/ContainersSearch"
import VehiclesSearch from "./searchTabs/VehiclesSearch"
import CollapsedSearch from "./searchTabs/CollapsedSearch"
import HeaderBar from "./header/HeaderBar"
import SearchTabs from "./searchTabs/SearchTabs"
import ShippingTable from "./tables/ShippingTable"
import ContainersTable from "./tables/ContainersTable"
import VehiclesTable from "./tables/VehiclesTable"
import DetailsSubTab from './detailsTabs/DetailsSubTabs'

class MainContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            vesselsList: [],
            vesselTypesList: [],
            primaryCargoTypesList:[]
        }
    }
    componentDidMount(){
        console.log("main container CDM")
        this.props.dispatch(portsLookup())
        this.getVessels()
        this.getVesselTypes()
        this.getPrimaryCargoTypes()

        this.props.visibleTab === "Shipping" && this.props.dispatch(searchShipping())

      }

    cursorStyle = {
        cursor: this.props.cursor,
    }

    getVessels(){
     request.get(apiCall + "/vessels?CentricRestService")
    .then(res => {
        res.body.objectFlyweightArray && this.setState({vesselsList: res.body.objectFlyweightArray})
    })
    .catch(err=>alert("Vessels list failed to load"))
    }

    getVesselTypes(){
        request.get(apiCall + "/vessel_types?CentricRestService")
       .then(res => {
           res.body.objectFlyweightArray && this.setState({vesselTypesList: res.body.objectFlyweightArray})

       })
       .catch(err=>alert("Vessel Type list failed to load"))
   }

   getPrimaryCargoTypes(){
    request.get(apiCall + "/primary_cargo_types?CentricRestService")
   .then(res => {
       res.body.objectFlyweightArray && this.setState({primaryCargoTypesList: res.body.objectFlyweightArray})

   })
   .catch(err=>alert("Primary Cargo Types list failed to load"))
}

renderSearchTab = ()=>{
    if(this.props.collapsedSearchTab === true){
        return <CollapsedSearch/>
    } else if(this.props.visibleTab==="Shipping"){
        return <ShippingSearch vesselsList={this.state.vesselsList} vesselTypesList={this.state.vesselTypesList} primaryCargoTypesList={this.state.primaryCargoTypesList}/>
    } else if(this.props.visibleTab==="Containers"){
        return <ContainersSearch/>
    } else if(this.props.visibleTab==="Vehicles"){
        return <VehiclesSearch/>
    } else{
        return ""
    }
}


renderTables = ()=>{
    if(this.props.visibleTab==="Shipping"){
        return <ShippingTable/>
    } else if(this.props.visibleTab==="Containers"){
        return <ContainersTable/>
    } else if(this.props.visibleTab==="Vehicles"){
        return <VehiclesTable/>
    } else{
        return ""
    }
}
    render() { 
        return ( 
            <React.Fragment>
            <div 
            //style={{cursor:this.props.cursor}}
            className={this.props.cursorLoading?"loading-cursor":"default-cursor"}
            >
            <div id="header-container">
                <HeaderBar/>
            </div>
            <div id="body-container">
              <div id ={this.props.collapsedSearchTab?"collapsed-search-container":"search-container"}>
              {!this.props.collapsedSearchTab && <img id="expanded-arrow" src="./images/chevron-circle-left.svg" onClick={()=>this.props.dispatch({type:"COLLAPSE_EXPAND_SEARCH_TAB", value:true})}/>}
                {!this.props.collapsedSearchTab && <SearchTabs/>}
                {this.renderSearchTab()}
              </div>
              <div id="table-details-container">
                    {this.renderTables()}
                    <DetailsSubTab/>
              </div>
          </div>    
          </div>
          </React.Fragment>
         );
    }
}

function mapStatetoProps(state){
return{
    cursorLoading: state.loading.cursorLoading,
    visibleTab: state.visibleComponents.visibleTab,
    collapsedSearchTab: state.visibleComponents.collapsedSearchTab
}
}

export default connect(mapStatetoProps)(MainContainer);