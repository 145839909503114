import React from "react"
import ReactTable from "react-table-6"
import { connect } from "react-redux"
import withDraggableColumns from 'react-table-hoc-draggable-columns'
import { ExportToCsv } from 'export-to-csv'
import { formatEpochDate, formatEpochDateTime, convertTableDataForCSV} from "../../utils"
import { setCargoTransactions, sendContainerUpdate } from "../../actions"
import { debounce } from "lodash"
import ContainersTableHeader from "../header/tableHeaderComponents/ContainersTableHeader"

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);

class ContainersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
         // postArray: [],
          colOrder: {}
         }
         this.reactTableContainers = React.createRef();

    }

    componentDidMount= ()=>{
      //Update column order when rendered. Column order not directly linked to store as causes issues with draggable component.
    this.setState({colOrder: this.props.colOrder})
    console.log("CDM containers table")

    }


    handleWidthChange = debounce((newSizes) =>{
      newSizes.map((item)=>{
        this.props.dispatch({type:"CHANGE_ORG_CONT_COL_WIDTH", payload:{key:item.id+"Width", value:item.value}})
      })
    },500)

    handleSaveArray = (cellIndex)=>{
    if(!this.props.containersSaveArray.includes(cellIndex)){
      this.props.dispatch({type:"UPDATE_CONTAINERS_SAVE_ARRAY", index:cellIndex})
      !this.props.containersSaveButton && this.setContainersSaveButton(true)
      console.log(this.props.containersSaveArray)
  }
    }
    

    setContainersSaveButton = (value)=>{
      this.props.dispatch({type: "CHANGE_CONTAINERS_SAVE_BUTTON", containersSaveButton: value})
    }


      renderFreightForwarderCell = (cellInfo)=>{
        return(
          <select onChange={(event)=> this.handleFreightForwarderCellChange(cellInfo.index, event)} value={cellInfo.original.freightForwarderObjectString}>
            <option value=""/>
            {this.props.freightForwarders.map((item,i)=>{
                    return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                    })}
          </select>
        )
      }

      handleFreightForwarderCellChange = (cellIndex, event) =>{
        this.props.dispatch({type:"UPDATE_CONTAINERS_RETURN_FF_OBJ_STR" ,payload:{index: cellIndex, freightForwarderObjectString:event.target.value}})
        this.handleSaveArray(cellIndex)

      }

      renderOperatorStopCell = (cellInfo)=>{
        return  <input type="checkbox" checked={cellInfo.value} onChange={()=>this.handleOperatorStopChange(cellInfo)}/>

      }

      handleOperatorStopChange = (cellInfo)=>{
        this.props.dispatch({type:"UPDATE_CONTAINERS_RETURN_OPERATOR_STOP" ,payload:{index: cellInfo.index, value:!cellInfo.value}})
      this.handleSaveArray(cellInfo.index)
      }

      renderFreightForwarderStopCell = (cellInfo)=>{
        return  <input type="checkbox" checked={cellInfo.value} onChange={()=>this.handleFreightForwarderStopChange(cellInfo)}/>

      }

      handleFreightForwarderStopChange = (cellInfo)=>{
        this.props.dispatch({type:"UPDATE_CONTAINERS_RETURN_F_F_STOP" ,payload:{index: cellInfo.index, value:!cellInfo.value}})
      this.handleSaveArray(cellInfo.index)
      }

      handleCarrierRemarksChange = (cellIndex, event)=>{
        this.props.dispatch({type:"UPDATE_CONTAINERS_RETURN_CARRIER_REMARKS" ,payload:{index: cellIndex, value:event.target.value}})
      this.handleSaveArray(cellIndex)
      }
      
      renderCarrierRemarksCell = (cellInfo)=>{
        if(this.props.containersReturn[cellInfo.index].canUpdateCarrierRemarks === true){
          return (
            <input type="text" 
            value={this.props.containersReturn[cellInfo.index].carrierRemarks}
            onChange={(event)=>this.handleCarrierRemarksChange(cellInfo.index, event)}
            />
            )
          } else {
            return cellInfo.value
          }
        }
        renderReleaseCarrierCell = (cellInfo)=>{
          return(
            <select onChange={(event)=> this.handleReleaseCarrierCellChange(cellInfo.index, event)} value={cellInfo.original.releaseCarrierObjectString}>
              <option value=""/>
              {this.props.containerCarriers.map((item,i)=>{
                      return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                      })}
            </select>
          )
        }
  
        handleReleaseCarrierCellChange = (cellIndex, event) =>{
          this.props.dispatch({type:"UPDATE_CONTAINERS_RETURN_CARRIER_OBJ_STR" ,payload:{index: cellIndex, releaseCarrierObjectString:event.target.value}})
          this.handleSaveArray(cellIndex)
  
        }

        renderConsigneesCell = (cellInfo)=>{
          return(
            <select onChange={(event)=> this.handleConsigneesCellChange(cellInfo.index, event)} value={cellInfo.original.consigneeObjectString}>
              <option value=""/>
              {this.props.consignees.map((item,i)=>{
                      return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                      })}
            </select>
          )
        }
    
        handleConsigneesCellChange = (cellIndex, event) =>{
          this.props.dispatch({type:"UPDATE_CONTAINERS_RETURN_CONSIGNEE_OBJ_STR" ,payload:{index: cellIndex, consigneeObjectString:event.target.value}})
          this.handleSaveArray(cellIndex)
    
        }
        
        handleExport = ()=>{
          if(this.props.containersReturn.length < 1){
            alert("There is no data to export")
          } else{

            
            const tableData = this.reactTableContainers.wrappedInstance.getResolvedState().sortedData;
            const visibleCols = this.reactTableContainers.wrappedInstance.getResolvedState().allVisibleColumns;
            var csvData = convertTableDataForCSV(tableData, visibleCols)
            
            
            const options = { 
              filename: "centric_containers",
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true, 
              showTitle: false,
              title: "",
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: false,
            };
            
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(csvData);
            }
          }
     
        render() { 


        const columns = [
          {
          Header: 'Freight Forwarder',
          accessor: 'freightForwarder',
          width: this.props.show.freightForwarderWidth,
          position: this.state.colOrder.freightForwarderPosition,
          show: this.props.show.freightForwarder,
          Cell: (cellInfo) => {
            if(cellInfo.original.canUpdateFreightForwarder === true && this.props.isLoggedIn === true && this.props.freightForwarders.length>0){
              return this.renderFreightForwarderCell(cellInfo)
            } else {
              return cellInfo.value
            }
          }
        },{
          Header: 'ID',
          accessor: 'id',
          width: this.props.show.idWidth,
          position: this.state.colOrder.idPosition,
          show: this.props.show.id   
        },{
          Header: 'Vessel',
          accessor: 'vessel',
          width: this.props.show.vesselWidth,
          position: this.state.colOrder.vesselPosition,
          show: this.props.show.vessel     
        },{
          Header: 'Voyage Code',
          accessor: 'portVoyageCode',
          width: this.props.show.portVoyageCodeWidth,
          position: this.state.colOrder.portVoyageCodePosition,
          show: this.props.show.portVoyageCode
       },{
          Header: 'Operator',
          accessor: 'operator',
          width: this.props.show.operatorWidth,
          position: this.state.colOrder.operatorPosition,
          show: this.props.show.operator     
        },{
          Header: 'IMEX Status',
          accessor: 'imexStatus',
          width: this.props.show.imexStatusWidth,
          position: this.state.colOrder.imexStatusPosition,
          show: this.props.show.imexStatus
        },{
          Header: 'Operator Stop',
          accessor: 'hasOperatorStop',
          width: this.props.show.hasOperatorStopWidth,
          position: this.state.colOrder.hasOperatorStopPosition,
          show: this.props.show.hasOperatorStop,
              Cell: (cellInfo) => {
            if(cellInfo.original.canUpdateOperatorStop === true && this.props.isLoggedIn === true){
              return this.renderOperatorStopCell(cellInfo)
            } else {
              return cellInfo.value?"Yes":"No"
            }
          }
        }, {
          Header: 'Stops',
          accessor: 'stops',
          width: this.props.show.stopsWidth,
          position: this.state.colOrder.stopsPosition,
          show: this.props.show.stops
        }, {
          Header: 'Has Tasks',
          accessor: 'hasTasks',
          show: this.props.show.hasTasks,
          width: this.props.show.hasTasksWidth,
          position: this.state.colOrder.hasTasksPosition,
          Cell: cellInfo => cellInfo.value? "Yes" : "No"
        },{
          Header: 'Freight Forwarder Stop',
          accessor: 'hasFreightForwarderStop',
          width: this.props.show.hasFreightForwarderStopWidth,
          position: this.state.colOrder.hasFreightForwarderStopPosition,
          show: this.props.show.hasFreightForwarderStop,
          Cell: (cellInfo) => {
            if(cellInfo.original.canUpdateFreightForwarderStop === true && this.props.isLoggedIn === true){
              return this.renderFreightForwarderStopCell(cellInfo)
            } else {
              return cellInfo.value?"Yes":"No"
            }
          }
        },{
          Header: 'Locality',
          accessor: 'locality',
          width: this.props.show.localityWidth,
          position: this.state.colOrder.localityPosition,
          show: this.props.show.locality,
        }, {
          Header: 'ISO Code',
          accessor: 'isoCode',
          width: this.props.show.isoCodeWidth,
          position: this.state.colOrder.isoCodePosition,
          show: this.props.show.isoCode
        }, {
          Header: 'Length/Width/Height',
          accessor: 'lengthWidthHeight',
          width: this.props.show.lengthWidthHeightWidth,
          position: this.state.colOrder.lengthWidthHeightPosition,
          show: this.props.show.lengthWidthHeight
        }, {
          Header: 'Is Reefer',
          accessor: 'isReefer',
          width: this.props.show.isReeferWidth,
          position: this.state.colOrder.isReeferPosition,
          show: this.props.show.isReefer,
          Cell: cellInfo => cellInfo.value? "Yes" : "No"
        }, {
          Header: 'Is Overdimensional',
          accessor: 'isOverdimensional',
          width: this.props.show.isOverdimensionalWidth,
          position: this.state.colOrder.isOverdimensionalPosition,
          show: this.props.show.isOverdimensional,
          Cell: cellInfo => cellInfo.value? "Yes" : "No"
        }, {
          Header: 'Hazardous Description',
          accessor: 'hazardousDescription',
          width: this.props.show.hazardousDescriptionWidth,
          position: this.state.colOrder.hazardousDescriptionPosition,
          show: this.props.show.hazardousDescription
        },{
          Header: 'Receive Date',
          accessor: 'receivedDateTime',
          width: this.props.show.receivedDateTimeWidth,
          position: this.state.colOrder.receivedDateTimePosition,
          show: this.props.show.receivedDateTime,
          Cell: cellInfo => formatEpochDateTime(cellInfo.value)
        }, {
          Header: 'Receive Mode',
          accessor: 'receiveMode',
          width: this.props.show.receiveModeWidth,
          position: this.state.colOrder.receiveModePosition,
          show: this.props.show.receiveMode
        },{
          Header: 'Release Date',
          accessor: 'releasedDateTime',
          width: this.props.show.releasedDateTimeWidth,
          position: this.state.colOrder.releasedDateTimePosition,
          show: this.props.show.releasedDateTime,
          Cell: cellInfo => formatEpochDateTime(cellInfo.value)

        }, {
          Header: 'Release Mode',
          accessor: 'releaseMode',
          width: this.props.show.releaseModeWidth,
          position: this.state.colOrder.releaseModePosition,
          show: this.props.show.releaseMode
        }, {
          Header: 'Last Day Of Free Storage',
          accessor: 'lastDayOfFreeStorage',
          width: this.props.show.lastDayOfFreeStorageWidth,
          position: this.state.colOrder.lastDayOfFreeStoragePosition,
          show: this.props.show.lastDayOfFreeStorage,
          Cell: cellInfo => formatEpochDate(cellInfo.value)
        },{
          Header: 'Available For Delivery Date',
          accessor: 'availableForDeliveryDateTime',
          width: this.props.show.availableForDeliveryDateTimeWidth,
          position: this.state.colOrder.availableForDeliveryDateTimePosition,
          show: this.props.show.availableForDeliveryDateTime,
          Cell: cellInfo => formatEpochDateTime(cellInfo.value)

        },{
          Header: 'Booking Reference',
          accessor: 'bookingReference',
          width: this.props.show.bookingReferenceWidth,
          position: this.state.colOrder.bookingReferencePosition,
          show: this.props.show.bookingReference
        },{
          Header: 'CEDO',
          accessor: 'cedo',
          width: this.props.show.cedoWidth,
          position: this.state.colOrder.cedoPosition,
          show: this.props.show.cedo
        },{
          Header: 'Commodity',
          accessor: 'commodity',
          width: this.props.show.commodityWidth,
          position: this.state.colOrder.commodityPosition,
          show: this.props.show.commodity
        },{
          Header: 'Consignee',
          accessor: 'consignee',
          width: this.props.show.consigneeWidth,
          position: this.state.colOrder.consigneePosition,
          show:this.props.show.consignee,
          Cell: (cellInfo) => {
            if(cellInfo.original.canUpdateConsignee === true && this.props.isLoggedIn === true && this.props.consignees.length>0){
              return this.renderConsigneesCell(cellInfo)
            } else {
              return cellInfo.value
            }
          }
        },{
          Header: 'Consignor',
          accessor: 'consignor',
          width: this.props.show.consignorWidth,
          position: this.state.colOrder.consignorPosition,
          show: this.props.show.consignor
        },{
          Header: 'Customs Entry Number',
          accessor: 'customsEntryNumber',
          width: this.props.show.customsEntryNumberWidth,
          position: this.state.colOrder.customsEntryNumberPosition,
          show: this.props.show.customsEntryNumber
        },{
          Header: 'Has Been Unpacked',
          accessor: 'hasBeenUnpacked',
          width: this.props.show.hasBeenUnpackedWidth,
          position: this.state.colOrder.hasBeenUnpackedPosition,
          show: this.props.show.hasBeenUnpacked,
          Cell: cellInfo => cellInfo.value? "Yes" : "No"

        },{
          Header: 'Optimal Pick Up',
          accessor: 'optimalPickUp',
          width: this.props.show.optimalPickUpWidth,
          position: this.state.colOrder.optimalPickUpPosition,
          show: this.props.show.optimalPickUp
        },{
          Header: 'PIN',
          accessor: 'pin',
          width: this.props.show.pinWidth,
          position: this.state.colOrder.pinPosition,
          show: this.props.show.pin
        },{
          Header: 'Carrier Remarks',
          accessor: 'carrierRemarks',
          width: this.props.show.carrierRemarksWidth,
          position: this.state.colOrder.carrierRemarksPosition,
          show: this.props.show.carrierRemarks,
          Cell: (cellInfo)=>cellInfo.original.canUpdateCarrierRemarks && this.props.isLoggedIn? this.renderCarrierRemarksCell(cellInfo): cellInfo.value
        },{
          Header: 'Receive Carrier',
          accessor: 'receiveCarrier',
          width: this.props.show.receiveCarrierWidth,
          position: this.state.colOrder.receiveCarrierPosition,
          show: this.props.show.receiveCarrier
        },{
          Header: 'Receive Vehicle',
          accessor: 'receiveVehicle',
          width: this.props.show.receiveVehicleWidth,
          position: this.state.colOrder.receiveVehiclePosition,
          show: this.props.show.receiveVehicle
        },{
          Header: 'Release Carrier',
          accessor: 'releaseCarrier',
          width: this.props.show.releaseCarrierWidth,
          position: this.state.colOrder.releaseCarrierPosition,
          show:this.props.show.releaseCarrier,
          Cell: (cellInfo) => {
            if(cellInfo.original.canUpdateCarrier === true && this.props.isLoggedIn === true && this.props.containerCarriers.length>0){
              return this.renderReleaseCarrierCell(cellInfo)
            } else {
              return cellInfo.value
            }
          }
        },{
          Header: 'Release Vehicle',
          accessor: 'releaseVehicle',
          width: this.props.show.releaseVehicleWidth,
          position: this.state.colOrder.releaseVehiclePosition,
          show: this.props.show.releaseVehicle
        },{
          Header: 'Seal',
          accessor: 'seal',
          width: this.props.show.sealWidth,
          position: this.state.colOrder.sealPosition,
          show: this.props.show.seal
        },{
          Header: 'Temperature',
          accessor: 'temperature',
          width: this.props.show.temperatureWidth,
          position: this.state.colOrder.temperaturePosition,
          show: this.props.show.temperature
        },{
          Header: 'Load Port',
          accessor: 'loadPort',
          width: this.props.show.loadPortWidth,
          position: this.state.colOrder.loadPortPosition,
          show: this.props.show.loadPort
        },{
          Header: 'Discharge Port',
          accessor: 'dischargePort',
          width: this.props.show.dischargePortWidth,
          position: this.state.colOrder.dischargePortPosition,
          show: this.props.show.dischargePort
        },{
          Header: 'Connected Date',
          accessor: 'connectedDateTime',
          width: this.props.show.connectedDateTimeWidth,
          position: this.state.colOrder.connectedDateTimePosition,
          show: this.props.show.connectedDateTime,
          Cell: cellInfo => formatEpochDateTime(cellInfo.value)
        },{
          Header: 'Weight',
          accessor: 'weight',
          width: this.props.show.weightWidth,
          position: this.state.colOrder.weightPosition,
          show: this.props.show.weight,
          Cell: cellInfo => cellInfo.value && cellInfo.value.toString()

        },{
          Header: 'Destination Port',
          accessor: 'destinationPort',
          width: this.props.show.destinationPortWidth,
          position: this.state.colOrder.destinationPortPosition,
          show: this.props.show.destinationPort
        },{
          Header: 'Is Transhipment',
          accessor: 'isTranshipment',
          width: this.props.show.isTranshipmentWidth,
          position: this.state.colOrder.isTranshipmentPosition,
          show: this.props.show.isTranshipment,
          Cell: cellInfo => cellInfo.value? "Yes" : "No"

        },{
          Header: 'Yard Location',
          accessor: 'yardLocation',
          width: this.props.show.yardLocationWidth,
          position: this.state.colOrder.yardLocationPosition,
          show: this.props.show.yardLocation
        },{
          Header: 'VGM',
          accessor: 'isWeightCertified',
          width: this.props.show.isWeightCertifiedWidth,
          position: this.state.colOrder.isWeightCertifiedPosition,
          show: this.props.show.isWeightCertified,
          Cell: cellInfo => cellInfo.value? "Yes" : "No"

        },
      ]

      const orderedCols = [...columns].sort((function (a, b) {
        return a.position - b.position;
      }))

      const draggableList = columns.map((item)=>{
        return item.accessor
      })

      return ( 
        <React.Fragment>
        {/* {console.log(draggableList)} */}
             <div className="table-header-container">

              <ContainersTableHeader handleExport={this.handleExport}/>
            </div>
            <div id="react-table-container">
              <ReactTableDraggableColumns
                  draggableColumns= {{
                    mode: 'reorder',
                    draggable: draggableList,
                    onDraggedColumnChange: function(columns){
                      console.log(columns)
                    }
                  }}
                  data={this.props.containersReturn}
                  columns={orderedCols}
                  defaultSorted={this.props.defaultSorted}
                  defaultPageSize={20}
                  ref={(r)=>this.reactTableContainers=r}
                  style={{
                    height: this.props.collapsedDetails?"calc(100vh - 175px)":"450px",
                    fontSize: "0.8em",
                    
                  }}
                  noDataText=""
                  getTrProps={(state, rowInfo, column) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e, t) => {
                          this.props.dispatch({type: "UPDATE_CONTAINERS_DETAILS", containersDetails: this.props.containersReturn[rowInfo.index]})
                          this.props.dispatch({type:"SET_SELECTED_ROW", payload:{key:"containersTableSelectedRow", value:rowInfo.index}})
                          this.props.isLoggedIn && this.props.dispatch(setCargoTransactions(this.props.containersReturn[rowInfo.index].cargoItemObjectString, "UPDATE_CONTAINERS_TRANSACTIONS"))
                        }
                        ,
                        style: {
                          background: rowInfo.index === this.props.containersTableSelectedRow? "rgb(50, 135, 214, 0.25)": null
                        }}
                      }else{
                        return {}
                      }
                    }
                  }
                    getTheadTrProps = {(info)=>{
                      return {
                        onMouseLeave: (e,t)=>{
                          var colOrder = info.columns.reduce(
                            (obj, item, index) => Object.assign(obj, { [item.accessor +"Position"]: index }), {});
                            // var colName = info.columns.reduce(
                            //   (obj, item, index) => Object.assign(obj, { [item.Header.props.children]: index }), {});
                      
                              //console.log(colOrder)
                              this.props.dispatch({type:"UPDATE_CONT_COL_ORDER", containersColOrder:colOrder})
                              if(
                                colOrder !== this.props.colOrder
                                ){
                                // console.log(colOrder)
                                // console.log(this.props.colOrder)
                              }
                              
                        }
                      }
                    }}
                  className="-striped -highlight"
                  onResizedChange={(newResized, event) => {
                    this.handleWidthChange(newResized)
                  }}
                  onSortedChange={(newSorted, column, shiftKey) => {
                    this.props.dispatch({type:"CHANGE_ORG_CONT_COL_ITEM", payload:{key:"defaultSorted", value:newSorted}})
                    console.log("sorted")
                  }}
          />
            {/* <ReactTable
                    data={this.props.containersReturn}
                    settings={{}}
                    columns={columns}
                    defaultSorted={this.props.defaultSorted}
                    defaultPageSize={20}
                    ref={(r)=>this.reactTableContainers=r}
                    style={{
                      height: "450px",
                      fontSize: "0.8em"
                    }}
                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e, t) => {
                            this.props.dispatch({type: "UPDATE_CONTAINERS_DETAILS", containersDetails: this.props.containersReturn[rowInfo.index]})
                            this.props.dispatch({type:"SET_SELECTED_ROW", payload:{key:"containersTableSelectedRow", value:rowInfo.index}})
                            this.props.isLoggedIn && this.props.dispatch(setCargoTransactions(this.props.containersReturn[rowInfo.index].cargoItemObjectString, "UPDATE_CONTAINERS_TRANSACTIONS"))
                          }
                          ,
                          style: {
                            background: rowInfo.index === this.props.containersTableSelectedRow? "rgb(50, 135, 214, 0.25)": null
                          }}
                        }else{
                          return {}
                        }
                      }
                    }


                    className="-striped -highlight"
                    onResizedChange={(newResized, event) => {
                      this.handleWidthChange(newResized)
                    }}
                    onSortedChange={(newSorted, column, shiftKey) => {
                      this.props.dispatch({type:"CHANGE_ORG_CONT_COL_ITEM", payload:{key:"defaultSorted", value:newSorted}})
                    }}
            /> */}
        </div>
        </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
        containersReturn: state.containersReturn,
        userInfo: state.userInfo,
        isLoggedIn: state.visibleComponents.isLoggedIn,
        containersTableSelectedRow: state.visibleComponents.containersTableSelectedRow,
        containersSaveButton: state.visibleComponents.containersSaveButton,
        freightForwarders: state.dropdowns.freightForwarders,
        show: state.organiseTable.containers,
        colOrder: state.organiseTable.containersColOrder,
        containerCarriers: state.dropdowns.containerCarriers,
        consignees: state.dropdowns.consignees,
        containersSaveArray: state.visibleComponents.containersSaveArray,
        defaultSorted: state.organiseTable.containers.defaultSorted,
        csvExport: state.csvExport,
        collapsedDetails: state.visibleComponents.collapsedDetails,
        //need key to trigger refresh of table-draggable component. key is changed on loading of settings/login
        key: state.visibleComponents.containersTableKey


    }
  }  

export default connect(mapStateToProps)(ContainersTable); 