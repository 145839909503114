import React from "react"
import ReactTable from "react-table-6"
import { connect } from "react-redux"
import withDraggableColumns from 'react-table-hoc-draggable-columns'
import { ExportToCsv } from 'export-to-csv'
import { formatEpochDate, formatEpochDateTime, convertTableDataForCSV } from "../../utils"
import { setCargoTransactions, sendContainerUpdate } from "../../actions"
import { debounce } from "lodash"
import VehiclesTableHeader from "../header/tableHeaderComponents/VehiclesTableHeader"

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);

class VehiclesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          colOrder: {}
         }
         this.reactTableVehicles = React.createRef();

    }
    
    componentDidMount= ()=>{
      //Update column order when rendered. Column order not directly linked to store as causes issues with draggable component.
    this.setState({colOrder: this.props.colOrder})
    console.log("CDM vehicles table")

    }



    renderConsigneesCell = (cellInfo)=>{
      return(
        <select onChange={(event)=> this.handleConsigneesCellChange(cellInfo.index, event)} value={cellInfo.original.consigneeObjectString}>
          <option value=""/>
          {this.props.consignees.map((item,i)=>{
                  return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                  })}
        </select>
      )
    }

    handleConsigneesCellChange = (cellIndex, event) =>{
      this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_CONSIGNEE_OBJ_STR" ,payload:{index: cellIndex, consigneeObjectString:event.target.value}})
      this.handleSaveArray(cellIndex)

    }

    renderFreightForwarderCell = (cellInfo)=>{
      return(
        <select onChange={(event)=> this.handleFreightForwarderCellChange(cellInfo.index, event)} value={cellInfo.original.freightForwarderObjectString}>
          <option value=""/>
          {this.props.freightForwarders.map((item,i)=>{
                  return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                  })}
        </select>
      )
    }

    handleFreightForwarderCellChange = (cellIndex, event) =>{
      this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_FF_OBJ_STR" ,payload:{index: cellIndex, freightForwarderObjectString:event.target.value}})
      this.handleSaveArray(cellIndex)

    }


    renderFreightForwarderStopCell = (cellInfo)=>{
      return  <input type="checkbox" checked={cellInfo.value} onChange={()=>this.handleFreightForwarderStopChange(cellInfo)}/>

    }

    handleFreightForwarderStopChange = (cellInfo)=>{
      this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_F_F_STOP" ,payload:{index: cellInfo.index, value:!cellInfo.value}})
      this.handleSaveArray(cellInfo.index)
    }

      renderOperatorStopCell = (cellInfo)=>{
        return  <input type="checkbox" checked={cellInfo.value} onChange={()=>this.handleOperatorStopChange(cellInfo)}/>

      }

      handleOperatorStopChange = (cellInfo)=>{
        this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_OPERATOR_STOP" ,payload:{index: cellInfo.index, value:!cellInfo.value}})
        this.handleSaveArray(cellInfo.index)
      }

      renderCustomsAgentStopCell = (cellInfo)=>{
        return  <input type="checkbox" checked={cellInfo.value} onChange={()=>this.handleCustomsAgentStopChange(cellInfo)}/>

      }

      handleCustomsAgentStopChange = (cellInfo)=>{
        this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_CUSTOMS_AGENT_STOP" ,payload:{index: cellInfo.index, value:!cellInfo.value}})
        this.handleSaveArray(cellInfo.index)
      }

      
      renderCarrierRemarksCell = (cellInfo)=>{
        if(this.props.vehiclesReturn[cellInfo.index].canUpdateCarrierRemarks === true){
          return (
            <input type="text" 
            value={this.props.vehiclesReturn[cellInfo.index].carrierRemarks}
            onChange={(event)=>this.handleCarrierRemarksChange(cellInfo.index, event)}
            />
            )
          } else {
            return cellInfo.value
          }
        }
        
        handleCarrierRemarksChange = (cellIndex, event)=>{
          this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_CARRIER_REMARKS" ,payload:{index: cellIndex, value:event.target.value}})
          this.handleSaveArray(cellIndex)
        }

        renderReleaseCarrierCell = (cellInfo)=>{
          return(
            <select onChange={(event)=> this.handleReleaseCarrierCellChange(cellInfo.index, event)} value={cellInfo.original.releaseCarrierObjectString}>
              <option value=""/>
              {this.props.carCarriers.map((item,i)=>{
                      return(<option key={i} value={item.objectString}>{item.displayAttribute}</option>)
                      })}
            </select>
          )
        }
  
        handleReleaseCarrierCellChange = (cellIndex, event) =>{
          this.props.dispatch({type:"UPDATE_VEHICLES_RETURN_CARRIER_OBJ_STR" ,payload:{index: cellIndex, releaseCarrierObjectString:event.target.value}})
          this.handleSaveArray(cellIndex)
  
        }

        setVehiclesSaveButton = (value)=>{
          this.props.dispatch({type: "CHANGE_VEHICLES_SAVE_BUTTON", vehiclesSaveButton: value})
        }

        handleSaveArray = (cellIndex)=>{
        if(!this.props.vehiclesSaveArray.includes(cellIndex)){
          this.props.dispatch({type:"UPDATE_VEHICLES_SAVE_ARRAY", index:cellIndex})
          !this.props.vehiclesSaveButton && this.setVehiclesSaveButton(true)
          console.log(this.props.vehiclesSaveArray)
      }
        }

        handleWidthChange = debounce((newSizes) =>{
          newSizes.map((item)=>{
            this.props.dispatch({type:"CHANGE_ORG_VEH_COL_WIDTH", payload:{key:item.id+"Width", value:item.value}})
          })
        },500)

        handleExport = ()=>{
          if(this.props.vehiclesReturn.length < 1){
            alert("There is no data to export")
          } else{

            
            const tableData = this.reactTableVehicles.wrappedInstance.getResolvedState().sortedData;
            const visibleCols = this.reactTableVehicles.wrappedInstance.getResolvedState().allVisibleColumns;
            var csvData = convertTableDataForCSV(tableData, visibleCols)
            
            
            const options = { 
              filename: "centric_vehicles",
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true, 
              showTitle: false,
              title: "",
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: false,
            };
            
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(csvData);
            }
          }

        render() { 
        const columns = [{
            Header: 'Vessel',
            accessor: 'vessel',
            width: this.props.show.vesselWidth,
            position: this.state.colOrder.vesselPosition,
            show:this.props.show.vessel

          } ,{
            Header: 'Ocean Bill',
            accessor: 'oceanBillOfLading',
            width: this.props.show.oceanBillOfLadingWidth,
            position: this.state.colOrder.oceanBillOfLadingPosition,
            show:this.props.show.oceanBillOfLading

          } ,{
            Header: 'Voyage Code',
            accessor: 'portVoyageCode',
            width: this.props.show.portVoyageCodeWidth,
            position: this.state.colOrder.portVoyageCodePosition,
            show:this.props.show.portVoyageCode
          },{
            Header: 'House Bill',
            accessor: 'houseBillOfLading',
            width: this.props.show.houseBillOfLadingWidth,
            position: this.state.colOrder.houseBillOfLadingPosition,
            show:this.props.show.houseBillOfLading

          } , {
            Header: 'Operator',
            accessor: 'operator',
            width: this.props.show.operatorWidth,
            position: this.state.colOrder.operatorPosition,
            show:this.props.show.operator
          }, {
            Header: 'VIN',
            accessor: 'id',
            width: this.props.show.idWidth,
            position: this.state.colOrder.idPosition,
            show:this.props.show.id
            
          }, {
            Header: 'Stops',
            accessor: 'stops',
            width: this.props.show.stopsWidth,
            position: this.state.colOrder.stopsPosition,
            show:this.props.show.stops
          }, {
            Header: 'MPI Status',
            accessor: 'mafStatus',
            width: this.props.show.mafStatusWidth,
            position: this.state.colOrder.mafStatusPosition,
            show:this.props.show.mafStatus
          }, {
            Header: 'Operator Stop',
            accessor: 'hasOperatorStop',
            width: this.props.show.hasOperatorStopWidth,
            position: this.state.colOrder.hasOperatorStopPosition,
            show:this.props.show.hasOperatorStop,
                Cell: (cellInfo) => {
              if(cellInfo.original.canUpdateOperatorStop === true && this.props.isLoggedIn === true){
                return this.renderOperatorStopCell(cellInfo)
              } else {
                return cellInfo.value?"Yes":"No"
              }
            }
          },{
            Header: 'Freight Forwarder Stop',
            accessor: 'hasFreightForwarderStop',
            width: this.props.show.hasFreightForwarderStopWidth,
            position: this.state.colOrder.hasFreightForwarderStopPosition,
            show:this.props.show.hasFreightForwarderStop,
            Cell: (cellInfo) => {
              if(cellInfo.original.canUpdateFreightForwarderStop === true && this.props.isLoggedIn === true){
                return this.renderFreightForwarderStopCell(cellInfo)
              } else {
                return cellInfo.value?"Yes":"No"
              }
            }
          },{
            Header: 'Locality',
            accessor: 'locality',
            width: this.props.show.localityWidth,
            position: this.state.colOrder.localityPosition,
            show:this.props.show.locality,
          },{
            Header: 'Customs Agent Stop',
            accessor: 'hasCustomsAgentStop',
            width: this.props.show.hasCustomsAgentStopWidth,
            position: this.state.colOrder.hasCustomsAgentStopPosition,
            show:this.props.show.hasCustomsAgentStop,
            Cell: (cellInfo) => {
              if(cellInfo.original.canUpdateCustomsAgentStop === true && this.props.isLoggedIn === true){
                return this.renderCustomsAgentStopCell(cellInfo)
              } else {
                return cellInfo.value?"Yes":"No"
              }
            }
          }, {
            Header: 'Received Date',
            accessor: 'receivedDateTime',
            width: this.props.show.receivedDateTimeWidth,
            position: this.state.colOrder.receivedDateTimePosition,
            show:this.props.show.receivedDateTime,
            Cell: cellInfo => formatEpochDateTime(cellInfo.value)
          }, {
            Header: 'Released Date',
            accessor: 'releasedDateTime',
            width: this.props.show.releasedDateTimeWidth,
            position: this.state.colOrder.releasedDateTimePosition,
            show:this.props.show.releasedDateTime,
            Cell: cellInfo => formatEpochDateTime(cellInfo.value)
          }, {
            Header: 'Last Day Of Free Storage',
            accessor: 'lastDayOfFreeStorage',
            width: this.props.show.lastDayOfFreeStorageWidth,
            position: this.state.colOrder.lastDayOfFreeStoragePosition,
            show:this.props.show.lastDayOfFreeStorage,
            Cell: cellInfo => formatEpochDate(cellInfo.value)
          },{
            Header: 'Available For Delivery',
            accessor: 'availableForDeliveryDateTime',
            width: this.props.show.availableForDeliveryDateTimeWidth,
            position: this.state.colOrder.availableForDeliveryDateTimePosition,
            show:this.props.show.availableForDeliveryDateTime,
            Cell: cellInfo => formatEpochDateTime(cellInfo.value)
          },{
            Header: 'Condition',
            accessor: 'condition',
            width: this.props.show.conditionWidth,
            position: this.state.colOrder.conditionPosition,
            show:this.props.show.condition
          },{
            Header: 'Consignee',
            accessor: 'consignee',
            width: this.props.show.consigneeWidth,
            position: this.state.colOrder.consigneePosition,
            show:this.props.show.consignee,
            Cell: (cellInfo) => {
              if(cellInfo.original.canUpdateConsignee === true && this.props.isLoggedIn === true && this.props.consignees.length>0){
                return this.renderConsigneesCell(cellInfo)
              } else {
                return cellInfo.value
              }
            }
          },{
            Header: 'Client Ref',
            accessor: 'clientRef',
            width: this.props.show.clientRefWidth,
            position: this.state.colOrder.clientRefPosition,
            show:this.props.show.clientRef
          },{
            Header: 'Customs Agent',
            accessor: 'customsAgent',
            width: this.props.show.customsAgentWidth,
            position: this.state.colOrder.customsAgentPosition,
            show:this.props.show.customsAgent
          },{
            Header: 'Description',
            accessor: 'description',
            width: this.props.show.descriptionWidth,
            position: this.state.colOrder.descriptionPosition,
            show:this.props.show.description
          },{
            Header: 'Customs Entry Number',
            accessor: 'customsEntryNumber',
            width: this.props.show.customsEntryNumberWidth,
            position: this.state.colOrder.customsEntryNumberPosition,
            show:this.props.show.customsEntryNumber
          },{
            Header: 'Release Carrier',
            accessor: 'releaseCarrier',
            width: this.props.show.releaseCarrierWidth,
            position: this.state.colOrder.releaseCarrierPosition,
            show:this.props.show.releaseCarrier,
            // show: this.props.isLoggedIn? this.props.show.freightForwarder:false,
            Cell: (cellInfo) => {
              if(cellInfo.original.canUpdateCarrier === true && this.props.isLoggedIn === true && this.props.carCarriers.length>0){
                return this.renderReleaseCarrierCell(cellInfo)
              } else {
                return cellInfo.value
              }
            }
          },{
            Header: 'Has Radio',
            accessor: 'hasRadio',
            width: this.props.show.hasRadioWidth,
            position: this.state.colOrder.hasRadioPosition,
            show:this.props.show.hasRadio
          },{
            Header: 'Key Number',
            accessor: 'keyNumber',
            width: this.props.show.keyNumberWidth,
            position: this.state.colOrder.keyNumberPosition,
            show:this.props.show.keyNumber
          },{
            Header: 'Location On Port',
            accessor: 'locationOnPort',
            width: this.props.show.locationOnPortWidth,
            position: this.state.colOrder.locationOnPortPosition,
            show:this.props.show.locationOnPort
          },{
            Header: 'Release Vehicle',
            accessor: 'releaseVehicle',
            width: this.props.show.releaseVehicleWidth,
            position: this.state.colOrder.releaseVehiclePosition,
            show:this.props.show.releaseVehicle
          },{
            Header: 'Load Port',
            accessor: 'loadPort',
            width: this.props.show.loadPortWidth,
            position: this.state.colOrder.loadPortPosition,
            show:this.props.show.loadPort
          },{
            Header: 'Carrier Remarks',
            accessor: 'carrierRemarks',
            width: this.props.show.carrierRemarksWidth,
            position: this.state.colOrder.carrierRemarksPosition,
            show:this.props.show.carrierRemarks,
            Cell: (cellInfo)=>cellInfo.original.canUpdateCarrierRemarks && this.props.isLoggedIn? this.renderCarrierRemarksCell(cellInfo): cellInfo.value
          },{
            Header: 'Freight Forwarder',
            accessor: 'freightForwarder',
            width: this.props.show.freightForwarderWidth,
            position: this.state.colOrder.freightForwarderPosition,
            show:this.props.show.freightForwarder,
            Cell: (cellInfo) => {
              if(cellInfo.original.canUpdateFreightForwarder === true && this.props.isLoggedIn === true && this.props.freightForwarders.length>0){
                return this.renderFreightForwarderCell(cellInfo)
              } else {
                return cellInfo.value
              }
            }
          }
        ]

        const orderedCols = [...columns].sort((function (a, b) {
          return a.position - b.position;
        }))
  
        const draggableList = columns.map((item)=>{
          return item.accessor
        })


        return ( 
          <React.Fragment>
            <div className="table-header-container">
              <VehiclesTableHeader handleExport={this.handleExport}/>
            </div>
            <div id="react-table-container">
              <ReactTableDraggableColumns
                    draggableColumns= {{
                      mode: 'reorder',
                      draggable: draggableList,
                      onDraggedColumnChange: function(columns){
                        console.log(columns)
                      }
                    }}
                    data={this.props.vehiclesReturn}
                    columns={orderedCols}
                    defaultSorted={this.props.defaultSorted}
                    defaultPageSize={20}
                    ref={(r)=>this.reactTableVehicles=r}
                    style={{
                      height: this.props.collapsedDetails?"calc(100vh - 175px)":"450px",
                      fontSize: "0.8em",
                      
                    }}
                    noDataText={null}
                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e, t) => {
                            this.props.dispatch({type: "UPDATE_DETAILS_COMPONENT", payload:{key:"vehiclesDetails", value:this.props.vehiclesReturn[rowInfo.index]}})
                            this.props.dispatch({type:"SET_SELECTED_ROW", payload:{key:"vehiclesTableSelectedRow", value:rowInfo.index}})
                            this.props.isLoggedIn && this.props.dispatch(setCargoTransactions(this.props.vehiclesReturn[rowInfo.index].cargoItemObjectString, "UPDATE_VEHICLES_TRANSACTIONS"))
                          } ,
                          style: {
                            background: rowInfo.index === this.props.vehiclesTableSelectedRow? "rgb(50, 135, 214, 0.25)": null
                          }
                        }
                      }else{
                          return {}
                        }
                      }}
                      getTheadTrProps = {(info)=>{
                        return {
                          onMouseLeave: (e,t)=>{
                            var colOrder = info.columns.reduce(
                              (obj, item, index) => Object.assign(obj, { [item.accessor +"Position"]: index }), {});
                              // var colName = info.columns.reduce(
                              //   (obj, item, index) => Object.assign(obj, { [item.Header.props.children]: index }), {});
                        
                                //console.log(colOrder)
                                this.props.dispatch({type:"UPDATE_VEH_COL_ORDER", vehiclesColOrder:colOrder})
                                if(
                                  colOrder !== this.props.colOrder
                                  ){
                                  // console.log(colOrder)
                                  // console.log(this.props.colOrder)
                                }
                                
                          }
                        }
                      }}
                    className="-striped -highlight"
                    onResizedChange={(newResized, event) => {
                      this.handleWidthChange(newResized)
                    }}
                    onSortedChange={(newSorted, column, shiftKey) => {
                      this.props.dispatch({type:"CHANGE_ORG_VEH_COL_ITEM", payload:{key:"defaultSorted", value:newSorted}})
                      console.log("sorted")
                    }}
            />
            {/* <ReactTable
                    data={this.props.vehiclesReturn}
                    settings={{}}
                    columns={columns}
                    defaultSorted={this.props.defaultSorted}
                    defaultPageSize={20}
                    ref={(r)=>this.reactTableVehicles=r}
                    style={{
                    height: "450px",
                    fontSize: "0.8em",
                    }}

                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e, t) => {
                            this.props.dispatch({type: "UPDATE_DETAILS_COMPONENT", payload:{key:"vehiclesDetails", value:this.props.vehiclesReturn[rowInfo.index]}})
                            this.props.dispatch({type:"SET_SELECTED_ROW", payload:{key:"vehiclesTableSelectedRow", value:rowInfo.index}})
                            this.props.isLoggedIn && this.props.dispatch(setCargoTransactions(this.props.vehiclesReturn[rowInfo.index].cargoItemObjectString, "UPDATE_VEHICLES_TRANSACTIONS"))
                          } ,
                          style: {
                            background: rowInfo.index === this.props.vehiclesTableSelectedRow? "rgb(50, 135, 214, 0.25)": null
                          }
                        }
                      }else{
                          return {}
                        }
                      }}
                    className="-striped -highlight"
                    onResizedChange={(newResized, event) => {
                      this.handleWidthChange(newResized)
                    }}
                    onSortedChange={(newSorted, column, shiftKey) => {
                      this.props.dispatch({type:"CHANGE_ORG_VEH_COL_ITEM", payload:{key:"defaultSorted", value:newSorted}})
                    }}
            /> */}
        </div>
        </React.Fragment>
         );
    }
}
 
function mapStateToProps(state){
    return {
        vehiclesReturn: state.vehiclesReturn,
        userInfo: state.userInfo,
        isLoggedIn: state.visibleComponents.isLoggedIn,
        vehiclesTableSelectedRow: state.visibleComponents.vehiclesTableSelectedRow,
        freightForwarders: state.dropdowns.freightForwarders,
        carCarriers: state.dropdowns.carCarriers,
        consignees: state.dropdowns.consignees,
        show: state.organiseTable.vehicles,
        colOrder: state.organiseTable.vehiclesColOrder,
        vehiclesSaveArray: state.visibleComponents.vehiclesSaveArray,
        vehiclesSaveButton: state.visibleComponents.vehiclesSaveButton,
        defaultSorted: state.organiseTable.vehicles.defaultSorted,
        collapsedDetails: state.visibleComponents.collapsedDetails,
        //need key to trigger refresh of table-draggable component. key is changed on loading of settings/login
        key: state.visibleComponents.vehiclesTableKey


    }
  }  

export default connect(mapStateToProps)(VehiclesTable);