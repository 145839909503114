import React from "react"
import ReactTable from "react-table-6"
import { connect } from "react-redux"
import { formatEpochDateTime } from "../../utils"

class containersTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const columns = [{
            Header: 'Date',
            accessor: 'dateTime',
            Cell: props => formatEpochDateTime(props.value)
          }, {
            Header: 'Type',
            accessor: 'type'
          },{
            Header: 'Details',
            accessor: 'details'
          },{
            Header: 'By Organisation',
            accessor: 'byOrganisation'
          }]


        return ( 
            <div id="react-table-container">
            <ReactTable
                    data={this.props.transactions}
                    columns={columns}
                    defaultPageSize={20}
                    style={{
                    height: "450px",
                    width: "800px",
                    fontSize: "0.8em"

                    }}
                    // noDataText=""

                    getTrProps={(state, rowInfo, column) => {
                        return {
                          onClick: (e, t) => {
                          }
                        };
                      }}
                    className="-striped -highlight"
            />
        </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
    transactions: state.detailsTab.containersTransactions.objects
    }
  }  

export default connect(mapStateToProps)(containersTransactions);