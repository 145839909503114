import React from "react"
import { connect } from "react-redux"

class OrganiseContainersTable extends React.Component {
    constructor(props) {
        super(props);
        //Settings are kept in state until submitted and then passed to Store.
        this.state = { 
            freightForwarder:false,
            id: true,
            vessel: true,
            portVoyageCode: true,
            operator: true,
            imexStatus: true,
            hasOperatorStop: false,
            stops: true,
            hasTasks: true,
            hasFreightForwarderStop: false,
            locality: true,
            isoCode: true,
            lengthWidthHeight: true,
            isReefer: true,
            isOverdimensional: true,
            hazardousDescription: true,
            receivedDateTime: true,
            receiveMode: true,
            releasedDateTime:true,
            releaseMode:true,
            lastDayOfFreeStorage: true,
            availableForDeliveryDateTime: false,
            bookingReference: false,
            cedo: false,
            commodity: false,
            consignee:false,
            consignor: false,
            customsEntryNumber: false,
            hasBeenUnpacked: false,
            optimalPickUp: false,
            pin: false,
            carrierRemarks: false,
            receiveCarrier: false,
            receiveVehicle: false,
            releaseCarrier: false,
            releaseVehicle: false,
            seal: false,
            temperature: false,
            loadPort: false,
            dischargePort:false,
            connectedDateTime:false,
            weight:false,
            destinationPort:false,
            isTranshipment:false,
            yardLocation: false,
            isWeightCertified:false,
            selectAll: false,
        }
    }

    componentDidMount(){
        //Keeps state settings up to date with Redux store on reload.
            this.setState({
            freightForwarder:this.props.containers.freightForwarder,
            id: this.props.containers.id,
            vessel: this.props.containers.vessel,
            portVoyageCode: this.props.containers.portVoyageCode,
            operator: this.props.containers.operator,
            imexStatus: this.props.containers.imexStatus,
            hasOperatorStop: this.props.containers.hasOperatorStop,
            stops: this.props.containers.stops,
            hasTasks: this.props.containers.hasTasks,
            hasFreightForwarderStop: this.props.containers.hasFreightForwarderStop,
            locality: this.props.containers.locality,
            isoCode: this.props.containers.isoCode,
            lengthWidthHeight: this.props.containers.lengthWidthHeight,
            isReefer: this.props.containers.isReefer,
            isOverdimensional: this.props.containers.isOverdimensional,
            hazardousDescription: this.props.containers.hazardousDescription,
            receivedDateTime: this.props.containers.receivedDateTime,
            receiveMode: this.props.containers.receiveMode,
            releasedDateTime:this.props.containers.releasedDateTime,
            releaseMode:this.props.containers.releaseMode,
            lastDayOfFreeStorage: this.props.containers.lastDayOfFreeStorage,
            availableForDeliveryDateTime: this.props.containers.availableForDeliveryDateTime,
            bookingReference: this.props.containers.bookingReference,
            cedo: this.props.containers.cedo,
            commodity: this.props.containers.commodity,
            consignee:this.props.containers.consignee,
            consignor: this.props.containers.consignor,
            customsEntryNumber: this.props.containers.customsEntryNumber,
            hasBeenUnpacked: this.props.containers.hasBeenUnpacked,
            optimalPickUp: this.props.containers.optimalPickUp,
            pin: this.props.containers.pin,
            carrierRemarks: this.props.containers.carrierRemarks,
            receiveCarrier: this.props.containers.receiveCarrier,
            receiveVehicle: this.props.containers.receiveVehicle,
            releaseCarrier: this.props.containers.releaseCarrier,
            releaseVehicle: this.props.containers.releaseVehicle,
            seal: this.props.containers.seal,
            temperature: this.props.containers.temperature,
            loadPort: this.props.containers.loadPort,
            dischargePort:this.props.containers.dischargePort,
            connectedDateTime:this.props.containers.connectedDateTime,
            weight:this.props.containers.weight,
            destinationPort:this.props.containers.destinationPort,
            isTranshipment:this.props.containers.isTranshipment,
            yardLocation: this.props.containers.yardLocation,
            isWeightCertified:this.props.containers.isWeightCertified,
            selectAll: this.props.containers.selectAll,
            })
          }
    
    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})        }
    }

    handleCheckboxChange = event => {
        this.setState({[event.target.name]:event.target.checked})
    }


    handleSelectAll = event =>{
        this.setState({
                    selectAll: event.target.checked,
                    id: event.target.checked,
                    vessel: event.target.checked,
                    portVoyageCode: event.target.checked,
                    operator: event.target.checked,
                    imexStatus: event.target.checked,
                    hasOperatorStop: event.target.checked,
                    stops: event.target.checked,
                    hasTasks: event.target.checked,
                    hasFreightForwarderStop: event.target.checked,
                    locality: event.target.checked,
                    isoCode: event.target.checked,
                    lengthWidthHeight: event.target.checked,
                    isReefer: event.target.checked,
                    isOverdimensional: event.target.checked,
                    hazardousDescription: event.target.checked,
                    receivedDateTime: event.target.checked,
                    receiveMode: event.target.checked,
                    releasedDateTime:event.target.checked,
                    releaseMode:event.target.checked,
                    lastDayOfFreeStorage: event.target.checked,
                    availableForDeliveryDateTime: event.target.checked,
                    bookingReference: event.target.checked,
                    cedo: event.target.checked,
                    commodity: event.target.checked,
                    consignee:event.target.checked,
                    consignor: event.target.checked,
                    customsEntryNumber: event.target.checked,
                    hasBeenUnpacked: event.target.checked,
                    optimalPickUp: event.target.checked,
                    pin: event.target.checked,
                    carrierRemarks: event.target.checked,
                    receiveCarrier: event.target.checked,
                    receiveVehicle: event.target.checked,
                    releaseCarrier: event.target.checked,
                    releaseVehicle: event.target.checked,
                    seal: event.target.checked,
                    temperature: event.target.checked,
                    loadPort: event.target.checked,
                    dischargePort:event.target.checked,
                    connectedDateTime:event.target.checked,
                    weight:event.target.checked,
                    destinationPort:event.target.checked,
                    isTranshipment:event.target.checked,
                    yardLocation: event.target.checked,
                    isWeightCertified:event.target.checked,
                    freightForwarder:event.target.checked   
            })
    }

    handleSubmit = event =>{
        event.preventDefault()
        this.props.dispatch(
            {
                type: "UPDATE_ALL_ORG_CONT_CHECKBOX",
                payload: {
                    freightForwarder:this.state.freightForwarder,
                    id: this.state.id,
                    vessel: this.state.vessel,
                    portVoyageCode: this.state.portVoyageCode,
                    operator: this.state.operator,
                    imexStatus: this.state.imexStatus,
                    hasOperatorStop: this.state.hasOperatorStop,
                    stops: this.state.stops,
                    hasTasks: this.state.hasTasks,
                    hasFreightForwarderStop: this.state.hasFreightForwarderStop,
                    locality: this.state.locality,
                    isoCode: this.state.isoCode,
                    lengthWidthHeight: this.state.lengthWidthHeight,
                    isReefer: this.state.isReefer,
                    isOverdimensional: this.state.isOverdimensional,
                    hazardousDescription: this.state.hazardousDescription,
                    receivedDateTime: this.state.receivedDateTime,
                    receiveMode: this.state.receiveMode,
                    releasedDateTime:this.state.releasedDateTime,
                    releaseMode:this.state.releaseMode,
                    lastDayOfFreeStorage: this.state.lastDayOfFreeStorage,
                    availableForDeliveryDateTime: this.state.availableForDeliveryDateTime,
                    bookingReference: this.state.bookingReference,
                    cedo: this.state.cedo,
                    commodity: this.state.commodity,
                    consignee:this.state.consignee,
                    consignor: this.state.consignor,
                    customsEntryNumber: this.state.customsEntryNumber,
                    hasBeenUnpacked: this.state.hasBeenUnpacked,
                    optimalPickUp: this.state.optimalPickUp,
                    pin: this.state.pin,
                    carrierRemarks: this.state.carrierRemarks,
                    receiveCarrier: this.state.receiveCarrier,
                    receiveVehicle: this.state.receiveVehicle,
                    releaseCarrier: this.state.releaseCarrier,
                    releaseVehicle: this.state.releaseVehicle,
                    seal: this.state.seal,
                    temperature: this.state.temperature,
                    loadPort: this.state.loadPort,
                    dischargePort:this.state.dischargePort,
                    connectedDateTime:this.state.connectedDateTime,
                    weight:this.state.weight,
                    destinationPort:this.state.destinationPort,
                    isTranshipment:this.state.isTranshipment,
                    yardLocation: this.state.yardLocation,
                    isWeightCertified:this.state.isWeightCertified,
                    selectAll: this.state.selectAll,
                }
            }
        )
        this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"}) 
    }

    render() { 
        return ( 
            <div className="modal-background">
                <div className="organise-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                        <span>Organise</span>
                    </div>
                    <div className="modal-content-container">
                    <form onSubmit={this.handleSubmit}>
                        <table className="organise-table">
                        <thead>
                            <tr>
                            <th></th>
                            <th>Show</th>
                            <th></th>
                            <th></th>
                            <th>Show</th>                            
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>
                                <td>Freight Forwarder:</td>
                                <td><input name="freightForwarder"  type="checkbox" checked={this.state.freightForwarder} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>CEDO:</td>
                                <td><input name="cedo"  type="checkbox" checked={this.state.cedo} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>ID:</td>
                                <td><input name="id"  type="checkbox" checked={this.state.id} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Commodity:</td>
                                <td><input name="commodity"  type="checkbox" checked={this.state.commodity} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Vessel:</td>
                                <td><input name="vessel"  type="checkbox" checked={this.state.vessel} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Consignee:</td>
                                <td><input name="consignee"  type="checkbox" checked={this.state.consignee} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Voyage Code:</td>
                                <td><input name="portVoyageCode"  type="checkbox" checked={this.state.portVoyageCode} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Consignor:</td>
                                <td><input name="consignor"  type="checkbox" checked={this.state.consignor} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Operator:</td>
                                <td><input name="operator"  type="checkbox" checked={this.state.operator} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Customs Entry Number:</td>
                                <td><input name="customsEntryNumber"  type="checkbox" checked={this.state.customsEntryNumber} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>IMEX Status:</td>
                                <td><input name="imexStatus"  type="checkbox" checked={this.state.imexStatus} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Has Been Unpacked:</td>
                                <td><input name="hasBeenUnpacked"  type="checkbox" checked={this.state.hasBeenUnpacked} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Operator Stop:</td>
                                <td><input name="hasOperatorStop"  type="checkbox" checked={this.state.hasOperatorStop} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Optimal Pick Up:</td>
                                <td><input name="optimalPickUp"  type="checkbox" checked={this.state.optimalPickUp} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Stops:</td>
                                <td><input name="stops"  type="checkbox" checked={this.state.stops} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>PIN:</td>
                                <td><input name="pin"  type="checkbox" checked={this.state.pin} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Has Tasks:</td>
                                <td><input name="hasTasks"  type="checkbox" checked={this.state.hasTasks} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Carrier Remarks:</td>
                                <td><input name="carrierRemarks"  type="checkbox" checked={this.state.carrierRemarks} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Freight Forwarder Stop:</td>
                                <td><input name="hasFreightForwarderStop"  type="checkbox" checked={this.state.hasFreightForwarderStop} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Receive Carrier:</td>
                                <td><input name="receiveCarrier"  type="checkbox" checked={this.state.receiveCarrier} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Locality:</td>
                                <td><input name="locality"  type="checkbox" checked={this.state.locality} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Receive Vehicle:</td>
                                <td><input name="receiveVehicle"  type="checkbox" checked={this.state.receiveVehicle} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>ISO Code:</td>
                                <td><input name="isoCode"  type="checkbox" checked={this.state.isoCode} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Release Carrier:</td>
                                <td><input name="releaseCarrier"  type="checkbox" checked={this.state.releaseCarrier} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Length/Width/Height:</td>
                                <td><input name="lengthWidthHeight"  type="checkbox" checked={this.state.lengthWidthHeight} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Release Vehicle:</td>
                                <td><input name="releaseVehicle"  type="checkbox" checked={this.state.releaseVehicle} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Is Reefer:</td>
                                <td><input name="isReefer"  type="checkbox" checked={this.state.isReefer} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Seal:</td>
                                <td><input name="seal"  type="checkbox" checked={this.state.seal} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Is Overdimensional:</td>
                                <td><input name="isOverdimensional"  type="checkbox" checked={this.state.isOverdimensional} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Temperature:</td>
                                <td><input name="temperature"  type="checkbox" checked={this.state.temperature} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Hazardous Description:</td>
                                <td><input name="hazardousDescription"  type="checkbox" checked={this.state.hazardousDescription} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Load Port:</td>
                                <td><input name="loadPort"  type="checkbox" checked={this.state.loadPort} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Receive Date:</td>
                                <td><input name="receivedDateTime"  type="checkbox" checked={this.state.receivedDateTime} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Discharge Port:</td>
                                <td><input name="dischargePort"  type="checkbox" checked={this.state.dischargePort} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Receive Mode:</td>
                                <td><input name="receiveMode"  type="checkbox" checked={this.state.receiveMode} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Connected Date:</td>
                                <td><input name="connectedDateTime"  type="checkbox" checked={this.state.connectedDateTime} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Release Date:</td>
                                <td><input name="releasedDateTime"  type="checkbox" checked={this.state.releasedDateTime} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Weight:</td>
                                <td><input name="weight"  type="checkbox" checked={this.state.weight} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Release Mode:</td>
                                <td><input name="releaseMode"  type="checkbox" checked={this.state.releaseMode} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Destination Port:</td>
                                <td><input name="destinationPort"  type="checkbox" checked={this.state.destinationPort} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Last Day Of Free Storage:</td>
                                <td><input name="lastDayOfFreeStorage"  type="checkbox" checked={this.state.lastDayOfFreeStorage} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Is Transhipment:</td>
                                <td><input name="isTranshipment"  type="checkbox" checked={this.state.isTranshipment} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Available For Delivery Date:</td>
                                <td><input name="availableForDeliveryDateTime"  type="checkbox" checked={this.state.availableForDeliveryDateTime} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>Yard Location:</td>
                                <td><input name="yardLocation"  type="checkbox" checked={this.state.yardLocation} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td>Booking Reference:</td>
                                <td><input name="bookingReference"  type="checkbox" checked={this.state.bookingReference} onChange={this.handleCheckboxChange}/></td>
                                <td></td>
                                <td>VGM:</td>
                                <td><input name="isWeightCertified"  type="checkbox" checked={this.state.isWeightCertified} onChange={this.handleCheckboxChange}/></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Select All:</td>
                                <td><input type="checkbox" checked={this.state.selectAll} onChange={this.handleSelectAll}/></td>
                            </tr>
                        </tbody>
                        </table>
                        <div className="save-button-container">
                        <div><input type="submit" className="submit-button save-button"  value="Show"/></div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
     containers: state.organiseTable.containers
    }
  }  

export default connect(mapStateToProps)(OrganiseContainersTable);