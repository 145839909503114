import React from "react"
import { connect } from "react-redux";
import { sendContainerUpdate } from "../../actions"


class EditAllContainers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            ffStop:false,
            freightForwarder: false,
            carrier:false,
            carrierRemarks:false,
            operatorStop:false,
            ffStopValue:false,
            freightForwarderValue: "",
            carrierValue:"",
            carrierRemarksValue:"",
            operatorStopValue:false,
        }
    }


    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }

    handleCheckboxChange = event => {
        this.setState({[event.target.name]:event.target.checked})
    }

    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }

    handleSubmit = event =>{
        event.preventDefault()        
        if(
            this.state.ffStop === true ||
            this.state.freightForwarder === true ||
            this.state.carrier === true ||
            this.state.carrierRemarks === true ||
            this.state.operatorStop === true 
            ){
            for(var i=0 ; i < this.props.containersReturn.length; i++){
                var containerValues = this.props.containersReturn[i]
                //post values are the original values unless the corresponding edit box is checked and item has permission to edit that value
                var hasOperatorStop = containerValues.hasOperatorStop
                var hasFreightForwarderStop = containerValues.hasFreightForwarderStop
                var freightForwarder = containerValues.freightForwarderObjectString
                var carrierRemarks = containerValues.carrierRemarks
                var carrier = containerValues.releaseCarrierObjectString
               if (this.state.operatorStop && containerValues.canUpdateOperatorStop){hasOperatorStop = this.state.operatorStopValue}
               if (this.state.ffStop && containerValues.canUpdateFreightForwarderStop){hasFreightForwarderStop = this.state.ffStopValue}
               if (this.state.freightForwarder && containerValues.canUpdateFreightForwarder){freightForwarder = this.state.freightForwarderValue}
               if (this.state.carrierRemarks && containerValues.canUpdateCarrierRemarks){carrierRemarks = this.state.carrierRemarksValue}
               if (this.state.carrier && containerValues.canUpdateCarrier){carrier = this.state.carrierValue}

                var postBody ={	
                    "cargoItemUpdate" : {
                        "carrierObjectString": carrier,
                        "carrierRemarks": carrierRemarks,
                        "consigneeObjectString" : containerValues.consigneeObjectString,
                        "freightForwarderObjectString": freightForwarder,
                        "hasCustomsAgentStop" : containerValues.hasCustomsAgentStop,
                        "hasFreightForwarderStop": hasFreightForwarderStop,
                        "hasOperatorStop": hasOperatorStop,
                        "objectEdition": this.props.containersReturn[i].objectEdition.toString(),
                        "targetObjectString": this.props.containersReturn[i].cargoItemObjectString
                    },
                    "user" :{
                        "userObjectString": this.props.userInfo.user.userObjectString
                    },
                    "sessionId": this.props.userInfo.sessionId
                
                }
                this.props.dispatch(sendContainerUpdate(postBody, i))
                console.log(postBody)
            }
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            }else{
                alert("Please select an item to update")
            }
        
    }

    render() { 
        return ( 
            <div className="modal-background">
            <div className="organise-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                    <span>Edit All</span>
                </div>
                <div className="modal-content-container">
                <form onSubmit={this.handleSubmit}>
                <table className="edit-all-table">
                    <thead>
                        <tr>
                            <th>Edit:</th>
                            <th>Set to:</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>  
                        {this.props.visibleComponents.editAllConOperatorStop &&
                        <tr>
                            <td><input type="checkbox" name="operatorStop" checked={this.state.operatorStop} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.operatorStop?"":"unactive"}>Operator Stop:</td>
                            <td className={this.state.operatorStop?"":"unactive"}><input name="operatorStopValue" checked={this.state.operatorStopValue}  type="checkbox" onChange={this.handleCheckboxChange}/></td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllConFFStop &&
                        <tr>
                            <td><input type="checkbox" name="ffStop" checked={this.state.ffStop} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.ffStop?"":"unactive"}>Freight Forwarder Stop:</td>
                            <td className={this.state.ffStop?"":"unactive"}><input name="ffStopValue"  type="checkbox" checked={this.state.ffStopValue} onChange={this.handleCheckboxChange}/></td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllConFF  &&  this.props.freightForwarders.length > 0 &&
                        <tr>
                            <td><input type="checkbox" name="freightForwarder" checked={this.state.freightForwarder} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.freightForwarder?"":"unactive"}>FreightForwarder:</td>
                            <td className={this.state.freightForwarder?"":"unactive"}>
                            <select className="input-select" onChange={this.handleChange} name="freightForwarderValue">
                                    <option value=""></option>
                                    {this.props.freightForwarders.map((item, i)=>{
                                return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                )
                            })}
                                </select>
                            </td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllConCarrier  && this.props.carriers.length > 0 &&
                        <tr>
                            <td><input type="checkbox" name="carrier" checked={this.state.carrier} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.carrier?"":"unactive"}>Carrier:</td>
                            <td className={this.state.carrier?"":"unactive"}>
                            <select className="input-select" onChange={this.handleChange} name="carrierValue">
                                    <option value=""></option>
                                    {this.props.carriers.map((item, i)=>{
                                return(
                                    <option key={i} value={item.objectString}>{item.displayAttribute}</option>
                                )
                            })}
                                </select>
                            </td>
                        </tr>
                        }
                        {this.props.visibleComponents.editAllConCarrierRemarks &&
                        <tr>
                            <td><input type="checkbox"  name="carrierRemarks" checked={this.state.carrierRemarks} onChange={this.handleCheckboxChange}/></td>
                            <td className={this.state.carrierRemarks?"":"unactive"}>Carrier Remarks:</td>
                            <td className={this.state.carrierRemarks?"":"unactive"}><textarea rows="4" name="carrierRemarksValue" value={this.state.carrierRemarksValue} onChange={this.handleChange}/></td>
                        </tr>
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="table-button">
                                <input type="submit" value="Save" className="submit-button save-button"/>
                            </td>
                        </tr>
                    </tbody>
            </table>
                </form>
                </div>
            </div>
        </div>

         );
    }
}

function mapStateToProps(state){
    return {
        containersReturn: state.containersReturn,
        carriers: state.dropdowns.containerCarriers,
        freightForwarders: state.dropdowns.freightForwarders,
        visibleComponents: state.visibleComponents,
        userInfo: state.userInfo,

    }
  }  
 
export default connect(mapStateToProps)(EditAllContainers);