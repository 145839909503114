import request from 'superagent'
import { apiCall } from "../../config"


export function getPorts(){
    return request.get(apiCall + "/ports?CentricRestService")
    .then(res => {
        const posts = res.body
        return posts
})
}


export function getSimpleUserBasedGets(getType, userObjStr){
    return request.get(apiCall + `/${getType}/${userObjStr}?CentricRestService`)
    .then(res => {
        const posts = res.body
        return posts
})
}


export function getVoyages(voyageType){
    return request.get(apiCall + `/voyages/${voyageType}?CentricRestService`)
    .then(res => {
        const posts = res.body
        return posts
    })
    .catch(err => {
        err && alert(err)
    })
}

export function searchShippingMovements(postBody){
    return request.post(apiCall + "/shipping_movements?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        const posts2 = res.body
        return posts2
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function searchCargoItems(postBody){
    return request.post(apiCall + "/cargo_items.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        const posts = res.body
        return posts
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postUserInfo(postBody){
    return request.post(apiCall + "/authenticate_user?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        const posts2 = res.body
        console.log(posts2)
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postShippingMovementUpdate(postBody){
    return request.post(apiCall + "/update_shipping_movement.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        const posts2 = res.body
        console.log(posts2)
        return posts2
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postUpdateCargoItem(postBody){
    return request.post(apiCall + "/update_cargo_item.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        const posts2 = res.body
        return posts2
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}



export function postChangeUserPassword(postBody){
    return request.post(apiCall + "/change_user_password?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postFilterLookupCars(postBody){
    return request.post(apiCall + "/filter_lookups_for_cars.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        const posts2 = res.body
        // console.log(posts2)
        return posts2
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function getCargoTransactions(cargoItemObjectString){
    return request.get(apiCall + `/cargo_item_transactions/${cargoItemObjectString}?CentricRestService`)
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postDeleteSavedSetting(postBody){
    return request.post(apiCall + "/delete_saved_setting.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postSaveSetting(postBody){
    return request.post(apiCall + "/save_setting.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        console.log(res)
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}


// for first step of Export Pre-notes
export function getBookingReference(bookingRef){
    return request.get(apiCall + `/booking_reference/${bookingRef}?CentricRestService`)
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

// for second step of Export Pre-notes
export function getContainerNumber(contaierNumber){
    return request.get(apiCall + `/prenote_container_id/${contaierNumber}?CentricRestService`)
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

// for third step of Export Pre-notes
export function sendPreNoteContainer(postBody){
    return request.post(apiCall + "/prenote_container.json?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postEmail(postBody){
    return request.post(apiCall + "/email?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}

export function postResetPassword(postBody){
    return request.post(apiCall + "/password_reset?CentricRestService")
    .send(
        postBody
    )
    .then(res => {
        return res
    })
    .catch(err=>{
        console.log("Error" + err)
    })
}



