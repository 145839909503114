import React from "react"
import { connect } from "react-redux";
import { changeInPortDate } from "../../../actions"
import { todayDate } from "../../../utils"

class InPortDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
        }
    }

    componentDidMount(){
        this.props.dispatch(changeInPortDate(todayDate()))
      }

    handleInPortDateChange = event =>{
        this.props.dispatch(changeInPortDate(event.target.value))
    }

    handleRadioChange = event =>{

    }

    render() { 
        return ( 
<React.Fragment>
<p>In Port Date:</p>
                <input type="radio"name="inPortDate" checked={this.props.shippingSearch.radioButton === "inPortDate"}  value=""onChange={()=>{this.props.dispatch({type:"CHANGE_SEARCH_SHIPPING_INPUT", payload:{key:"radioButton", value:event.target.name}})}}></input>
                <span className="input-title">On:</span>
                <input className="input-date" type="date" name="inPortDate" value={this.props.inPortDate} onChange={this.handleInPortDateChange}></input>
</React.Fragment>

         );
    }
}
function mapStateToProps(state){
    return {
        inPortDate: state.shippingSearch.inPortDate,
        shippingSearch: state.shippingSearch
    }
  } 
export default connect(mapStateToProps)(InPortDate) 

