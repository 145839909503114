const initialState = {
    shippingDetails: {}, //need to transfer over from details reducer
    containersDetails: {},
    vehiclesDetails: {},
    containersTransactions: {},
    vehiclesTransactions: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_CONTAINERS_DETAILS":
                return Object.assign({}, state, {containersDetails: action.containersDetails })
                break;
        case "RESET_CONTAINERS_DETAILS":
                return Object.assign({}, state, {containersDetails: initialState.containersDetails})
                break;
        case "RESET_VEHICLES_DETAILS":
                return Object.assign({}, state, {vehiclesDetails: initialState.vehiclesDetails})
                break;
        case "UPDATE_CONTAINERS_TRANSACTIONS":
                return Object.assign({}, state, {containersTransactions: action.transactions })
                break;
        case "UPDATE_VEHICLES_TRANSACTIONS":
                return Object.assign({}, state, {vehiclesTransactions: action.transactions })
                break;
        case "UPDATE_DETAILS_COMPONENT":
                return Object.assign({}, state, {[action.payload.key]: action.payload.value})
                break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer