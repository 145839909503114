const initialState = {
  
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PRE_NOTES":
            return action.preNotes
            break;
        case "RESET_PRE_NOTES":
            return initialState
            break;
        default:
            return state
    }
}

export default reducer