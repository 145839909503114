const initialState = {
    //ids:"V36-105407\nME34S-359610\n1G6DR57T890147624",
    ids:"",
    clientReference: "",
    customsEntryNumber: "",
    houseBOL: "",
    includeCleared: true,
    includeNotReadyToDeliver: true,
    includeOffPort: true,
    includeOnHold: true,
    includeOnPort: true,
    includeOnShip: true,
    includeReadyToDeliver: true,
    oceanBOL:"",
    operatorObjStr:"",
    releasedDateDynamic:"",
    releasedFromDate:"",
    releasedToDate:"",
    storageDays:"",
    voyageObjStr:"",
    radioReleasedDate: "none",
    dateRangeNumber: 1,
    dateRangeType:"Day(s)"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_SEARCH_VEHICLES_INPUT":
            return Object.assign({}, state, {[action.payload.key]: action.payload.value})
            break;
        case "LOAD_VEHICLES_SETTINGS":
            return Object.assign({}, state, {
                bookingReference: action.payload.bookingReference,
                //cedos: action.cedos,
                clientReference: action.payload.clientReference,
                customsEntryNumber: action.payload.customsEntryNumber,
                houseBOL: action.payload.houseBOL,
                ids:action.ids,
                 includeCleared: action.payload.includeCleared,
                // includeDLR: action.payload.includeDLR,
                // includeDry: action.payload.includeDry,
                // includeExports: action.payload.includeExports,
                // includeImports: action.payload.includeImports,
                // includeNotified: action.payload.includeNotified,
                 includeNotReadyToDeliver: action.payload.includeNotReadyToDeliver,
                 includeOffPort: action.payload.includeOffPort,
                includeOnHold: action.payload.includeOnHold,
                 includeOnPort: action.payload.includeOnPort,
                 includeOnShip: action.payload.includeOnShip,
                 includeReadyToDeliver: action.payload.includeReadyToDeliver,
                // includeReefers: action.payload.includeReefers,
                // includeSOB: action.payload.includeSOB,
                // includeStorage: action.payload.includeStorage,
                // noCedoOnly: action.payload.noCedoOnly,
                oceanBOL: action.payload.oceanBOL,
                // operator: action.payload.operator,
                operatorObjStr: action.payload.operatorObjStr,
                // operatorPIN: action.payload.operatorPIN,
                // receivedDateDynamic: action.payload.receivedDateDynamic,
                // receivedFromDate: action.payload.receivedFromDate,
                // receivedToDate: action.payload.receivedToDate,
                // releasedDateDynamic: action.payload.releasedDateDynamic,
                // releasedFromDate: action.payload.releasedFromDate,
                // releasedToDate: action.payload.releasedToDate,
                 storageDays: action.storageDays,
                // voyage: action.payload.voyage,
                voyageObjStr: action.payload.voyageObjStr,
            })
            break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer