export default function formatEpochDateTime(dateString){

    var epochString = dateString.replace(/[^\-0-9]/g, "")
    var epochDate = parseInt(epochString)
    var d = new Date(epochDate);
    var day = d.getUTCDate();
    var month = d.getUTCMonth() + 1;
    var year = d.getUTCFullYear()
    var hour = d.getHours()
    var utcHour = d.getUTCHours()
    var minutes = d.getMinutes()
     if (dateString.length > 0  && epochDate > 0){

         return day+"/"+("0" + month).slice(-2)+"/"+year+" "+("0" + utcHour).slice(-2)+":"+("0" + minutes).slice(-2)
     } else {
        return ""
    }
    }
