const initialState = []

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_SHIPPING_MOVEMENTS":
            return action.shippingMovements
            break;
        case "RESET_SHIPPING_RETURN":
            return initialState
            break;
        case "UPDATE_SHIPPING_MOVEMENT_AT_INDEX":
            return (
                state.slice(0,action.payload.index).concat(action.payload.value).concat(state.slice(action.payload.index + 1))
            )
            break;
        case 'LOGOUT':
            return initialState
            break;
        default:
        return state
    }
}

export default reducer