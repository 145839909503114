import { todayDate, setDynamicDateRange } from "../utils"
import { shippingPost, containersPost, vehiclesPost } from "../actions"



export function searchShipping(){
    return (dispatch, getState) =>{
        const state = getState()
        //Reset highlighted row and previous search results if any
        dispatch({type:"SET_SELECTED_ROW", payload:{key:"shippingTableSelectedRow", value:null}})
        dispatch({type:"RESET_SHIPPING_RETURN"})

        //Set cookie for last searched tab to trigger on login
        if(state.visibleComponents.isLoggedIn === true){ document.cookie = "lastSearchTab=Shipping; Max-Age=15552000"}



    //Set the toDate and fromDate depending on the radiobutton selected
    var searchFilters = state.shippingSearch
    var isLoggedIn = state.visibleComponents.isLoggedIn
    var fromDate = searchFilters.fromDate
    var toDate = searchFilters.toDate
    if (searchFilters.radioButton === "inPortDate"){
        fromDate = ""
        toDate = ""
    } else if (searchFilters.radioButton === "dynamicDate" ){
        switch(searchFilters.dateRangeNext){
            case "Next":
                        fromDate = todayDate()
                        toDate= setDynamicDateRange(searchFilters.dateRangeNext, searchFilters.dateRangeValue, searchFilters.dateRangeType)
            break;
            case "Last": 
                        fromDate= setDynamicDateRange(searchFilters.dateRangeNext, searchFilters.dateRangeValue, searchFilters.dateRangeType)
                        toDate = todayDate()
            break;
            default: console.log("error")
        }
    }
    
    var postSearchPublic = {
        "shippingMovementsFilter": {
            "fromDate": fromDate,
            "inPortDate": searchFilters.radioButton === "inPortDate"? searchFilters.inPortDate : "",
            "includeArrivals": searchFilters.includeArrivals.toString(),
            "includeDepartures": searchFilters.includeDepartures.toString(),
            "includeShifts": searchFilters.includeShifts.toString(),
            "movementDateDynamic": searchFilters.dateRangeNext+","+searchFilters.dateRangeValue+","+searchFilters.dateRangeType,
            "primaryCargoTypeObjStr": searchFilters.primaryCargoTypeObjStr,
            "toDate": toDate,
            "vesselObjStr": searchFilters.vesselObjStr,
            "vesselTypeObjStr": searchFilters.vesselTypeObjStr
        }
    
}
    var postSearchSecure = {	
        "shippingMovementsFilter": {
            "fromDate": fromDate,
            "inPortDate": searchFilters.radioButton === "inPortDate"? searchFilters.inPortDate : "",
            "includeArrivals": searchFilters.includeArrivals.toString(),
            "includeDepartures": searchFilters.includeDepartures.toString(),
            "includeShifts": searchFilters.includeShifts.toString(),
            "movementDateDynamic": searchFilters.dateRangeNext+","+searchFilters.dateRangeValue+","+searchFilters.dateRangeType,
            "primaryCargoTypeObjStr": searchFilters.primaryCargoTypeObjStr,
            "toDate": toDate,
            "vesselObjStr": searchFilters.vesselObjStr,
            "vesselTypeObjStr": searchFilters.vesselTypeObjStr
        },
    "user" :{
        "userObjectString": state.userInfo.user.userObjectString
    },
        "sessionId": state.userInfo.sessionId
}


    !isLoggedIn && console.log(postSearchPublic)
    !isLoggedIn && dispatch(shippingPost(postSearchPublic))
    isLoggedIn && console.log(postSearchSecure)
    isLoggedIn && dispatch(shippingPost(postSearchSecure))


}
}

export function searchContainers(){
    return (dispatch, getState) =>{
        const state = getState()

        //Reset highlighted row and previous search results if any
        dispatch({type:"SET_SELECTED_ROW", payload:{key:"containersTableSelectedRow", value:null}})
        dispatch({type:"RESET_CONTAINERS_RETURN"})

        //Set cookie for last searched tab to trigger on login
        if(state.visibleComponents.isLoggedIn === true){ document.cookie = "lastSearchTab=Containers; Max-Age=15552000"}

       //Convert strings to arrays
         var idsArr = state.containersSearch.ids.split('\n')
         var idsArr2 = idsArr.map(x => x.trim())
         var cedosArr = state.containersSearch.cedos.split('\n')
         var cedosArr2 = cedosArr.map(x => x.trim())
         var releasedFromDate = ""
         var releasedToDate = ""
         var receivedFromDate = ""
         var receivedToDate = ""
        
         //set movement date dynamic if option selected
         if(state.containersSearch.includeOffPort === true && state.containersSearch.radioReleasedDate === "dynamicDate"){
             releasedFromDate = setDynamicDateRange("Last", state.containersSearch.releasedDateRangeNumber, state.containersSearch.releasedDateRangeType)
             releasedToDate = todayDate()
         } else if(state.containersSearch.includeOffPort === true && state.containersSearch.radioReleasedDate === "fromDateToDate"){
             releasedFromDate = state.containersSearch.releasedFromDate
             releasedToDate = state.containersSearch.releasedToDate
         } else{
           releasedFromDate = ""
           releasedToDate = ""
         }
 
         if(state.containersSearch.radioReceivedDate === "dynamicDate"){
             receivedFromDate = setDynamicDateRange("Last", state.containersSearch.receivedDateRangeNumber, state.containersSearch.receivedDateRangeType)
             receivedToDate = todayDate()
         } else if(state.containersSearch.radioReceivedDate === "fromDateToDate"){
             receivedFromDate = state.containersSearch.receivedFromDate
             receivedToDate = state.containersSearch.receivedToDate
         } else{
           receivedFromDate = ""
           receivedToDate = ""
         }
 
 
 
         var postSearchPublic = {
             "cargoItemFilter": {
                 "cargoType" : "1",
                 "ids": idsArr2,
                 "cedos": cedosArr2,
                 "bookingReference": state.containersSearch.bookingReference,
                 "includeOnPort" : state.containersSearch.includeOnPort.toString(),
                 "includeOnShip": state.containersSearch.includeOnShip.toString(),
                 "includeOffPort": state.containersSearch.includeOffPort.toString(),
                 "includeNotified": state.containersSearch.includeNotified.toString(),
                 "includeExports" : "true",
                 "includeImports" : "true",
                 "includeDLR": "true",
                 "includeStorage": "true",
                 "includeSOB": "true",
                 "includeCleared" : "true",
                 "includeOnHold" : "true",
                 "includeReadyToDeliver" : "true",
                 "includeNotReadyToDeliver" : "true",
                 "includeDry" : "true",
                 "includeReefers" : "true",
                 "noCedoOnly": "false",
                 "releasedDateDynamic": "Last,"+state.containersSearch.releasedDateRangeNumber+","+state.containersSearch.releasedDateRangeType,
                 "releasedFromDate":releasedFromDate,
                 "releasedToDate":releasedToDate,
                 "storageDays": -1,
             }
         }
         var containersStorageDays = state.containersSearch.storageDays
         if(typeof(state.containersSearch.storageDays) !== "number" || state.containersSearch.storageDays <=0){
             containersStorageDays = -1
         }
         var postSearchSecure = {		
             "cargoItemFilter": {
                 "cargoType" : "1",
                 "ids": idsArr2,
                 "cedos": cedosArr2,
                 "bookingReference": state.containersSearch.bookingReference,
                 "includeOnPort" : state.containersSearch.includeOnPort.toString(),
                 "includeOnShip": state.containersSearch.includeOnShip.toString(),
                 "includeOffPort": state.containersSearch.includeOffPort.toString(),
                 "includeNotified": state.containersSearch.includeNotified.toString(),
                 "voyageObjStr": state.containersSearch.voyageObjStr,
                 "customsEntryNumber": state.containersSearch.customsEntryNumber,
                 "operatorPIN": state.containersSearch.operatorPIN,
                 "operatorObjStr": state.containersSearch.operatorObjStr,
                 "storageDays": containersStorageDays,
                 "includeExports" :state.containersSearch.includeExports.toString(),
                 "includeImports" : state.containersSearch.includeImports.toString(),
                 "includeDLR": state.containersSearch.includeDLR.toString(),
                 "includeStorage": state.containersSearch.includeStorage.toString(),
                 "includeSOB": state.containersSearch.includeSOB.toString(),
                 "includeCleared" : state.containersSearch.includeCleared.toString(),
                 "includeOnHold" : state.containersSearch.includeOnHold.toString(),
                 "includeReadyToDeliver" : state.containersSearch.includeReadyToDeliver.toString(),
                 "includeNotReadyToDeliver" : state.containersSearch.includeNotReadyToDeliver.toString(),
                 "includeDry" : state.containersSearch.includeDry.toString(),
                 "includeReefers" : state.containersSearch.includeReefers.toString(),
                 "noCedoOnly": state.containersSearch.noCedoOnly.toString(),
                 "receivedDateDynamic": "Last,"+state.containersSearch.receivedDateRangeNumber+","+state.containersSearch.receivedDateRangeType,
                 "receivedFromDate":receivedFromDate,
                 "receivedToDate":receivedToDate,
                 "releasedDateDynamic": "Last,"+state.containersSearch.releasedDateRangeNumber+","+state.containersSearch.releasedDateRangeType,
                 "releasedFromDate":releasedFromDate,
                 "releasedToDate":releasedToDate,
 
             },
             "user" :{
                 "userObjectString": state.userInfo.user.userObjectString
             },
             "sessionId": state.userInfo.sessionId
         
         }
 
 
 
         if(state.visibleComponents.isLoggedIn === true){
             dispatch(containersPost(postSearchSecure))
             console.log(postSearchSecure)
         } else {
             dispatch(containersPost(postSearchPublic))
             console.log(postSearchPublic)
         }
         
         //reset settings
         dispatch({type: "CHANGE_CONTAINERS_SAVE_BUTTON", containersSaveButton: false})
         dispatch({type: "RESET_CONTAINERS_SAVE_ARRAY"})
         dispatch({type: "RESET_EDIT_ALL_CON"})
 
    }
}

export function searchVehicles(){
    return (dispatch, getState) =>{
        const state = getState()

        //Reset highlighted row and previous search results if any
        dispatch({type:"SET_SELECTED_ROW", payload:{key:"vehiclesTableSelectedRow", value:null}})
        dispatch({type:"RESET_VEHICLES_RETURN"})

        //Set cookie for last searched tab to trigger on login
        if(state.visibleComponents.isLoggedIn === true){ document.cookie = "lastSearchTab=Vehicles; Max-Age=15552000"}

       var idsArr = state.vehiclesSearch.ids.split('\n')
       var idsArr2 = idsArr.map(x => x.trim())

       var releasedFromDate = ""
       var releasedToDate = ""

       if(state.vehiclesSearch.includeOffPort === true && state.vehiclesSearch.radioReleasedDate === "dynamicDate"){
           releasedFromDate = setDynamicDateRange("Last", state.vehiclesSearch.dateRangeNumber, state.vehiclesSearch.dateRangeType)
           releasedToDate = todayDate()
       } else if(state.vehiclesSearch.includeOffPort === true && state.vehiclesSearch.radioReleasedDate === "fromDateToDate"){
           releasedFromDate = state.vehiclesSearch.releasedFromDate
           releasedToDate = state.vehiclesSearch.releasedToDate
       } else{
         releasedFromDate = ""
         releasedToDate = ""
       }

       var postSearchPublic = {		
           "cargoItemFilter": {
               "cargoType" : "7",
               "ids": idsArr2, //VINS?
               "includeCleared": "true",
               "includeNotReadyToDeliver": "true",
               "includeOffPort": "true",
               "includeOnHold": "true",
               "includeOnPort": "true",
               "includeOnShip": "true",
               "includeReadyToDeliver":"true",
           }
       }
       var vehiclesStorageDays = state.vehiclesSearch.storageDays
       if(typeof(state.vehiclesSearch.storageDays) !== "number" || state.vehiclesSearch.storageDays <=0){
           vehiclesStorageDays = -1
       }
       var postSearchSecure = {
         
               "cargoItemFilter": {
                   "cargoType" : "7",
                   "ids": idsArr2,
                   "clientRef": state.vehiclesSearch.clientReference,
                   "customsEntryNumber":state.vehiclesSearch.customsEntryNumber,
                   "houseBOL":state.vehiclesSearch.houseBOL,
                   "includeCleared": state.vehiclesSearch.includeCleared.toString(),
                   "includeNotReadyToDeliver":state.vehiclesSearch.includeNotReadyToDeliver.toString(),
                   "includeOffPort": state.vehiclesSearch.includeOffPort.toString(),
                   "includeOnHold":state.vehiclesSearch.includeOnHold.toString(),
                   "includeOnPort":state.vehiclesSearch.includeOnPort.toString(),
                   "includeOnShip":state.vehiclesSearch.includeOnShip.toString(),
                   "includeReadyToDeliver":state.vehiclesSearch.includeReadyToDeliver.toString(),
                   "oceanBOL":state.vehiclesSearch.oceanBOL,
                   "operatorObjStr":state.vehiclesSearch.operatorObjStr,
                  "releasedDateDynamic": "Last,"+state.vehiclesSearch.dateRangeNumber+","+state.vehiclesSearch.dateRangeType,
                  "releasedFromDate":releasedFromDate, 
                   "releasedToDate":releasedToDate, 
                  "storageDays":vehiclesStorageDays,
                   "voyageObjStr":state.vehiclesSearch.voyageObjStr,
               },
               "user" :{
                   "userObjectString": state.userInfo.user.userObjectString
               },
               "sessionId": state.userInfo.sessionId
       }
       if(state.visibleComponents.isLoggedIn === true){
           dispatch(vehiclesPost(postSearchSecure))
           console.log(postSearchSecure)
       } else {
           dispatch(vehiclesPost(postSearchPublic))
           console.log(postSearchPublic)
       }

       dispatch({type: "CHANGE_VEHICLES_SAVE_BUTTON", vehiclesSaveButton: false})
       dispatch({type: "RESET_VEHICLES_SAVE_ARRAY"})
       dispatch({type: "RESET_EDIT_ALL_VEH"})


    }
}