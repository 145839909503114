import React from "react"
import { connect } from "react-redux"
import { sendBookingReference } from "../../actions"

class ExportPreNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            bookingReference: ""

         }
    }

    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }

    handleNext = event => {
        event.preventDefault()
        this.props.dispatch(sendBookingReference(this.state.bookingReference))
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
            this.props.dispatch({type: "RESET_PRE_NOTES"})

        }
    }

    handleOnChange = event => {

    }

    render() { 
        return ( 
            <div className="modal-background">
                <div className="export-pre-notes-container">
                    <div className="close" onClick={this.handleCancel}>&times;</div>
                    <form onSubmit={this.handleNext}>
                        <div className="modal-header">
                            <span>Export Pre-notes</span>
                        </div>
                        <div className="modal-content-container">
                            <div><span>Booking Reference:</span><span className="red">*</span><span><input type="text" name="bookingReference" value={this.state.bookingReference} onChange={this.handleChange} autoFocus={true}/></span></div>
                            <br/>
                            <div>
                            <input type="submit" value="Next >>" className="submit-button save-button"/>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
    
    }
  }  

export default connect(mapStateToProps)(ExportPreNotes);