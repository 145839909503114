import React from "react"
import { connect } from "react-redux"
import { deleteSavedSetting, saveSetting } from "../../actions"
import { loadVehiclesSettings } from "../../actions/settings"
import { convertArrayToString, convertStringToArray} from "../../utils"
import { searchVehicles} from "../../actions/search"




class SettingsVehicles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            radio: "-1",
            saveName: "",
            index: -1,
        }
    }

    componentDidMount(){
        this.nameInput.focus();
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }
  
    handleChange = event => {
        this.setState({[event.target.name]:event.target.value})
    }

    handleRadioChange = event => {
        this.setState({radio:event.target.name})
        this.setState({index:event.target.value})
    }

    handleElementClick = index => {
        var indexToString = index.toString()
        this.setState({radio:indexToString})
        this.setState({index:indexToString})
    }

    handleDelete = event =>{
        if(this.state.index >=0){
            var vehiclesSettings = this.props.allSettings[this.state.index]
            var deleteBody = {	
                "savedSetting" : {
                        "name": vehiclesSettings.name,
                        "type": 7
                    },
                "user" :{
                    "userObjectString": this.props.userInfo.user.userObjectString
                },
                "sessionId" : this.props.userInfo.sessionId
            }
           this.props.dispatch(deleteSavedSetting(deleteBody, this.props.userInfo.user.userObjectString))
           console.log(deleteBody)
        }else{
            alert("Please select an option to delete")
        }
    }

    handleLoad = event =>{
        
        //Handling of Column Settings
        if(this.state.index >=0 ){
  
            //Load settings, then search table, then close settings box
            this.props.dispatch(loadVehiclesSettings(this.props.allSettings[this.state.index]))
            this.props.dispatch(searchVehicles())
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})

    } else{
        alert("Please select an option to load")
    }
    }

    handleSave = (event) =>{
        var vehiclesSettings = this.props.allSettings[this.state.index]
        var saveName = ""
        if(this.state.index<0){
            saveName= this.state.saveName
        }else{
            saveName = vehiclesSettings.name
        }
        var postBody = {	
            "savedSetting" : {
                "cargoItemFilter": {
                    "savedSetting": null,
                    //"bookingReference": "",
                    "cargoType": 7,
                    // "cedos": [ " " ],
                    "clientReference": this.props.vehiclesSearch.clientReference,
                    "customsEntryNumber": this.props.vehiclesSearch.customsEntryNumber,
                    "houseBOL": this.props.vehiclesSearch.houseBOL,
                    "ids": convertStringToArray(this.props.vehiclesSearch.ids),
                    "includeCleared": this.props.vehiclesSearch.includeCleared,
                    //"includeDLR": false,
                    //"includeDry": false,
                    // "includeExports": false,
                    // "includeImports": false,
                    // "includeNotified": false,
                    "includeNotReadyToDeliver": this.props.vehiclesSearch.includeNotReadyToDeliver,
                    "includeOffPort": this.props.vehiclesSearch.includeOffPort,
                    "includeOnHold": this.props.vehiclesSearch.includeOnHold,
                    "includeOnPort": this.props.vehiclesSearch.includeOnPort,
                    "includeOnShip": this.props.vehiclesSearch.includeOnShip,
                    "includeReadyToDeliver": this.props.vehiclesSearch.includeReadyToDeliver,
                    // "includeReefers": false,
                    // "includeSOB": false,
                    // "includeStorage": false,
                    // "noCedoOnly": false,
                    "oceanBOL": this.props.vehiclesSearch.oceanBOL,
                    "operator": null,
                    "operatorObjStr": this.props.vehiclesSearch.operatorObjStr,
                    // "operatorPIN": "",
                    // "receivedDateDynamic": "",
                    // "receivedFromDate": "",
                    // "receivedToDate": "",
                    "releasedDateDynamic": this.props.vehiclesSearch.releasedDateDynamic, //FIX
                    "releasedFromDate": this.props.vehiclesSearch.releasedFromDate,
                    "releasedToDate": this.props.vehiclesSearch.releasedToDate,
                    "storageDays": this.props.vehiclesSearch.storageDays,
                    "voyage": null,
                    "voyageObjStr": this.props.vehiclesSearch.voyageObjStr
                },
                "columnSettings": [
                    {
                        "ascending": true,
                        "groupBy": false,
                        "heading": "Vessel",
                        "name": "vessel",
                        "position": this.props.columnOrder.vesselPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.vessel,
                        "sortOrder": 1,
                        "width": this.props.columnInfo.vesselWidth
                    },
                    {
                        "ascending": true,
                        "groupBy": false,
                        "heading": "Ocean Bill",
                        "name": "oceanBillOfLading",
                        "position": this.props.columnOrder.oceanBillOfLadingPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.oceanBillOfLading,
                        "sortOrder": 2,
                        "width": this.props.columnInfo.oceanBillOfLadingWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": true,
                        "heading": "Voyage Code",
                        "name": "portVoyageCode",
                        "position": this.props.columnOrder.Position,
                        "savedSetting": null,
                        "show": this.props.columnInfo.portVoyageCodevoyageCode,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.voyageCodeWidth
                    },
                    {
                        "ascending": true,
                        "groupBy": false,
                        "heading": "House Bill",
                        "name": "houseBillOfLading",
                        "position": this.props.columnOrder.houseBillOfLadingPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.houseBillOfLading,
                        "sortOrder": 3,
                        "width": this.props.columnInfo.houseBillOfLadingWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Operator",
                        "name": "operator",
                        "position": this.props.columnOrder.operatorPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.operator,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.operatorWidth
                    },
                    {
                        "ascending": true,
                        "groupBy": false,
                        "heading": "VIN",
                        "name": "id",
                        "position": this.props.columnOrder.idPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.id,
                        "sortOrder": 4,
                        "width": this.props.columnInfo.idWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Stops",
                        "name": "stops",
                        "position": this.props.columnOrder.stopsPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.stops,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.stopsWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "MAF Status",
                        "name": "mafStatus",
                        "position": this.props.columnOrder.mafStatusPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.mafStatus,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.mafStatusWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Operator Stop",
                        "name": "hasOperatorStop",
                        "position": this.props.columnOrder.hasOperatorStopPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.hasOperatorStop,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.hasOperatorStopWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Freight Forwarder Stop",
                        "name": "hasFreightForwarderStop",
                        "position": this.props.columnOrder.hasFreightForwarderStopPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.hasFreightForwarderStop,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.hasFreightForwarderStopWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Locality",
                        "name": "locality",
                        "position": this.props.columnOrder.localityPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.locality,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.localityWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Customs Agent Stop",
                        "name": "hasCustomsAgentStop",
                        "position": this.props.columnOrder.hasCustomsAgentStopPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.hasCustomsAgentStop,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.hasCustomsAgentStopWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Received Date",
                        "name": "receivedDateTime",
                        "position": this.props.columnOrder.receivedDateTimePosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.receivedDateTime,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.receivedDateTimeWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Released Date",
                        "name": "releasedDateTime",
                        "position": this.props.columnOrder.releasedDateTimePosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.releasedDateTime,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.releasedDateTimeWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Last Day Of Free Storage",
                        "name": "lastDayOfFreeStorage",
                        "position": this.props.columnOrder.lastDayOfFreeStoragePosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.lastDayOfFreeStorage,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.lastDayOfFreeStorageWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Available For Delivery",
                        "name": "availableForDeliveryDateTime",
                        "position": this.props.columnOrder.availableForDeliveryDateTimePosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.availableforDeliveryDateTime,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.availableforDeliveryDateTimeWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Condition",
                        "name": "condition",
                        "position": this.props.columnOrder.conditionPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.condition,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.conditionWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Consignee",
                        "name": "consignee",
                        "position": this.props.columnOrder.consigneePosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.consignee,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.consigneeWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Client Ref",
                        "name": "clientRef",
                        "position": this.props.columnOrder.clientRefPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.clientRef,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.clientRefWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Customs Agent",
                        "name": "customsAgent",
                        "position": this.props.columnOrder.customsAgentPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.customsAgent,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.customsAgentWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Description",
                        "name": "description",
                        "position": this.props.columnOrder.descriptionPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.description,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.descriptionWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Customs Entry Number",
                        "name": "customsEntryNumber",
                        "position": this.props.columnOrder.customsEntryNumberPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.customsEntryNumber,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.customsEntryNumberWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Release Carrier",
                        "name": "releaseCarrier",
                        "position": this.props.columnOrder.releaseCarrierPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.releaseCarrier,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.releaseCarrierWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Has Radio",
                        "name": "hasRadio",
                        "position": this.props.columnOrder.hasRadioPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.hasRadio,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.hasRadioWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Key Number",
                        "name": "keyNumber",
                        "position": this.props.columnOrder.keyNumberPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.keyNumber,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.keyNumberWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Location On Port",
                        "name": "locationOnPort",
                        "position": this.props.columnOrder.locationOnPortPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.locationOnPort,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.locationOnPortWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Release Vehicle",
                        "name": "releaseVehicle",
                        "position": this.props.columnOrder.releaseVehiclePosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.releaseVehicle,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.releaseVehicleWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Load Port",
                        "name": "loadPort",
                        "position": this.props.columnOrder.loadPortPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.loadPort,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.loadPortWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Carrier Remarks",
                        "name": "carrierRemarks",
                        "position": this.props.columnOrder.carrierRemarksPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.carrierRemarks,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.carrierRemarksWidth
                    },
                    {
                        "ascending": false,
                        "groupBy": false,
                        "heading": "Freight Forwarder",
                        "name": "freightForwarder",
                        "position": this.props.columnOrder.freightForwarderPosition,
                        "savedSetting": null,
                        "show": this.props.columnInfo.freightForwarder,
                        "sortOrder": 0,
                        "width": this.props.columnInfo.freightForwarderWidth
                    }
                ],
                "columnSorts":this.props.columnInfo.defaultSorted,
                "name": saveName,
                "shippingMovementFilter": null,
                "type": 7
            },
            "user" :{
                "userObjectString": this.props.userInfo.user.userObjectString
            },
            "sessionId" : this.props.userInfo.sessionId
        }

        if(this.state.index<0 && this.state.saveName.length<1){
            alert("Please choose an option or make a save name")
        } else {
            this.props.dispatch(saveSetting(postBody, this.props.userInfo.user.userObjectString))
            // this.props.dispatch(searchShipping())
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
        console.log(postBody)
    }

    render() { 
        return ( 
            <div className="modal-background">
            <div className="favourites-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                    <span>Favourites</span>
                </div>
                <div className="modal-content-container">
                <form>
                    <p>
                    <input type="radio" className="favourites-radio"  value="-1" name="-1" checked={this.state.radio==="-1"} onChange={this.handleRadioChange}/>
                    <input 
                        type="text" 
                        className="favourites-text-input" 
                        name="saveName" 
                        value={this.state.saveName} 
                        onChange={this.handleChange} 
                        placeholder="Create New Setting"
                        ref={(inputEl) => this.nameInput = inputEl}
                        onClick={()=>this.handleElementClick(-1)}
                    />
                    </p>
                    {  this.props.allSettings.map((item,index)=>{
                        if(item.type ===7 && item.cargoItemFilter){
                            return(
                                <p key={"p"+index}>
                                <input 
                                    type="radio" 
                                    className="favourites-radio" 
                                    value={index} 
                                    name={index} 
                                    checked={this.state.radio===index.toString()} 
                                    onChange={this.handleRadioChange} 
                                    key={index}
                                />
                                <span key={"span"+index} onClick={()=>this.handleElementClick(index)}>{item.name}</span>
                                </p>
                            )
                        } else {
                            return ""
                        }
        })}
                    <input type="button" value="Load" className="submit-button save-button" onClick={this.handleLoad}/>
                    <input type="button" value="Save" className="submit-button save-button middle-button" onClick={this.handleSave}/>
                    <input type="button" value="Delete" className="submit-button save-button" onClick={this.handleDelete}/>


                </form>
                </div>
            </div>
        </div>

         );
    }
}

function mapStateToProps(state){
    return {
    allSettings: state.settings.allSettings,
    userInfo: state.userInfo,
    vehiclesSearch : state.vehiclesSearch,
    columnInfo: state.organiseTable.vehicles,
    columnOrder: state.organiseTable.vehiclesColOrder
    }
  }  
 
export default connect(mapStateToProps)(SettingsVehicles);