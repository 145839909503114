export default function formatEpochDate(dateString){

    var epochString = dateString.replace(/[^\-0-9]/g, "")
    var epochDate = parseInt(epochString)
    var d = new Date(epochDate);
    var day = d.getUTCDate();
    var month = d.getUTCMonth() + 1;
    var year = d.getUTCFullYear()
     if (dateString.length > 0  && epochDate > 0){

         return day+"/"+("0" + month).slice(-2)+"/"+year
     } else {
         return ""
     }
    }