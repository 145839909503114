const initialState = []


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PORTS':
                return action.ports
        break;
        default:
            return state
    }
}

export default reducer