import React from "react"
import { connect } from "react-redux"
import { searchShipping } from "../../actions/search"

class OrganiseShippingTable extends React.Component {
    constructor(props) {
        super(props);
    //Settings are kept in state until submitted and then passed to Store.
        this.state = { 
            movementDate: true,
            movementTime: true,
            berthTime: true,
            vesselName: true,
            voyageCode: true,
            importsLoaded: true,
            moveType: true,
            fromToPort: true,
            primaryCargo: true,
            agent: true,
            berth: true,
            arriveHarbourDate: false,
            arriveHarbourTime: false,
            comments: false,
            departBerthDate: false,
            departBerthTime: false,
            hasPendingChanges: false,
            isComplete: false,
            launchTimeOnBoard: false,
            lloydsNumber: false,
            sideTo: false,
            trade: false,
            tugs: false,
            tugsTimeOnBoard: false,
            selectAll: false,
        }
    }

    componentDidMount(){
    //Keeps state settings up-to-date with Redux store on reload.
        this.setState({
            movementDate: this.props.shipping.movementDate,
            movementTime: this.props.shipping.movementTime,
            berthTime: this.props.shipping.berthTime,
            vesselName: this.props.shipping.vesselName,
            voyageCode: this.props.shipping.voyageCode,
            importsLoaded: this.props.shipping.importsLoaded,
            moveType: this.props.shipping.moveType,
            fromToPort: this.props.shipping.fromToPort,
            primaryCargo: this.props.shipping.primaryCargo,
            agent: this.props.shipping.agent,
            berth: this.props.shipping.berth,
            arriveHarbourDate: this.props.shipping.arriveHarbourDate,
            arriveHarbourTime: this.props.shipping.arriveHarbourTime,
            comments: this.props.shipping.comments,
            departBerthDate: this.props.shipping.departBerthDate,
            departBerthTime: this.props.shipping.departBerthTime,
            hasPendingChanges: this.props.shipping.hasPendingChanges,
            isComplete: this.props.shipping.isComplete,
            launchTimeOnBoard: this.props.shipping.launchTimeOnBoard,
            lloydsNumber: this.props.shipping.lloydsNumber,
            sideTo: this.props.shipping.sideTo,
            trade: this.props.shipping.trade,
            tugs: this.props.shipping.tugs,
            tugsTimeOnBoard: this.props.shipping.tugsTimeOnBoard,
            selectAll: this.props.shipping.selectAll
        })
      }

    handleSubmit = event =>{
      event.preventDefault()
      this.props.dispatch(
        {
            type: "UPDATE_ALL_ORG_SHIP_CHECKBOX",
            payload: {
                movementDate: this.state.movementDate,
                movementTime: this.state.movementTime,
                berthTime: this.state.berthTime,
                vesselName: this.state.vesselName,
                voyageCode: this.state.voyageCode,
                importsLoaded: this.state.importsLoaded,
                moveType: this.state.moveType,
                fromToPort: this.state.fromToPort,
                primaryCargo: this.state.primaryCargo,
                agent: this.state.agent,
                berth: this.state.berth,
                arriveHarbourDate: this.state.arriveHarbourDate,
                arriveHarbourTime: this.state.arriveHarbourTime,
                comments: this.state.comments,
                departBerthDate: this.state.departBerthDate,
                departBerthTime: this.state.departBerthTime,
                hasPendingChanges: this.state.hasPendingChanges,
                isComplete: this.state.isComplete,
                launchTimeOnBoard: this.state.launchTimeOnBoard,
                lloydsNumber: this.state.lloydsNumber,
                sideTo: this.state.sideTo,
                trade: this.state.trade,
                tugs: this.state.tugs,
                tugsTimeOnBoard: this.state.tugsTimeOnBoard,
                selectAll: this.state.selectAll
            }
        }
    )
    //this.props.dispatch(searchShipping())
    this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
    }

    handleCancel = event =>{
        if (event.target === event.currentTarget){
            this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "none"})
        }
    }

    handleSelectAll = event =>{
    this.setState({
        movementDate: event.target.checked,
        movementTime: event.target.checked,
        berthTime: event.target.checked,
        vesselName: event.target.checked,
        voyageCode: event.target.checked,
        importsLoaded: event.target.checked,
        moveType: event.target.checked,
        fromToPort: event.target.checked,
        primaryCargo: event.target.checked,
        agent: event.target.checked,
        berth: event.target.checked,
        arriveHarbourDate: event.target.checked,
        arriveHarbourTime: event.target.checked,
        comments: event.target.checked,
        departBerthDate: event.target.checked,
        departBerthTime: event.target.checked,
        hasPendingChanges: event.target.checked,
        isComplete: event.target.checked,
        launchTimeOnBoard: event.target.checked,
        lloydsNumber: event.target.checked,
        sideTo: event.target.checked,
        trade: event.target.checked,
        tugs: event.target.checked,
        tugsTimeOnBoard: event.target.checked,
        selectAll: event.target.checked,
    })
    }



    handleCheckboxChange = event => {
        this.setState({[event.target.name]:event.target.checked})
    }


    render() { 
        return ( 
            <div className="modal-background">
            <div className="organise-container">
                <div className="close" onClick={this.handleCancel}>&times;</div>
                <div className="modal-header">
                        <span>Organise</span>
                    </div>
                    <div className="modal-content-container">
                <form onSubmit={this.handleSubmit}>
                    <table className="organise-table">
            <thead>
                <tr>
                <th></th>
                <th>Show</th>
                <th></th>
                <th></th>
                <th>Show</th>
                
                </tr>
            </thead>
            <tbody>  
                <tr>
                    <td>Date:</td>
                    <td><input name="movementDate"  type="checkbox" checked={this.state.movementDate} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Arrive Harbour Time:</td>
                    <td><input name="arriveHarbourTime"  type="checkbox" checked={this.state.arriveHarbourTime} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Pilot Time:</td>
                    <td><input name="movementTime"  type="checkbox" checked={this.state.movementTime} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Comments:</td>
                    <td><input name="comments"  type="checkbox" checked={this.state.comments} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Berth Time:</td>
                    <td><input name="berthTime"  type="checkbox" checked={this.state.berthTime} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Depart Berth Date:</td>
                    <td><input name="departBerthDate"  type="checkbox" checked={this.state.departBerthDate} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Vessel Name:</td>
                    <td><input name="vesselName"  type="checkbox" checked={this.state.vesselName} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Depart Berth Time</td>
                    <td><input name="departBerthTime"  type="checkbox" checked={this.state.departBerthTime} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Voyage Code:</td>
                    <td><input name="voyageCode"  type="checkbox" checked={this.state.voyageCode} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Has Pending Changes:</td>
                    <td><input name="hasPendingChanges"  type="checkbox" checked={this.state.hasPendingChanges} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Imports:</td>
                    <td><input name="importsLoaded"  type="checkbox" checked={this.state.importsLoaded} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Is Complete:</td>
                    <td><input name="isComplete"  type="checkbox" checked={this.state.isComplete} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Type:</td>
                    <td><input name="moveType"  type="checkbox" checked={this.state.moveType} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Launch Time On Board:</td>
                    <td><input name="launchTimeOnBoard"  type="checkbox" checked={this.state.launchTimeOnBoard} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>From/To Port:</td>
                    <td><input name="fromToPort"  type="checkbox" checked={this.state.fromToPort} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Side To:</td>
                    <td><input name="sideTo"  type="checkbox" checked={this.state.sideTo} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Primary Cargo:</td>
                    <td><input name="primaryCargo"  type="checkbox" checked={this.state.primaryCargo} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Trade:</td>
                    <td><input name="trade"  type="checkbox" checked={this.state.trade} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Agent:</td>
                    <td><input name="agent"  type="checkbox" checked={this.state.agent} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Tugs:</td>
                    <td><input name="tugs"  type="checkbox" checked={this.state.tugs} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Berth:</td>
                    <td><input name="berth"  type="checkbox" checked={this.state.berth} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>Tugs Time On Board:</td>
                    <td><input name="tugsTimeOnBoard"  type="checkbox" checked={this.state.tugsTimeOnBoard} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                    <td>Arrive Harbour Date:</td>
                    <td><input name="arriveHarbourDate"  type="checkbox" checked={this.state.arriveHarbourDate} onChange={this.handleCheckboxChange}/></td>
                    <td></td>
                    <td>LLoyds Number:</td>
                    <td><input name="lloydsNumber"  type="checkbox" checked={this.state.lloydsNumber} onChange={this.handleCheckboxChange}/></td>
                </tr>
                <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Select All:</td>
                        <td><input type="checkbox" checked={this.state.selectAll} onChange={this.handleSelectAll}/></td>
                    </tr>
            </tbody>
            </table>
            <div className="save-button-container">
                        <div><input type="submit" className="submit-button save-button" value="Show"/></div>
                        </div>
                </form>
                </div>
            </div>
        </div>

         );
    }
}
function mapStateToProps(state){
    return {
     shipping: state.organiseTable.shipping
    }
  }  

export default connect(mapStateToProps)(OrganiseShippingTable);