//const initialState = {user:{userObjectString:""}}
const initialState = {user: {userObjectString: ''}}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_USER_INFO':
            return action.userInfo
            break;
        case 'CLEAR_USER_INFO':
            return initialState
            break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer