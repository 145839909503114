const initialState = {
    cargoType:"1", //need to be invisible components section?
    // ids:"APZU3788889\nAPZU3537332",
    ids:"",
    cedos:"",
    bookingReference:"",
    includeOnPort:true,
    includeOnShip:false,
    includeOffPort:false,
    includeNotified: false,
    voyageObjStr:"",
    customsEntryNumber:"",
    operatorObjStr:"",
    operatorPIN:"",
    storageDays:"",
    includeExports:true,
    includeImports:true,
    includeDLR:true,
    includeStorage:true,
    includeSOB:true,
    includeCleared:true,
    includeOnHold:true,
    includeReadyToDeliver:true,
    includeNotReadyToDeliver:true,
    includeDry:true,
    includeReefers:true,
    noCedoOnly:false,
    receivedDateDynamic:"",
    receivedFromDate:"",
    receivedToDate:"",
    releasedFromDate:"",
    releasedToDate:"",
    releasedDateDynamic:"",
    radioReleasedDate:"none",
    releasedDateRangeNumber:1,
    releasedDateRangeType:"Day(s)",
    radioReceivedDate:"none",
    receivedDateRangeNumber:1,
    receivedDateRangeType:"Day(s)",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE_INCLUDEONPORT":
            return Object.assign({}, state, {includeOnPort: !state.includeOnPort})
            break;
        case "TOGGLE_INCLUDEONSHIP":
            return Object.assign({}, state, {includeOnShip: !state.includeOnShip})
            break;
        case "TOGGLE_INCLUDEOFFPORT":
            return Object.assign({}, state, {includeOffPort: !state.includeOffPort})
            break;
        case "TOGGLE_INCLUDENOTIFIED":
            return Object.assign({}, state, {includeNotified: !state.includeNotified})
            break;
        case "TOGGLE_INCLUDEEXPORTS":
            return Object.assign({}, state, {includeExports: !state.includeExports})
            break;
        case "TOGGLE_INCLUDEIMPORTS":
            return Object.assign({}, state, {includeImports: !state.includeImports})
            break;
        case "TOGGLE_INCLUDEDLR":
            return Object.assign({}, state, {includeDLR: !state.includeDLR})
            break;
        case "TOGGLE_INCLUDESTORAGE":
            return Object.assign({}, state, {includeStorage: !state.includeStorage})
            break;
        case "TOGGLE_INCLUDESOB":
            return Object.assign({}, state, {includeSOB: !state.includeSOB})
            break;         
        case "TOGGLE_INCLUDECLEARED":
        return Object.assign({}, state, {includeCleared: !state.includeCleared})
            break;   
        case "TOGGLE_INCLUDEONHOLD":
            return Object.assign({}, state, {includeOnHold: !state.includeOnHold})
            break;   
        case "TOGGLE_INCLUDEREADYTODELIVER":
            return Object.assign({}, state, {includeReadyToDeliver: !state.includeReadyToDeliver})
            break;   
        case "TOGGLE_INCLUDENOTREADYTODELIVER":
            return Object.assign({}, state, {includeNotReadyToDeliver: !state.includeNotReadyToDeliver})
            break;   
        case "TOGGLE_INCLUDEDRY":
            return Object.assign({}, state, {includeDry: !state.includeDry})
            break;   
        case "TOGGLE_INCLUDEREEFERS":
            return Object.assign({}, state, {includeReefers: !state.includeReefers})
            break;   
        case "TOGGLE_NOCEDOONLY":
            return Object.assign({}, state, {noCedoOnly: !state.noCedoOnly})
            break;       
        case "CHANGE_CONTAINER_RADIO_RECEIVED_DATE":
            return Object.assign({}, state, {radioReceivedDate: action.radioReceivedDate})
            break;     
        case "CHANGE_CONTAINER_RADIO_RELEASED_DATE":
            return Object.assign({}, state, {radioReleasedDate: action.radioReleasedDate})
            break;    
        case "CHANGE_CONTAINER_IDS":
            return Object.assign({}, state, {ids: action.ids})
            break;   
        case "CHANGE_CONTAINER_CEDOS":
            return Object.assign({}, state, {cedos: action.cedos})
            break;  
        case "CHANGE_CONTAINER_BOOKING_REFERENCE":
            return Object.assign({}, state, {bookingReference: action.bookingReference})
            break;  
        case "CHANGE_CONTAINER_CUSTOMS_ENTRY_NUMBER":
            return Object.assign({}, state, {customsEntryNumber: action.customsEntryNumber})
            break;  
        case "CHANGE_CONTAINER_OPERATOR_PIN":
            return Object.assign({}, state, {operatorPIN: action.operatorPIN})
            break; 
        case "CHANGE_CONTAINER_STORAGE_DAYS":
            return Object.assign({}, state, {storageDays: action.storageDays})
            break; 
        case "CHANGE_CONTAINER_VOYAGE_OBJ_STR":
            return Object.assign({}, state, {voyageObjStr: action.voyageObjStr})
        case "CHANGE_CONTAINER_OPERATOR_OBJ_STR":
            return Object.assign({}, state, {operatorObjStr: action.operatorObjStr})
            break; 
        case "CHANGE_SEARCH_CONTAINERS_INPUT":
            return Object.assign({}, state, {[action.payload.key]: action.payload.value})
            break;
        case "LOAD_CONTAINERS_SETTINGS":
            return Object.assign({}, state, {
                bookingReference: action.payload.bookingReference,
                cedos: action.cedos,
                clientReference: action.payload.clientReference, //find
                customsEntryNumber: action.payload.customsEntryNumber,
                houseBOL: action.payload.houseBOL,//need?
                ids:action.ids,
                includeCleared: action.payload.includeCleared,
                includeDLR: action.payload.includeDLR,
                includeDry: action.payload.includeDry,
                includeExports: action.payload.includeExports,
                includeImports: action.payload.includeImports,
                includeNotified: action.payload.includeNotified,
                includeNotReadyToDeliver: action.payload.includeNotReadyToDeliver,
                includeOffPort: action.payload.includeOffPort,
                includeOnHold: action.payload.includeOnHold,
                includeOnPort: action.payload.includeOnPort,
                includeOnShip: action.payload.includeOnShip,
                includeReadyToDeliver: action.payload.includeReadyToDeliver,
                includeReefers: action.payload.includeReefers,
                includeSOB: action.payload.includeSOB,
                includeStorage: action.payload.includeStorage,
                noCedoOnly: action.payload.noCedoOnly,
                oceanBOL: action.payload.oceanBOL, //need?
                operator: action.payload.operator, //used?
                operatorObjStr: action.payload.operatorObjStr,
                operatorPIN: action.payload.operatorPIN,
                receivedDateDynamic: action.payload.receivedDateDynamic,
                receivedFromDate: action.payload.receivedFromDate,
                receivedToDate: action.payload.receivedToDate,
                releasedDateDynamic: action.payload.releasedDateDynamic,
                releasedFromDate: action.payload.releasedFromDate,
                releasedToDate: action.payload.releasedToDate,
                storageDays: action.storageDays,
                voyage: action.payload.voyage, //need?
                voyageObjStr: action.payload.voyageObjStr,
            })
        
            break;
        case 'LOGOUT':
            return initialState
            break;
        default:
            return state
    }
}

export default reducer