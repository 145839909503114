import React from "react"
import { connect } from "react-redux";
import { toggleLoginBox, loginStatus, updateUserInfo } from "../../actions"
import LoginBox from "../modalComponents/LoginBox"
import ChangePasswordForm from "../modalComponents/ChangePasswordForm"
import OrganiseVehiclesTable from "../modalComponents/OrganiseVehiclesTable"
import OrganiseShippingTable from "../modalComponents/OrganiseShippingTable"
import OrganiseContainersTable from "../modalComponents/OrganiseContainersTable"
import EditAllContainers from "../modalComponents/EditAllContainers"
import EditAllVehicles from "../modalComponents/EditAllVehicles"
import SettingsContainers from "../modalComponents/SettingsContainers"
import SettingsShipping from "../modalComponents/SettingsShipping"
import SettingsVehicles from "../modalComponents/SettingsVehicles"
import ExportPreNotes from "../modalComponents/ExportPreNotes"
import ExportPreNotes2 from "../modalComponents/ExportPreNotes2"
import ContactUs from "../modalComponents/ContactUs"
import Register from "../modalComponents/Register"
import ForgotPassword from "../modalComponents/ForgotPassword"
import ExportPreNotesVoyageSelect from "../modalComponents/ExportPreNotesVoyageSelect"


class HeaderBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount(){
        console.log("header CDM")
      
      }

showLoginBox = event =>{
 this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "loginBox"})
}

handleLogout = event =>{
    this.props.dispatch(loginStatus(false))
    this.props.dispatch({type: "CLEAR_USER_INFO"})
    this.props.dispatch({type: "LOGOUT"})
}

// showMyAccount = event =>{
//     this.props.dispatch({type: "IS_VISIBLE_CHANGE_PASSWORD_BOX", changePasswordBoxVisible: true})
// }

renderModalBox = () =>{
    switch(this.props.visibleModalBox){
        case "loginBox": return <LoginBox/>
        break;
        case "changePasswordForm": return <ChangePasswordForm/>
        break;
        case "organiseShippingTable": return <OrganiseShippingTable/>
        break;
        case "organiseContainersTable": return <OrganiseContainersTable/>
        break;
        case "organiseVehiclesTable": return <OrganiseVehiclesTable/>
        break;
        case "editAllContainers": return <EditAllContainers/>
        break;
        case "editAllVehicles": return <EditAllVehicles/>
        break;
        case "settingsContainers": return <SettingsContainers/>
        break;
        case "settingsShipping": return <SettingsShipping/>
        break;
        case "settingsVehicles": return <SettingsVehicles/>
        break;
        case "exportPreNotes": return <ExportPreNotes/>
        break;
        case "exportPreNotes2": return <ExportPreNotes2/>
        break;
        case "exportPreNotesVoyageSelect": return <ExportPreNotesVoyageSelect/>
        break;
        case "contactUs": return <ContactUs/>
        break;
        case "register": return <Register/>
        break;
        case "forgotPassword": return <ForgotPassword/>
        break;
        default: return
    }

}
    render() { 
        return ( 
            <div id="header">
                {this.renderModalBox()}
                <div id="header-logo">
                    <img src="./images/centreport_logo_white2.png"/>
                </div>
                <div id="header-links">
                    {this.props.isLoggedIn && this.props.visibleTab === "Containers" && <p onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "exportPreNotes"})}>Export Pre-notes</p>}
                    {this.props.isLoggedIn?<p onClick={this.handleLogout}>Logout</p>:<p onClick={this.showLoginBox}>Login</p>}
                    {this.props.isLoggedIn && <p onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "changePasswordForm"})}>My Account</p>}
                    {!this.props.isLoggedIn && <p  onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "register"})}>Register</p>}
                    <p onClick={()=>this.props.dispatch({type: "CHANGE_VISIBLE_MODAL_BOX", visibleModalBox: "contactUs"})}>Contact Us</p>
                    {/* <p>Help</p> */}
                </div>
            </div>
         );
    }
}
 
function mapStateToProps(state){
    return {
        isLoggedIn: state.visibleComponents.isLoggedIn,
        visibleModalBox: state.visibleComponents.visibleModalBox,
        visibleTab: state.visibleComponents.visibleTab
    }
  } 

export default connect(mapStateToProps)(HeaderBar)